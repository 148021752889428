import feed from "@src/api/feed/feed";
import CompactPost from "@src/components/elements/CompactPost";
import { useInfiniteQueryOnScroll } from "@src/hooks/useInfiniteQueryOnScroll";
import useDebounce from "@src/utils/useDebounce";

type Props = {
  userId: string;
  searchQuery: string;
};

const PostLikes = (props: Props) => {
  const { userId } = props;
  const debouncedSearchQuery = useDebounce(props.searchQuery, 500);
  const queryParams = {
    q: debouncedSearchQuery || null,
    featured_post_id: null,
    user_id: null,
    liked_by: userId || null,
  };

  const { result: feedResult } = useInfiniteQueryOnScroll({
    queryKey: ["posts", "feed", queryParams],
    queryFn: ({ pageParam }) => feed({ params: { ...queryParams, page: pageParam } }),
    initialPageParam: 0,
    getNextPageParam: lastPage => {
      const nextPage = lastPage.current_page + 1;
      return nextPage <= lastPage.total_pages ? nextPage : null;
    },
  });

  if (!feedResult.isSuccess) return <></>;

  return (
    <div className="mt-2 grid grid-cols-2 gap-4 xl:grid-cols-5">
      {feedResult.data.pages
        .flatMap(page => page.data)
        .filter(originalPost => originalPost !== null)
        .map(originalPost => {
          const post = originalPost.is_reposted ? originalPost.reposted : originalPost;

          let id = post.id;
          let type: "image" | "video" = "image";
          let url = "/images/default_header_profile.png";

          if (post.link_preview?.image) {
            url = `${post.link_preview?.image}`;
          } else if (post.media.length) {
            id = post.media[0].id;
            url = post.media[0].data_url;
            if (post.media[0].type === "video") {
              type = "video";
            }
          }

          return (
            <CompactPost
              key={`like:${post.watch ? id : post.id}`}
              link={
                post.watch ? `/watch/für_dich?featured=${id}` : `/news/für_dich?featured=${post.id}`
              }
              type={type}
              url={url}
              viewCount={post.view_count}
              likeCount={post.like_count}
            />
          );
        })}
    </div>
  );
};

export default PostLikes;
