import { PencilIcon } from "@heroicons/react/24/outline";
import suggestions from "@src/api/users/suggestions";
import PopularWidget from "@src/components/elements/PopularWidget";
import UserWidget from "@src/components/elements/UserWidget";
import Input from "@src/components/elements/input/Input";
import TabBodies from "@src/components/elements/shared/tab/TabBodies";
import TabBody from "@src/components/elements/shared/tab/TabBody";
import TabController from "@src/components/elements/shared/tab/TabController";
import TabHeader from "@src/components/elements/shared/tab/TabHeader";
import TabHeaders from "@src/components/elements/shared/tab/TabHeaders";
import FollowingFeedContainer from "@src/pages/news/posts/FollowingFeedContainer";
import MyFeedContainer from "@src/pages/news/posts/MyFeedContainer";
import PostsContainer from "@src/pages/news/posts/PostsContainer";
import { useAppSelector } from "@src/state/hooks";
import useLoginModal from "@src/state/modal/useLoginModal";
import useDebounce from "@src/utils/useDebounce";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

type Props = {
  filter?: string;
};

const NewsContainer = ({ filter = "toYou" }: Props) => {
  const { user } = useAppSelector(state => state.user);
  const { t } = useTranslation();
  const [searchQuery, setSearchQuery] = useState("");
  const debouncedSearchQuery = useDebounce(searchQuery, 500);
  const navigate = useNavigate();
  const loginModal = useLoginModal();

  const suggestionsResult = useQuery({
    queryKey: ["users-suggestions"],
    queryFn: () => suggestions(),
  });

  const checkAuth = () => {
    if (!user) {
      loginModal.open();
      return false;
    }
    return true;
  };

  return (
    <>
      <PopularWidget>
        {suggestionsResult.isSuccess &&
          suggestionsResult.data.data.map(user => (
            <div className="w-[70px] shrink-0" key={user.id}>
              <UserWidget key={user.id} user={user} showName={true} />
            </div>
          ))}
      </PopularWidget>

      <TabController defaultTab={filter}>
        <TabHeaders>
          <TabHeader id="myFeed" url="/news/mein_feed">
            {t("main.feedTabs.myFeed")}
          </TabHeader>
          <TabHeader id="toYou" url="/news/für_dich">
            {t("main.feedTabs.toYou")}
          </TabHeader>
          <TabHeader id="create" url="/news/erstellen">
            {t("main.feedTabs.create.title")}
          </TabHeader>
          <TabHeader id="following" url="/news/following">
            {t("main.feedTabs.following")}
          </TabHeader>
          <TabHeader id="trending" url="/news/trending">
            {t("main.feedTabs.trending")}
          </TabHeader>
        </TabHeaders>

        <Input
          name="search-contacts"
          placeholder={t("components.shared.search", {
            tab: t("main.feedTabs." + filter),
          })}
          onChange={e => setSearchQuery(e)}
          clearable
        />

        <TabBodies>
          <TabBody id="myFeed">
            <MyFeedContainer searchQuery={debouncedSearchQuery} />
          </TabBody>
          <TabBody id="toYou">
            <PostsContainer searchQuery={debouncedSearchQuery} />
          </TabBody>
          <TabBody id="create">{t("main.navLinks.createAd.tabs.create")}</TabBody>
          <TabBody id="following">
            <FollowingFeedContainer searchQuery={debouncedSearchQuery} />
          </TabBody>
          <TabBody id="trending">
            <PostsContainer searchQuery={debouncedSearchQuery} />
          </TabBody>
        </TabBodies>
      </TabController>

      <button
        className="absolute bottom-2 right-5 z-20 flex size-[50px] items-center justify-center rounded-full bg-blue-200 lg:hidden"
        onClick={() => checkAuth() && navigate("/news/erstellen")}
      >
        <PencilIcon className="size-6 text-gray-600" />
      </button>
    </>
  );
};

export default NewsContainer;
