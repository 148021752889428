import UserWidgetFollowButton from "@src/components/elements/UserWidgetFollowButton";
import VerifyBadge from "@src/components/elements/VerifyBadge";
import FullScreenImage from "@src/components/elements/shared/FullScreenImage";
import { useAppSelector } from "@src/state/hooks";
import useUserBubbleModal from "@src/state/modal/useUserBubbleModal";
import getName from "@src/utils/getName";
import { User } from "@src/utils/user";
import { useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import tw, { TwStyle } from "twin.macro";

type Props = {
  user: User;
  follow?: boolean;
  showName?: boolean;
  sideName?: boolean;
  online?: boolean;
  size?: number;
  verified?: boolean;
  key?: string;
  nameStyle?: TwStyle;
  avatarFullscreen?: boolean;
};

const UserWidgetPicture = ({
  user,
  showName = false,
  follow = true,
  online = true,
  verified = false,
  nameStyle,
  ...props
}: Props) => {
  const { user: userData } = useAppSelector(state => state.user);
  const self = userData && userData.id === user.id;
  const followed = self || !follow || user.followed || false;
  online = online && true;
  const size = props.size || 75;
  const placeholder = `/images/placeholder/${user.type}.png`;
  const containerRef = useRef<HTMLAnchorElement>(null);
  const userBubbleModal = useUserBubbleModal();

  const placeholderHeaderImage = "/images/placeholder/headerImage.png";
  const [openAvatar, setOpenAvatar] = useState(false);

  const handleMouseOver = () => {
    if (containerRef.current) {
      const rect = containerRef.current.getBoundingClientRect();
      userBubbleModal.open({
        position: { x: rect.x, y: rect.y },
        offset: { x: 0, y: 100 },
        user,
      });
    }
  };

  return (
    <NavLink
      to={`/profile/${user.id}`}
      onClick={e => {
        userBubbleModal.close();
        if (props.avatarFullscreen) {
          setOpenAvatar(!openAvatar);
          e.preventDefault();
        }
      }}
      onMouseOver={handleMouseOver}
      onMouseLeave={() => {
        userBubbleModal.close();
      }}
      ref={containerRef}
    >
      <img
        className="max-h-[75px] min-h-[75px] w-full rounded-t-lg object-cover"
        src={user.header_image || placeholderHeaderImage}
        alt={getName(user)}
      />
      <div
        css={[
          tw`cursor-pointer relative w-full absolute top-[-40px]`,
          props.sideName && tw`flex`,
          props.avatarFullscreen && tw`pointer-events-auto`,
        ]}
      >
        <div
          css={[
            tw`rounded-full border border-gray-300 relative bg-white shrink-0`,
            !props.sideName && tw`mx-auto`,
          ]}
          style={{
            width: `${size.toString()}px`,
            height: `${size.toString()}px`,
          }}
        >
          <div
            css={[tw`flex justify-center items-center rounded-full overflow-hidden h-full w-full`]}
          >
            <img className="w-full" src={user.avatar || placeholder} alt={getName(user)} />
            {!!openAvatar && (
              <FullScreenImage
                onClose={() => setOpenAvatar(false)}
                imageUrl={user.avatar || placeholder}
              />
            )}
          </div>
          {online && (
            <div
              css={[
                tw`absolute rounded-full border-white bg-green-500 w-[25%] h-[25%] right-[2%] bottom-[2%]`,
              ]}
            />
          )}
          {!followed && userData && (
            <UserWidgetFollowButton user={user} me={userData} size={size} />
          )}
        </div>
        {showName && (
          <div
            css={[
              tw`text-xs text-left whitespace-nowrap text-gray-600 flex justify-center items-center gap-1 w-full h-[1rem] px-2 truncate`,
              !props.sideName && tw`mt-2`,
              props.sideName &&
                tw`text-sm font-bold h-[50px] leading-[50px] text-center pl-3 justify-start`,
            ]}
          >
            <div
              css={[
                tw`truncate`,
                verified ? tw`max-w-[calc(100%-16px)]` : tw`w-full`,
                !props.sideName ? tw`text-center` : tw`text-left`,
                nameStyle,
              ]}
            >
              {getName(user)}
            </div>
            {verified && <VerifyBadge type={user.type} />}
          </div>
        )}
      </div>
    </NavLink>
  );
};

export default UserWidgetPicture;
