import { MediaType } from "@src/utils/types/MediaType";

export const MediaData: Array<MediaType> = [
  {
    id: "26e38714-4f6d-497b-a9bd-d933f0ab667b",
    key: "74ebd322a4ea87ab22afebfd305c6831",
    file_name: "pexels-photo-3184641.jpeg",
    type: "image",
    data_url:
      "https://images.pexels.com/photos/1029757/pexels-photo-1029757.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    thumbnail:
      "https://images.pexels.com/photos/1029757/pexels-photo-1029757.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
  },
  {
    id: "5b150789-3b51-4923-8492-d83a383bc74e",
    key: "21b22f75c40281ffaf6c6800fc5c9301",
    file_name: "pexels-photo-3747482.jpeg",
    type: "image",
    data_url:
      "https://images.pexels.com/photos/8512383/pexels-photo-8512383.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    thumbnail:
      "https://images.pexels.com/photos/8512383/pexels-photo-8512383.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
  },
  {
    id: "65f59333-f3a1-4c74-a033-57282d4df880",
    key: "a5d4db338fb3fb68e6b6339f3b08453d",
    file_name: "pexels-photo-3184292.jpeg",
    type: "image",
    data_url:
      "https://images.pexels.com/photos/3184292/pexels-photo-3184292.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
    thumbnail:
      "https://images.pexels.com/photos/3184292/pexels-photo-3184292.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=200&w=300",
  },
  {
    id: "b6c871fb-ac00-4d75-bae0-b033d6a4ef24",
    key: "0b6c8b63da8cd91db9ab42256dc93e6b",
    file_name: "pexels-photo-3184465.jpeg",
    type: "image",
    data_url:
      "https://images.pexels.com/photos/2528118/pexels-photo-2528118.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    thumbnail:
      "https://images.pexels.com/photos/2528118/pexels-photo-2528118.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
  },
  {
    id: "6cb19e92-e7f1-4d7f-8e4e-33f785584c72",
    key: "e753901aa97462e3950c7fb6ae242a08",
    file_name: "pexels-photo-3184321.jpeg",
    type: "image",
    data_url:
      "https://images.pexels.com/photos/3778619/pexels-photo-3778619.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    thumbnail:
      "https://images.pexels.com/photos/3778619/pexels-photo-3778619.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
  },
  {
    id: "1e38714c-6d7b-47ab-bb49-cf7d014ddac1",
    key: "e8bc6debb2a54eb8a68a5308f5f4f962",
    file_name: "pexels-photo-3184298.jpeg",
    type: "image",
    data_url:
      "https://images.pexels.com/photos/8353778/pexels-photo-8353778.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    thumbnail:
      "https://images.pexels.com/photos/8353778/pexels-photo-8353778.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
  },
];
