import Input from "@src/components/elements/input/Input";
import { useState } from "react";
import { useTranslation } from "react-i18next";

type Props = {
  name: string;
  label: string;
  value: string;
  onChange: (value: string) => void;
  maxLength?: number;
};
const LegalInput = (props: Props) => {
  const [mode, setMode] = useState(0);
  const { t } = useTranslation();

  return (
    <div className="text-sm">
      <div className="mb-1">{props.label}</div>
      <div className="flex flex-row items-center gap-2">
        <input
          type="radio"
          name={`${props.name}-external`}
          id={`${props.name}-external`}
          value="external"
          onChange={() => setMode(0)}
          checked={!mode}
        />
        <label htmlFor={`${props.name}-external`}>{t("main.managedPages.externalPages")}</label>

        <input
          type="radio"
          name={`${props.name}-internal`}
          id={`${props.name}-internal`}
          value="internal"
          onChange={() => setMode(1)}
          checked={!!mode}
        />
        <label htmlFor={`${props.name}-internal`}>{t("components.input.text")}</label>
      </div>
      {mode ? (
        <textarea
          name={props.name}
          className="my-1 block w-full rounded-xl border-0 bg-gray-200 px-4 py-3 text-sm"
          placeholder={`${props.label} ${t("components.area.text")}`}
          value={props.value}
          onChange={e => props.onChange(e.target.value)}
          maxLength={props.maxLength}
        />
      ) : (
        <Input
          name={props.name}
          placeholder={`${t("components.input.link")} ${props.label}`}
          value={props.value}
          onChange={e => props.onChange(e)}
        />
      )}
    </div>
  );
};

export default LegalInput;
