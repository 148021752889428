import PopupWrapper from "@src/components/elements/PopupWrapper";
import Button from "@src/components/elements/input/Button";
import isApp from "@src/utils/isApp";
import { deleteCookie, setCookie } from "cookies-next";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import tw from "twin.macro";

type Props = {
  onClose: () => void;
  onConsent: (value: "allowed" | "declined") => void;
};

const cookiesListCloudflare = [
  { name: "_cf_bm" },
  { name: "__cflb" },
  { name: "cf_ob_info" },
  { name: "cf_use_ob" },
  { name: "__cfruid" },
  { name: "cf_clearance" },
  { name: "__cfduid" },
  { name: "__cf_bm" },
  { name: "__cfduid.*" },
  { name: "LS_CSRF_TOKEN " },
  { name: "cf_chl_rc_ni " },
  { name: "cf_chl_2" },
  { name: "cf_chl_cc_*" },
  { name: "_cfuvid" },
];

const cookiesListGoogleAdvertising = [
  { name: "NID" },
  { name: "google_experiment_mod*" },
  { name: "google_adsense_settings" },
  { name: "google_auto_fc_cmp_setting" },
  { name: "goog_pem_mod" },
  { name: "GoogleAdServingTest" },
  { name: "google_pem_mod" },
];

const cookiesListGoogleAnalytics = [
  { name: "_ga" },
  { name: "_gid" },
  { name: "_gat_gtag_*" },
  { name: "_gac_*" },
  { name: "_gat*" },
  { name: "_dc_gtm_*" },
  { name: "AMP_TOKEN" },
  { name: "__utma" },
  { name: "__utmt*" },
  { name: "__utmb" },
  { name: "__utmc" },
  { name: "__utmz" },
  { name: "__utmv" },
  { name: "__utmx" },
  { name: "__utm*" },
  { name: "_swag_ga_ga*" },
  { name: "__gads" },
  { name: "_gd*" },
  { name: "_ga_*" },
  { name: "_gcl_gb" },
];

const cookiesListGoogleTagManager = [
  { name: "cookiePreferences" },
  { name: "mat_ep" },
  { name: "_dc_gtm_UA-.*" },
  { name: "_gcl_au" },
  { name: "_dc_gtm_UA-*" },
];

const cookiesListHubSpot = [
  { name: "__hs_do_not_track" },
  { name: "__hssc" },
  { name: "__hssrc" },
  { name: "__hstc" },
  { name: "hubspotutk" },
  { name: "__cduid" },
  { name: "__hs_opt_out" },
  { name: "__hs_initial_opt_in" },
  { name: "hs_ab_test" },
  { name: "hs-messages-is-open" },
  { name: "hs-messages-hide-welcome-message" },
  { name: "__hsmem" },
  { name: "hs-membership-csrf" },
  { name: "hs_langswitcher_choice" },
  { name: "messagesUtk" },
];

const cookiesListother = [{ name: "sentryReplaySession" }, { name: "i18nextLng" }];

const CookiesPopup = ({ onClose, onConsent }: Props) => {
  const [selectedGroups, setSelectedGroups] = useState<string[]>([]);
  const [showCookieSelection, setShowCookieSelection] = useState(false);
  const { t } = useTranslation();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1280 || isApp);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1280 || isApp);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const buttonSize = isMobile ? Button.Sizes.Large : Button.Sizes.Small;

  const allowSelectedCookies = () => {
    selectedGroups.forEach(group => {
      switch (group) {
        case "Cloudflare":
          cookiesListCloudflare.forEach(cookie =>
            setCookie(cookie.name, "allowed", {
              maxAge: 60 * 60 * 24 * 365, // Setzt den Cookie für 1 Jahr
              path: "/",
              secure: true, // Stellt sicher, dass der Cookie nur über HTTPS gesendet wird
              sameSite: "strict", // Schutz vor CSRF-Attacken
            }),
          );
          break;
        case "GoogleAnalytics":
          cookiesListGoogleAnalytics.forEach(cookie =>
            setCookie(cookie.name, "allowed", {
              maxAge: 60 * 60 * 24 * 365, // Setzt den Cookie für 1 Jahr
              path: "/",
              secure: true, // Stellt sicher, dass der Cookie nur über HTTPS gesendet wird
              sameSite: "strict", // Schutz vor CSRF-Attacken
            }),
          );
          break;
        case "GoogleTagManager":
          cookiesListGoogleTagManager.forEach(cookie =>
            setCookie(cookie.name, "allowed", {
              maxAge: 60 * 60 * 24 * 365, // Setzt den Cookie für 1 Jahr
              path: "/",
              secure: true, // Stellt sicher, dass der Cookie nur über HTTPS gesendet wird
              sameSite: "strict", // Schutz vor CSRF-Attacken
            }),
          );
          break;
        case "GoogleAdvertising":
          cookiesListGoogleAdvertising.forEach(cookie =>
            setCookie(cookie.name, "allowed", {
              maxAge: 60 * 60 * 24 * 365, // Setzt den Cookie für 1 Jahr
              path: "/",
              secure: true, // Stellt sicher, dass der Cookie nur über HTTPS gesendet wird
              sameSite: "strict", // Schutz vor CSRF-Attacken
            }),
          );
          break;
        case "HubSpot":
          cookiesListHubSpot.forEach(cookie =>
            setCookie(cookie.name, "allowed", {
              maxAge: 60 * 60 * 24 * 365, // Setzt den Cookie für 1 Jahr
              path: "/",
              secure: true, // Stellt sicher, dass der Cookie nur über HTTPS gesendet wird
              sameSite: "strict", // Schutz vor CSRF-Attacken
            }),
          );
          break;
        case "Other":
          cookiesListother.forEach(cookie =>
            setCookie(cookie.name, "allowed", {
              maxAge: 60 * 60 * 24 * 365, // Setzt den Cookie für 1 Jahr
              path: "/",
              secure: true, // Stellt sicher, dass der Cookie nur über HTTPS gesendet wird
              sameSite: "strict", // Schutz vor CSRF-Attacken
            }),
          );
          break;
        default:
          break;
      }
    });

    onConsent("allowed");
  };

  const allowAllCookies = () => {
    const allCookies = [
      ...cookiesListCloudflare,
      ...cookiesListGoogleAnalytics,
      ...cookiesListGoogleTagManager,
      ...cookiesListGoogleAdvertising,
      ...cookiesListHubSpot,
      ...cookiesListother,
    ];

    allCookies.forEach(cookie =>
      setCookie(cookie.name, "allowed", {
        maxAge: 60 * 60 * 24 * 365, // Setzt den Cookie für 1 Jahr
        path: "/",
        secure: true, // Stellt sicher, dass der Cookie nur über HTTPS gesendet wird
        sameSite: "strict", // Schutz vor CSRF-Attacken
      }),
    );

    onConsent("allowed");
  };

  const handleCheckboxChange = (group: string) => {
    const index = selectedGroups.indexOf(group);
    if (index === -1) {
      setSelectedGroups([...selectedGroups, group]);
    } else {
      setSelectedGroups(selectedGroups.filter(item => item !== group));
    }
  };

  const removeAllCookies = () => {
    cookiesListCloudflare.forEach(cookie => deleteCookie(cookie.name));
    cookiesListGoogleAnalytics.forEach(cookie => deleteCookie(cookie.name));
    cookiesListGoogleTagManager.forEach(cookie => deleteCookie(cookie.name));
    cookiesListGoogleAdvertising.forEach(cookie => deleteCookie(cookie.name));
    cookiesListHubSpot.forEach(cookie => deleteCookie(cookie.name));
    cookiesListother.forEach(cookie => deleteCookie(cookie.name));

    onConsent("declined");
  };

  return (
    <PopupWrapper
      onClose={onClose}
      title={"Cookies"}
      windowStyle={tw`border border-gray-300 bg-white shadow-2xl rounded-2xl fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50 min-w-[300px] w-[90vw] md:w-fit h-fit max-h-full overflow-y-auto h-fit max-h-[calc(100vh-130px)] min-h-[200px] bg-gray-50 max-w-[600px]`}
    >
      <h1>{t("main.panel.CookiePopUp.title")}</h1>
      <h2>{t("main.panel.CookiePopUp.description")}</h2>
      <br></br>
      {showCookieSelection ? (
        <div>
          <h3>{t("main.panel.CookiePopUp.groups")}</h3>
          <br></br>
          <div className="cookie-group">
            <span>{t("components.shared.cloudflare")}</span>
            <label className="switch">
              <input type="checkbox" onChange={() => handleCheckboxChange("Cloudflare")} />
              <span className="slider round"></span>
            </label>
          </div>
          <div className="cookie-group">
            <span>{t("components.shared.googleAnalytics")}</span>
            <label className="switch">
              <input type="checkbox" onChange={() => handleCheckboxChange("GoogleAnalytics")} />
              <span className="slider round"></span>
            </label>
          </div>
          <div className="cookie-group">
            <span>{t("components.shared.googleTagManager")}</span>
            <label className="switch">
              <input type="checkbox" onChange={() => handleCheckboxChange("GoogleTagManager")} />
              <span className="slider round"></span>
            </label>
          </div>
          <div className="cookie-group">
            <span>{t("components.shared.googleAds")}</span>
            <label className="switch">
              <input type="checkbox" onChange={() => handleCheckboxChange("GoogleAdvertising")} />
              <span className="slider round"></span>
            </label>
          </div>
          <div className="cookie-group">
            <span>{t("components.shared.hubspot")}</span>
            <label className="switch">
              <input type="checkbox" onChange={() => handleCheckboxChange("HubSpot")} />
              <span className="slider round"></span>
            </label>
          </div>
          <div className="cookie-group">
            <span>{t("components.shared.other")}</span>
            <label className="switch">
              <input type="checkbox" onChange={() => handleCheckboxChange("Other")} />
              <span className="slider round"></span>
            </label>
          </div>
          <div style={isMobile ? { marginBottom: "5px" } : {}}>
            <Button onClick={allowSelectedCookies} size={buttonSize}>
              {t("main.panel.CookiePopUp.allowSelectedCookies")}
            </Button>
          </div>
        </div>
      ) : null}
      {!showCookieSelection && (
        <Button
          onClick={() => setShowCookieSelection(true)}
          variant={Button.Variants.Transparent}
          size={buttonSize}
        >
          {t("main.panel.CookiePopUp.chooseCookies")}
        </Button>
      )}
      <div style={isMobile ? { marginTop: "5px" } : {}}>
        <Button onClick={allowAllCookies} size={buttonSize}>
          {t("main.panel.CookiePopUp.allowAllCookies")}
        </Button>
      </div>
      <div style={isMobile ? { marginTop: "5px" } : {}}>
        <Button onClick={removeAllCookies} variant={Button.Variants.Transparent} size={buttonSize}>
          {t("main.panel.CookiePopUp.denyAllCookies")}
        </Button>
      </div>
      <div className="mt-2 flex justify-center gap-3">
        <NavLink to="/impressum" className="text-sm">
          {t("main.signIn.imprint")}
        </NavLink>
        <span className="text-sm">|</span>
        <NavLink to="/datenschutz" className="text-sm" onClick={() => onClose()}>
          {t("main.signIn.dataProtection")}
        </NavLink>
      </div>
    </PopupWrapper>
  );
};

export default CookiesPopup;
