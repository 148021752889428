import { Listbox, Transition } from "@headlessui/react";
import { ChevronUpDownIcon } from "@heroicons/react/20/solid";
import React, { Fragment, useState } from "react";

type Props = {
  options: Array<{
    name: string;
    value: string;
    icon: React.ReactNode;
  }>;
  onChange: (newValue: string) => void;
  label?: string;
  disabled?: boolean;
  defaultValue?: string;
};

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

const CustomSelect = ({ options, label, onChange, disabled, defaultValue }: Props) => {
  const [selected, setSelected] = useState(
    options[
      defaultValue
        ? options.findIndex(item => {
            return item.value === defaultValue;
          })
        : 0
    ],
  );

  return (
    <Listbox
      value={selected}
      onChange={e => {
        onChange(e.value);
        setSelected(e);
      }}
      disabled={disabled}
    >
      {({ open }) => (
        <div className="relative">
          {label && (
            <Listbox.Label className="absolute -top-2.5 left-2 z-20 bg-white px-1 text-xs font-medium text-gray-900">
              {label}
            </Listbox.Label>
          )}
          <div className="relative mt-2">
            <Listbox.Button className="relative w-full cursor-default rounded-2xl bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-darkblue sm:text-sm sm:leading-6">
              <div className="flex gap-2">
                {selected.icon && (
                  <span className="pointer-events-none inset-y-0 flex items-center">
                    {selected.icon}
                  </span>
                )}
                <span className="block truncate">{selected.name}</span>
              </div>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronUpDownIcon className="size-5 text-gray-400" aria-hidden="true" />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-2xl bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {options.map(option => (
                  <Listbox.Option
                    key={option.value}
                    className={({ active }) =>
                      classNames(
                        active ? "bg-darkblue text-white" : "text-darkblue",
                        "relative cursor-default select-none py-2 pl-3 pr-9",
                      )
                    }
                    value={option}
                  >
                    {({ selected }) => (
                      <span
                        className={classNames(
                          selected ? "font-semibold" : "font-normal",
                          "block truncate flex gap-2",
                        )}
                      >
                        {option.icon && option.icon}
                        {option.name}
                      </span>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </div>
      )}
    </Listbox>
  );
};

export default CustomSelect;
