import Panel from "@src/components/box/Panel";
import SuggestionsCenterItem from "@src/components/elements/sidebar/suggestions/SuggestionsCenterItem";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import tw from "twin.macro";

const items: Array<{ name: string; picture: string; link: string }> = [];

type Props = { id?: string; key?: string };

const MarketplaceSuggestions = ({ id }: Props) => {
  const { t } = useTranslation();
  return (
    <div className="px-3 lg:px-0">
      <Panel title={t("components.panels.popularProducts")} mobileBorder={false} key={id}>
        <div className="no-scrollbar flex gap-4 overflow-x-auto">
          {items.map(item => (
            <SuggestionsCenterItem
              key={item.name}
              name={item.name}
              link={item.link}
              picture={item.picture}
              imageStyle={tw`rounded-xl overflow-visible w-[100px] h-[150px] object-cover`}
            />
          ))}
          <NavLink to="/marketplace/">
            <div className="flex h-[150px] w-[100px] flex-col justify-center text-center">
              {t("buttons.showMore")}
            </div>
          </NavLink>
        </div>
      </Panel>
    </div>
  );
};
export default MarketplaceSuggestions;
