import {
  ArrowPathRoundedSquareIcon,
  ChevronLeftIcon,
  EllipsisHorizontalIcon,
  EnvelopeIcon,
  NewspaperIcon,
  VideoCameraIcon,
} from "@heroicons/react/24/outline";
import {
  BookmarkIcon as BookmarkIconSolid,
  ChatBubbleOvalLeftIcon as ChatBubbleOvalLeftIconSolid,
  HeartIcon as HeartIconSolid,
  PlayIcon as PlayIconSolid,
} from "@heroicons/react/24/solid";
import { useAppSelector } from "@src/state/hooks";
import { MediaType } from "@src/utils/types/MediaType";
import { useTranslation } from "react-i18next";

type Props = {
  media: Array<MediaType>;
};

const Preview = ({ media }: Props) => {
  const { user } = useAppSelector(state => state.user);
  const placeholder = `/images/placeholder/${user ? user.type : "person"}.png`;
  const { t } = useTranslation();

  return (
    <div className="aspect-[9/16] rounded-xl bg-[#3a4245] p-0.5 max-lg:max-h-[500px]">
      <div className="relative size-full rounded-xl bg-[#161823] p-2">
        {/* top side */}
        <div className="absolute left-[5%] top-[5%] flex w-[90%] flex-row justify-between text-mini text-white">
          <ChevronLeftIcon className="size-4" />
          <div>{t("main.watchTabs.myWatch")}</div>
          <div>{t("main.watchTabs.myWatch")}</div>
          <div>{t("main.contacts.tabs.following")}</div>
          <div>{t("main.contacts.tabs.trending")}</div>
        </div>
        {/* right side */}
        <div className="absolute right-[5%] top-[30%] flex flex-col gap-5 text-white">
          <HeartIconSolid className="size-6 text-white" />
          <ChatBubbleOvalLeftIconSolid className="size-6 " />
          <BookmarkIconSolid className="size-6 " />
          <PlayIconSolid className="size-6 " />
          <ArrowPathRoundedSquareIcon className="size-6 " />
          <EllipsisHorizontalIcon className="size-6 " />
        </div>
        {/* bottom side */}
        <div className="absolute bottom-[3%] left-[5%] flex w-[90%] flex-row justify-between gap-5 text-white">
          <img src="/logo512.png" className="size-7 rounded-md" alt="Logo" />
          <EnvelopeIcon className="size-6 " />
          <NewspaperIcon className="size-6 " />
          <VideoCameraIcon className="size-6 " />
          <div className="flex size-7 items-center justify-center overflow-hidden rounded-full">
            <img className="w-full" src={user ? user.avatar : placeholder} />
          </div>
        </div>
        {media[0]?.thumbnail && (
          <img
            alt="Thumbnail"
            src={media[0]?.thumbnail}
            className="size-full rounded-xl bg-black object-cover pb-[35px]"
          />
        )}
      </div>
    </div>
  );
};

export default Preview;
