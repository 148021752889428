import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "@src/state/store";
import { User } from "@src/utils/user";

interface UserState {
  user: User | null;
  token: string | null;
  refreshToken: string | null;
  notificationToken: string | null;
}

const initialState: UserState = {
  user: null,
  token: null,
  refreshToken: null,
  notificationToken: null,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, { payload }: PayloadAction<User>) => {
      state.user = payload;
    },
    setToken: (state, { payload }: PayloadAction<string>) => {
      state.token = payload;
    },
    setRefreshToken: (state, { payload }: PayloadAction<string>) => {
      state.refreshToken = payload;
    },
    setNotificationToken: (state, { payload }: PayloadAction<string>) => {
      state.notificationToken = payload;
    },
    signOut: state => {
      state.user = null;
      state.token = null;
      state.refreshToken = null;
      state.notificationToken = null;
    },
  },
});

export const selectNotifications = (state: RootState) => state.notifications.value;

export const { setUser, setToken, setRefreshToken, setNotificationToken, signOut } =
  userSlice.actions;

export default userSlice.reducer;
