import Input from "@src/components/elements/input/Input";
import WatchThumbnailList from "@src/components/elements/posts/watch/WatchThumbnailList";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const ProfileDetailsWatch = ({
  userId = "",
  showInput = false,
}: {
  userId?: string;
  showInput?: boolean;
}) => {
  const [searchQuery, setSearchQuery] = useState("");
  const { t } = useTranslation();
  return (
    <>
      {showInput && (
        <Input
          placeholder={t("main.watchTabs.placeholder")}
          name="watch_keywords"
          onChange={e => setSearchQuery(e)}
          clearable
        />
      )}
      <WatchThumbnailList search={searchQuery} userId={userId} smallGaps />
    </>
  );
};

export default ProfileDetailsWatch;
