import Select from "@src/components/elements/input/Select";

const salutations = [
  { value: "Herr", name: "Herr" },
  { value: "Frau", name: "Frau" },
  { value: "Dr", name: "Dr." },
  { value: "Prof", name: "Prof." },
];

type Props = {
  value: string;
  onChange: (key: string, value: string) => void;
};

const SalutationSelect = ({ value, onChange }: Props) => (
  <Select
    name="salutation"
    items={salutations}
    defaultValue={salutations[0].value}
    value={value}
    onChange={e => onChange("salutation", e.target.value)}
  />
);

export default SalutationSelect;
