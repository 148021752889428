import { ArrowLeftIcon } from "@heroicons/react/24/outline";
import create from "@src/api/chats/groups/create";
import upload from "@src/api/media/upload";
import GroupComboBox from "@src/components/elements/form/GroupComboBox";
import Button from "@src/components/elements/input/Button";
import Input from "@src/components/elements/input/Input";
import AvatarEditElement from "@src/pages/messenger/AvatarEditElement";
import isMobile from "@src/utils/isMobile";
import { ChatType } from "@src/utils/types/ChatType";
import { MediaType } from "@src/utils/types/MediaType";
import { User } from "@src/utils/user";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { PropsWithChildren, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

type Props = {
  selectedForm: string;
  setSelectedForm: (e: string) => void;
  selectedChats: Array<string>;
  setSelectedChats: (e: Array<string>) => void;
  setSelectedChat: (e: string | false) => void;
  onClose: () => void;
  chats: Array<ChatType>;
};

const MessengerGroups = ({ setSelectedChats, onClose }: Props) => {
  const { t } = useTranslation();
  const [name, setName] = useState<string>("");
  const [members, setMembers] = useState<Array<User>>([]);
  const imageRef = useRef<HTMLInputElement>(null);
  const [step, setStep] = useState<number>(0);
  const [image, setImage] = useState<MediaType | undefined>(undefined);
  const queryClient = useQueryClient();

  const setSelectedUsers = (data: Array<User>) => {
    setMembers(data);
  };

  const uploadMutation = useMutation({
    mutationKey: ["upload"],
    mutationFn: upload,
    onSuccess: ({ data }) => setImage(data),
    onError: () => {
      alert("Failed");
    },
  });

  const createMutation = useMutation({
    mutationKey: ["chats", "groups", "create"],
    mutationFn: create,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["chats"],
      });
      queryClient.invalidateQueries({
        queryKey: ["chats", "messages"],
      });
      setStep(0);
      setSelectedChats([]);
      onClose();
    },
    onError: () => {
      alert("Failed");
    },
  });

  return (
    <Wrapper>
      {step === 0 && (
        <div className="mt-[env(safe-area-inset-top)] flex flex-col gap-2.5 px-3 pb-0.5">
          <div className="mt-[env(safe-area-inset-top)] flex items-center gap-2 px-3 text-sm text-gray-900 max-md:text-center">
            <ArrowLeftIcon
              className="size-6 cursor-pointer rounded-full hover:bg-gray-300"
              onClick={() => {
                onClose();
              }}
            />
            {t("main.header.links.messenger.tabs.groups")}
          </div>
          <GroupComboBox
            selectedUsers={members}
            setSelectedUsers={setSelectedUsers}
            onSave={() => setStep(value => value + 1)}
            placeholder={t("main.header.links.messenger.searchGroups")}
          />
        </div>
      )}
      {step === 1 && (
        <div className="mt-[env(safe-area-inset-top)] flex flex-col gap-2.5 px-3 pb-0.5">
          <div className="mt-[env(safe-area-inset-top)] flex items-center gap-2 px-3 text-sm text-gray-900 max-md:text-center">
            <ArrowLeftIcon
              className="size-6 cursor-pointer rounded-full hover:bg-gray-300"
              onClick={() => setStep(value => value - 1)}
            />
            {t("main.header.links.messenger.tabs.groups")}
          </div>
          <input
            type="file"
            className="hidden"
            ref={imageRef}
            multiple={false}
            onChange={e => {
              if (e.target.files) {
                uploadMutation.mutate({ file: e.target.files[0] });
              }
            }}
          />
          <AvatarEditElement
            image={image}
            loading={uploadMutation.isPending}
            onClick={() => imageRef.current?.click()}
          />
          <Input
            name="group_name"
            label="Gruppenname"
            required
            maxLength={25}
            onChange={value => setName(value)}
          />
          <div className="flex gap-2">
            <Button
              variant={Button.Variants.Transparent}
              onClick={() => {
                onClose();
                setSelectedChats([]);
              }}
            >
              {t("buttons.cancel")}
            </Button>
            <Button
              variant={Button.Variants.Transparent}
              onClick={() =>
                createMutation.mutate({
                  data: {
                    name,
                    members: members.map(item => item.id),
                    avatar: image?.id,
                  },
                })
              }
            >
              {t("main.feedTabs.create.title")}
            </Button>
          </div>
        </div>
      )}
    </Wrapper>
  );
};

const Wrapper = (props: PropsWithChildren) => {
  if (isMobile) {
    return (
      <div className="fixed left-0 top-0 z-50 h-dvh w-screen bg-white p-2">{props.children}</div>
    );
  } else return props.children;
};

export default MessengerGroups;
