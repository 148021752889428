import { User } from "@src/utils/user";
import React from "react";
import { useTranslation } from "react-i18next";
import tw from "twin.macro";

const padZero = (num: number) => (num < 10 ? `0${num}` : num.toString());

const CVList: React.FC<{ user: User }> = ({ user }) => {
  const cvs = user.person?.cv || [];
  const educations = user.person?.education || [];

  const { t } = useTranslation();

  if (cvs.length === 0 && educations.length === 0) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <h1 style={{ fontSize: "2em", color: "#18677d", lineHeight: "1.1" }}>
          {t("main.profileViewTabs.editProfile.form.cv.noData")}
        </h1>
        <div
          style={{
            fontSize: "1.2em",
            color: "#555",
            textAlign: "center",
            maxWidth: "500px",
            marginTop: "50px",
          }}
        >
          {t("main.profileViewTabs.editProfile.form.cv.noDataSubtext")}
        </div>
      </div>
    );
  }

  return (
    <div>
      {cvs.length > 0 && (
        <div className="test" css={tw`border rounded-xl bg-gray-100 `}>
          <h2 css={tw`text-lg pl-4 pt-2`}>
            {t("main.profileViewTabs.editProfile.form.cv.career")}
          </h2>
          {cvs.map((cv, index) => {
            const startDate = new Date(cv.start);
            const endDate = new Date(cv.end);

            const formattedStartDate = `${padZero(startDate.getDate())}.${padZero(startDate.getMonth() + 1)}.${startDate.getFullYear()}`;
            const formattedEndDate = `${padZero(endDate.getDate())}.${padZero(endDate.getMonth() + 1)}.${endDate.getFullYear()}`;

            return (
              <div key={index.toString()} css={tw`border-gray-300 rounded-xl p-2 flex gap-5 `}>
                <div css={tw`border border-gray-300 rounded-xl p-2 bg-white rounded-lg shadow-md`}>
                  <p css={tw`text-sm`}>
                    {formattedStartDate} - {formattedEndDate}
                  </p>
                </div>
                <div
                  css={tw`border border-gray-300 rounded-xl p-2 w-3/4 bg-white rounded-lg shadow-md`}
                >
                  <p>{cv.description}</p>
                </div>
              </div>
            );
          })}
        </div>
      )}
      {educations.length > 0 && (
        <div className="test" css={tw`border rounded-xl bg-gray-100  mt-4`}>
          <h2 css={tw`text-lg pl-4 pt-2`}>
            {t("main.profileViewTabs.editProfile.form.cv.training")}
          </h2>

          {educations.map((education, index) => {
            const startDate = new Date(education.start);
            const endDate = new Date(education.end);

            const formattedStartDate = `${padZero(startDate.getDate())}.${padZero(startDate.getMonth() + 1)}.${startDate.getFullYear()}`;
            const formattedEndDate = `${padZero(endDate.getDate())}.${padZero(endDate.getMonth() + 1)}.${endDate.getFullYear()}`;

            return (
              <div key={index.toString()} css={tw`border-gray-300 rounded-xl p-2 flex gap-5`}>
                <div css={tw`border border-gray-300 rounded-xl p-2 bg-white rounded-lg shadow-md`}>
                  <p css={tw`text-sm`}>
                    {formattedStartDate} - {formattedEndDate}
                  </p>
                </div>
                <div
                  css={tw`border border-gray-300 rounded-xl p-2 w-3/4 bg-white rounded-lg shadow-md`}
                >
                  <p>{education.description}</p>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default CVList;
