import api, { RequestWithParams } from "@src/api/api";

export type ChangeEmailParams = {
  code: string;
};

export type ChangeEmailRequest = RequestWithParams<ChangeEmailParams>;

export default async function changeEmail(request: ChangeEmailRequest): Promise<void> {
  const { data } = await api.post(
    "/api/v1/users/account/email/change_confirm",
    { code: request.params.code },
    {
      headers: {
        "Content-Type": "application/json",
      },
    },
  );
  return data;
}
