import forYou from "@src/api/contacts/forYou";
import { useInfiniteQueryOnScroll } from "@src/hooks/useInfiniteQueryOnScroll";
import ContactItem from "@src/pages/contacts/ContactItem";
import { User } from "@src/utils/user";

type Props = {
  searchQuery?: string;
};

const ContactTrendingForYouContainer = ({ searchQuery }: Props) => {
  const { result: forYouResult } = useInfiniteQueryOnScroll({
    queryKey: ["contacts", "for-you", searchQuery],
    queryFn: ({ pageParam }) => forYou({ params: { page: pageParam, q: searchQuery } }),
    initialPageParam: 0,
    getNextPageParam: lastPage => {
      const nextPage = lastPage.current_page + 1;
      return nextPage <= lastPage.total_pages ? nextPage : null;
    },
  });

  return (
    <>
      <div className="grid grid-cols-2 gap-4 md:grid-cols-3">
        {forYouResult.data?.pages.map(page =>
          page.data.map((item: User) => <ContactItem key={item.id} mode="default" user={item} />),
        )}
      </div>
    </>
  );
};

export default ContactTrendingForYouContainer;
