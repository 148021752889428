import api, { requestParams, RequestWithParams } from "@src/api/api";
import { User } from "@src/utils/user";

export type MyContactsParams = {
  page?: number;
  q?: string;
};

export type MyContactsRequest = RequestWithParams<MyContactsParams>;

export type MyContactsResponse = {
  data: User[];
  current_page: number;
  total_pages: number;
};

export default async function myContacts(request: MyContactsRequest): Promise<MyContactsResponse> {
  const { data } = await api.get(
    `/api/v1/contacts/my_contacts${requestParams({ page: 0, ...request.params })}`,
  );
  return data;
}
