import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";

const options = {
  order: ["querystring", "navigator"],
  lookupQuerystring: "lng",
};

const init = () => {
  i18n
    .use(LanguageDetector)
    .use(Backend)
    .use(initReactI18next)
    .init({
      detection: options,
      fallbackLng: "en",
      supportedLngs: ["de", "en"],
      debug: true,
    });
};

export default init;
