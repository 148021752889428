import api, { RequestWithData } from "@src/api/api";
import { CheckoutSessionType } from "@src/utils/types/CheckoutSessionType";

export type RequestData = {
  product_id: string;
  quantity: number;
};

export type CheckoutSessionRequest = RequestWithData<RequestData>;

export type CheckoutSessionResponse = {
  data: CheckoutSessionType;
};

export default async function checkoutSession(
  request: CheckoutSessionRequest,
): Promise<CheckoutSessionResponse> {
  const { data } = await api.post("/api/v1/payment/checkout-session", request.data);
  return data;
}
