import BackButton from "@src/components/nav/BackButton";
import { useTranslation } from "react-i18next";

const CommunityRulesContainer = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="pl-4 pt-4">
        <BackButton stepback desktopVisible />
      </div>
      <div className="flex flex-col gap-3 pb-4 text-justify text-xs text-gray-700">
        <h1 className="text-left text-smbase font-semibold">
          {t("pages.comRulesContainer.text1")}
        </h1>
        <h2 className="font-semibold">{t("pages.comRulesContainer.text2")}</h2>
        <div>{t("pages.comRulesContainer.text3")}</div>
        <h2 className="font-semibold">{t("pages.comRulesContainer.text56")}</h2>
        <div>{t("pages.comRulesContainer.text4")}</div>
        <div>{t("pages.comRulesContainer.text5")}</div>
        <h2 className="font-semibold">{t("pages.comRulesContainer.text57")}</h2>
        <div>{t("pages.comRulesContainer.text6")}</div>
        <div>{t("pages.comRulesContainer.text5")}</div>
        <h2 className="font-semibold">{t("pages.comRulesContainer.text42")}</h2>
        <div>{t("pages.comRulesContainer.text7")}</div>
        <div>{t("pages.comRulesContainer.text5")}</div>
        <h2 className="font-semibold">{t("pages.comRulesContainer.text8")}</h2>
        <div>{t("pages.comRulesContainer.text9")}</div>
        <div>{t("pages.comRulesContainer.text10")}</div>
        <div>{t("pages.comRulesContainer.text11")}</div>
        <div>{t("pages.comRulesContainer.text12")}</div>
        <h2 className="font-semibold">{t("pages.comRulesContainer.text13")}</h2>
        <div>{t("pages.comRulesContainer.text14")}</div>
        <h2 className="font-semibold">{t("pages.comRulesContainer.text15")}</h2>
        <div>{t("pages.comRulesContainer.text16")}</div>
        <div>{t("pages.comRulesContainer.text17")}</div>
        <h2 className="font-semibold">{t("pages.comRulesContainer.text56")}</h2>
        <div>{t("pages.comRulesContainer.text18")}</div>
        <h2 className="font-semibold">{t("pages.comRulesContainer.text19")}</h2>
        <div>{t("pages.comRulesContainer.text20")}</div>
        <div>{t("pages.comRulesContainer.text21")}</div>
        <h2 className="font-semibold">{t("pages.comRulesContainer.text22")}</h2>
        <div>{t("pages.comRulesContainer.text23")}</div>
        <div>{t("pages.comRulesContainer.text24")}</div>
        <h2 className="font-semibold">{t("pages.comRulesContainer.text25")}</h2>
        <div>{t("pages.comRulesContainer.text26")}</div>
        <h2 className="font-semibold">{t("pages.comRulesContainer.text27")}</h2>
        <div>{t("pages.comRulesContainer.text28")}</div>
        <div>{t("pages.comRulesContainer.text29")}</div>
        <h2 className="font-semibold">{t("pages.comRulesContainer.text30")}</h2>
        <div>{t("pages.comRulesContainer.text31")}</div>
        <div>{t("pages.comRulesContainer.text32")}</div>
        <h2 className="font-semibold">{t("pages.comRulesContainer.text57")}</h2>
        <div>{t("pages.comRulesContainer.text33")}</div>
        <h2 className="font-semibold">{t("pages.comRulesContainer.text34")}</h2>
        <div>{t("pages.comRulesContainer.text35")}</div>
        <h2 className="font-semibold">{t("pages.comRulesContainer.text36.1")}</h2>
        <div>{t("pages.comRulesContainer.text36.2")}</div>
        <h2 className="font-semibold">{t("pages.comRulesContainer.text37")}</h2>
        <div>{t("pages.comRulesContainer.text38")}</div>
        <h2 className="font-semibold">{t("pages.comRulesContainer.text39")}</h2>
        <div>{t("pages.comRulesContainer.text40")}</div>
        <div>{t("pages.comRulesContainer.text41")}</div>
        <h2 className="font-semibold">{t("pages.comRulesContainer.text42")}</h2>
        <div>{t("pages.comRulesContainer.text43")}</div>
        <h2 className="font-semibold">{t("pages.comRulesContainer.text44")}</h2>
        <div>{t("pages.comRulesContainer.text45")}</div>
        <div>{t("pages.comRulesContainer.text46")}</div>
        <h2 className="font-semibold">{t("pages.comRulesContainer.text47")}</h2>
        <div>{t("pages.comRulesContainer.text48")}</div>
        <div>{t("pages.comRulesContainer.text49")}</div>
        <h2 className="font-semibold">{t("pages.comRulesContainer.text50")}</h2>
        <div>{t("pages.comRulesContainer.text51")}</div>
        <div>{t("pages.comRulesContainer.text52")}</div>
        <h2 className="font-semibold">{t("pages.comRulesContainer.text53")}</h2>
        <div>{t("pages.comRulesContainer.text54")}</div>
        <div>{t("pages.comRulesContainer.text55")}</div>
      </div>
    </>
  );
};

export default CommunityRulesContainer;
