import jobs from "@src/api/jobs/jobs";
import JobCard from "@src/pages/jobs/components/JobCard";
import JobCarousel from "@src/pages/jobs/components/JobCarousel";
import { useQuery } from "@tanstack/react-query";
import { t } from "i18next";
import { Bar, Line, Pie } from "react-chartjs-2";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { SwiperSlide } from "swiper/react";

const StatisticsContainer = () => {
  const jobsResult = useQuery({
    queryKey: ["jobs"],
    queryFn: () => jobs({ params: {} }),
  });

  const applicationsTrendData = {
    labels: [
      t("months.january"),
      t("months.february"),
      t("months.march"),
      t("months.april"),
      t("months.may"),
      t("months.june"),
      t("months.july"),
    ],
    datasets: [
      {
        label: t("main.jobsTabs.myJobs.applicationsTrend"),
        fill: false,
        lineTension: 0.1,
        backgroundColor: "#4dc9f6",
        borderColor: "#4dc9f6",
        pointBorderColor: "#4dc9f6",
        pointBackgroundColor: "#fff",
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "#4dc9f6",
        pointHoverBorderColor: "#4dc9f6",
        pointHoverBorderWidth: 2,
        data: [5, 10, 15, 20, 25, 30, 35],
      },
    ],
  };

  const jobCategoryData = {
    labels: [
      t("components.assignments.category.technology"),
      t("main.jobsTabs.categories.finance"),
      t("main.jobsTabs.categories.healthcare"),
      t("main.jobsTabs.categories.education"),
    ],
    datasets: [
      {
        label: t("main.jobsTabs.myJobs.jobCategories"),
        data: [40, 25, 20, 15],
        backgroundColor: ["#3490dc", "#38c172", "#e3342f", "#6cb2eb"],
      },
    ],
  };

  const applicationStatusData = {
    labels: [
      t("main.contacts.tabs.pending"),
      t("main.jobsTabs.myJobs.interview"),
      t("main.jobsTabs.myJobs.offered"),
      t("main.jobsTabs.myJobs.rejected"),
    ],
    datasets: [
      {
        data: [10, 5, 2, 8],
        backgroundColor: ["#fcc419", "#38c172", "#3490dc", "#e3342f"],
        hoverBackgroundColor: ["#f6e05e", "#4fd888", "#55c9ff", "#ef4444"],
      },
    ],
  };

  return (
    <div className="container">
      <div className="mb-4 grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-4">
        <div className="rounded-lg border border-blue-300 bg-blue-100 p-4 text-center">
          <h3 className="text-lg font-medium text-blue-700">
            {t("main.jobsTabs.myJobs.applicationSubmitted")}
          </h3>
          <p className="mt-2 text-2xl font-semibold text-blue-900">20</p>
        </div>
        <div className="rounded-lg border border-green-300 bg-green-100 p-4 text-center">
          <h3 className="text-lg font-medium text-green-700">
            {t("main.jobsTabs.myJobs.savedJobs")}
          </h3>
          <p className="mt-2 text-2xl font-semibold text-green-900">10</p>
        </div>
        <div className="rounded-lg border border-yellow-300 bg-yellow-100 p-4 text-center">
          <h3 className="text-lg font-medium text-yellow-700">
            {t("main.jobsTabs.myJobs.interviewsScheduled")}
          </h3>
          <p className="mt-2 text-2xl font-semibold text-yellow-900">5</p>
        </div>
        <div className="rounded-lg border border-purple-300 bg-purple-100 p-4 text-center">
          <h3 className="text-lg font-medium text-purple-700">
            {t("main.jobsTabs.myJobs.jobOffered")}
          </h3>
          <p className="mt-2 text-2xl font-semibold text-purple-900">4</p>
        </div>
      </div>
      <div className="mb-5 grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-4">
        <div className="rounded-lg border border-gray-300 bg-white p-6">
          <h2 className="mb-4 text-xl font-semibold">
            {t("main.jobsTabs.myJobs.applicationsTrend")}
          </h2>
          <Line data={applicationsTrendData} />
        </div>
        <div className="rounded-lg border border-gray-300 bg-white p-6">
          <h2 className="mb-4 text-xl font-semibold">{t("main.jobsTabs.myJobs.jobCategories")}</h2>
          <Bar data={jobCategoryData} />
        </div>
        <div className="rounded-lg border border-gray-300 bg-white p-6">
          <h2 className="mb-4 text-xl font-semibold">
            {t("main.jobsTabs.myJobs.applicationStatus")}
          </h2>
          <Pie data={applicationStatusData} />
        </div>
      </div>

      {t("main.navLinks.jobsPublished.similarJobs")}
      <div className="w-full">
        <JobCarousel spaceBetween={20} slidesPerView={5} mousewheel={true}>
          {jobsResult.data?.data.map(job => (
            <>
              <SwiperSlide className="w-full p-2">
                <JobCard job={job} />
              </SwiperSlide>
            </>
          ))}
        </JobCarousel>
      </div>
    </div>
  );
};

export default StatisticsContainer;
