import api, { RequestWithId } from "@src/api/api";
import { Product } from "@src/utils/product";

export type ProductRequest = RequestWithId;

export type ProductResponse = {
  data: Product;
};

export default async function product(request: ProductRequest): Promise<ProductResponse> {
  const { data } = await api.get(`/api/v1/marketplace/product/${request.id}`);
  return data;
}
