import * as Sentry from "@sentry/react";
import BlockUserModal from "@src/components/elements/main/BlockUserModal";
import ReportModal from "@src/components/elements/main/ReportModal";
import CookiesPopup from "@src/components/popup/CookiesPopup";
import LoginExtendedPopup from "@src/components/popup/LoginExtendedPopup";
import PaymentIntegrationPopup from "@src/components/popup/PaymentIntegrationPopup";
import { NotificationContextProvider } from "@src/contexts/NotificationContext";
import init from "@src/i18n";
import Router from "@src/routes/Router";
import { useAppSelector } from "@src/state/hooks";
import useLoginModal from "@src/state/modal/useLoginModal";
import usePaymentModal from "@src/state/modal/usePaymentModal";
import { persistor } from "@src/state/store";
import { getCookie, setCookie } from "cookies-next";
import { createContext, useEffect, useState } from "react";
import { Toaster } from "react-hot-toast";
import { useLocation } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";

function FallbackComponent() {
  return <div>An error has occured</div>;
}

type FullscreenType = {
  fullscreen: boolean;
  setFullscreen?: (fullscreen: boolean) => void;
  topNavHidden: boolean;
  setTopNavHidden?: (topNavHidden: boolean) => void;
  bottomNavHidden: boolean;
  setBottomNavHidden?: (bottomNavHidden: boolean) => void;
  sidebarHidden: boolean;
  setSidebarHidden?: (sidebarHidden: boolean) => void;
  sidebarNavHidden: boolean;
  setSidebarNavHidden?: (sidebarNavHidden: boolean) => void;
  borderless: boolean;
  setBorderless?: (borderless: boolean) => void;
  darkTheme: boolean;
  setDarkTheme?: (darkTheme: boolean) => void;
  wideScreen: boolean;
  setWideScreen?: (wideScreen: boolean) => void;
};

export const AppContext = createContext<FullscreenType | null>(null);

const loginBlacklist: Array<string> = [
  "/login",
  "/registrierung",
  "/verwaltung/passwort",
  "/info",
  "/help",
  "/marketing",
  "/impressum",
  "/datenschutzrichtlinie",
  "/agb",
  "/business",
  "/glossary",
  "/getapp",
];

const paymentWhitelist: Array<string> = [];

const App = () => {
  const location = useLocation();
  const [fullscreenMode, setFullscreenMode] = useState<boolean>(false);
  const [topNavHiddenMode, setTopNavHiddenMode] = useState<boolean>(false);
  const [bottomNavHiddenMode, setBottomNavHiddenMode] = useState<boolean>(false);
  const [sidebarHiddenMode, setSidebarHiddenMode] = useState<boolean>(false);
  const [borderlessMode, setBorderlessMode] = useState<boolean>(false);
  const [darkThemeMode, setDarkThemeMode] = useState<boolean>(false);
  const [wideScreenMode, setWideScreenMode] = useState<boolean>(false);
  const [isCookiesPopupOpen, setIsCookiesPopupOpen] = useState<boolean>(true);
  const [userHandleCookies, setUserHandleCookies] = useState<boolean>(false);

  const loginModal = useLoginModal();
  const paymentModal = usePaymentModal();
  const fullpath = useLocation().pathname;
  const { user } = useAppSelector(state => state.user);

  useEffect(() => {
    const hasAccepted = getCookie("cookieConsent");
    if (hasAccepted) {
      setUserHandleCookies(true);
    }
  }, []);

  useEffect(() => {
    // HubSpot tracking
    if ((window as any)._hsq) {
      (window as any)._hsq.push(["setPath", location.pathname]);
      (window as any)._hsq.push(["trackPageView"]);
    }
    setFullscreenMode(false);
    setTopNavHiddenMode(false);
    setBottomNavHiddenMode(false);
    setSidebarHiddenMode(false);
    setBorderlessMode(false);
    setDarkThemeMode(false);
    setWideScreenMode(false);
  }, [location]);

  const onBeforeLift = async () => {
    await init();
  };

  const handleConsent = (decision: string) => {
    setCookie("cookieConsent", decision, {
      maxAge: 60 * 60 * 24 * 365, // 365 Tage in Sekunden
      path: "/",
      secure: true,
      sameSite: "strict",
    });
    setUserHandleCookies(true);
    setIsCookiesPopupOpen(false);
  };

  const handleCookiesBannerOpen = () => {
    setIsCookiesPopupOpen(false);
  };

  return (
    <PersistGate onBeforeLift={onBeforeLift} loading={null} persistor={persistor}>
      <NotificationContextProvider>
        <Sentry.ErrorBoundary fallback={FallbackComponent} showDialog>
          <AppContext.Provider
            value={{
              fullscreen: fullscreenMode,
              setFullscreen: setFullscreenMode,
              topNavHidden: topNavHiddenMode,
              setTopNavHidden: setTopNavHiddenMode,
              bottomNavHidden: bottomNavHiddenMode,
              setBottomNavHidden: setBottomNavHiddenMode,
              sidebarHidden: sidebarHiddenMode,
              setSidebarHidden: setSidebarHiddenMode,
              sidebarNavHidden: sidebarHiddenMode,
              setSidebarNavHidden: setSidebarHiddenMode,
              borderless: borderlessMode,
              setBorderless: setBorderlessMode,
              darkTheme: darkThemeMode,
              setDarkTheme: setDarkThemeMode,
              wideScreen: wideScreenMode,
              setWideScreen: setWideScreenMode,
            }}
          >
            <Toaster />
            <Router />
            <ReportModal />
            <BlockUserModal />
            {!userHandleCookies && isCookiesPopupOpen && (
              <CookiesPopup onClose={handleCookiesBannerOpen} onConsent={handleConsent} />
            )}
            {loginModal.isOpen && !loginBlacklist.includes(fullpath) && <LoginExtendedPopup />}
            {user && paymentModal.isOpen && paymentWhitelist.includes(fullpath) && (
              <PaymentIntegrationPopup />
            )}
          </AppContext.Provider>
        </Sentry.ErrorBoundary>
      </NotificationContextProvider>
    </PersistGate>
  );
};

export default Sentry.withProfiler(App);
