// This whole File is only for a workaround till s3 CORS is fixed. Can be removed after it is fixed
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "@src/state/store";

interface ImageEditorState {
  imageEditor: string | File;
}

const initialState: ImageEditorState = {
  imageEditor: "",
};

export const imageEditorSlice = createSlice({
  name: "imageEditor",
  initialState,
  reducers: {
    setImageEditor: (state, { payload }: PayloadAction<string | File>) => {
      state.imageEditor = payload;
    },
  },
});

export const selectNotifications = (state: RootState) => state.notifications.value;

export const { setImageEditor } = imageEditorSlice.actions;

export default imageEditorSlice.reducer;
