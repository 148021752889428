import api from "@src/api/api";
import { Cart } from "@src/utils/cart";

export type CartsResponse = {
  data: Cart[];
};

export default async function carts(): Promise<CartsResponse> {
  const { data } = await api.get("/api/v1/carts");
  return data;
}
