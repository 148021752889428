import MultipleFileUpload, {
  UploadedFile,
} from "@src/components/elements/form/buttons/MultipleFileUpload";
import Input from "@src/components/elements/input/Input";
import StarsRating from "@src/components/elements/input/StarsRating";
import ModalWrapper from "@src/components/elements/shared/ModalWrapper";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import TextareaAutosize from "react-textarea-autosize";
import tw from "twin.macro";
interface ProductRatingHistogramProps {
  product_title: string;
}

const ProductReviewButton: React.FC<ProductRatingHistogramProps> = ({ product_title }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");

  const [, setUploadedFiles] = useState<UploadedFile[]>([]);
  const handleFilesChange = (files: UploadedFile[]) => {
    setUploadedFiles(files);
  };

  return (
    <>
      <ModalWrapper
        onCancel={() => {
          setOpen(false);
        }}
        open={open}
      >
        <div className="flex flex-col items-start">
          <div className="mb-2 text-xl font-semibold">Create a review</div>
          <div className="flex flex-row">
            <img
              className="mr-2 size-16 rounded-lg"
              src="https://tradefoox-beta.b-cdn.net/media/images/avatars/xUGccPhKBT/6659891.jpeg"
              alt="Neil image"
            />
            <div className="text-sm font-semibold">{product_title}</div>
          </div>
          <hr className="my-2 h-px w-full border-0 bg-gray-200 dark:bg-gray-700"></hr>

          <div className="">{t("main.shop.details.review")}</div>
          <div className="">
            <StarsRating rating={4} />
          </div>
          <hr className="my-2 h-px w-full border-0 bg-gray-200 dark:bg-gray-700"></hr>
        </div>

        <div className="flex flex-col gap-2">
          <Input type="text" name="Title" placeholder="Title" />
          <hr className="my-2 h-px w-full border-0 bg-gray-200 dark:bg-gray-700"></hr>

          <TextareaAutosize
            minRows={3}
            value={message}
            onChange={e => setMessage(e.target.value)}
            placeholder={t("main.shop.details.writeReview")}
            maxLength={250}
            css={[tw`block w-full rounded-xl py-3 px-4 bg-gray-200 border-0`]}
          />
        </div>
        <hr className="my-2 h-px w-full border-0 bg-gray-200 dark:bg-gray-700" />
        <MultipleFileUpload limit={4} onChange={handleFilesChange} />
      </ModalWrapper>

      <button
        onClick={() => {
          setOpen(true);
        }}
      >
        {t("main.shop.details.writeReview")}
      </button>
    </>
  );
};

export default ProductReviewButton;
