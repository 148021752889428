import { User } from "@src/utils/user";

const getName = (user: User) => {
  if (user.type === "person" && user.person)
    return `${user.person.firstname} ${user.person.lastname}`;
  if (user.type === "company" && user.company) return user.company.name;
  if (user.type === "club" && user.club) return user.club.name;
  return undefined;
};

export default getName;
