import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/solid";
import { RefObject, useEffect, useState } from "react";

const ScrollIcons = ({ galleryRef }: { galleryRef: RefObject<HTMLDivElement> }) => {
  const [isAtStart, setIsAtStart] = useState(true);
  const [isAtEnd, setIsAtEnd] = useState(false);

  useEffect(() => {
    const updateScrollPosition = () => {
      if (galleryRef.current) {
        const { scrollLeft, scrollWidth, clientWidth } = galleryRef.current;
        setIsAtStart(scrollLeft <= 0);
        setIsAtEnd(scrollLeft + clientWidth >= scrollWidth);
      }
    };
    updateScrollPosition();

    const currentGalleryRef = galleryRef.current;
    if (currentGalleryRef) {
      currentGalleryRef.addEventListener("scroll", updateScrollPosition);
      return () => currentGalleryRef.removeEventListener("scroll", updateScrollPosition);
    }
  }, [galleryRef]);

  if (!galleryRef) return <></>;

  return (
    <>
      {!isAtStart && (
        <div
          className="pointer-events-none absolute left-0 top-1/2 flex h-full w-20 -translate-y-1/2 cursor-pointer items-center sm:pointer-events-auto"
          onClick={() => {
            if (galleryRef.current)
              galleryRef.current.scrollTo(galleryRef.current.scrollLeft - 150, 0);
          }}
        >
          <div className="group absolute left-0 top-0 z-0 flex size-full items-center justify-center opacity-0 hover:opacity-100">
            <div className="rounded-full bg-white p-2 group-hover:bg-white/50">
              <ChevronLeftIcon className="z-50 mx-auto size-[25px]" />
            </div>
          </div>
        </div>
      )}

      {!isAtEnd && (
        <div
          className="pointer-events-none absolute right-0 top-1/2 flex h-full w-20 -translate-y-1/2 cursor-pointer items-center sm:pointer-events-auto"
          onClick={() => {
            if (galleryRef.current)
              galleryRef.current.scrollTo(galleryRef.current.scrollLeft + 150, 0);
          }}
        >
          <div className="group absolute right-0 top-0 z-0 flex size-full items-center justify-center opacity-0 hover:opacity-100">
            <div className="rounded-full bg-white p-2 group-hover:bg-white/50">
              <ChevronRightIcon className="z-50 mx-auto size-[25px]" />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ScrollIcons;
