import { AppContext } from "@src/App";
import usePopupModal from "@src/state/modal/usePopupModal";
import { useContext } from "react";
import { Outlet } from "react-router-dom";
import tw from "twin.macro";

const MobileMainWrapper = () => {
  const appContext = useContext(AppContext);
  const popupModal = usePopupModal();

  return (
    <div
      css={[
        tw`h-[calc(100dvh-calc(env(safe-area-inset-top)+env(safe-area-inset-bottom)))] mt-[env(safe-area-inset-top)] mb-[env(safe-area-inset-bottom)]`,
        (appContext?.fullscreen || appContext?.topNavHidden) &&
          tw`grid grid-rows-[calc(100dvh-calc(env(safe-area-inset-top)+env(safe-area-inset-bottom)))] md:grid-rows-[50px,calc(calc(100dvh-50px)-calc(env(safe-area-inset-top)+env(safe-area-inset-bottom)))]`,
        appContext?.darkTheme && tw`max-md:bg-neutral-900 max-md:text-white`,
        !appContext?.darkTheme && tw`bg-white text-black`,
      ]}
    >
      <main className="overflow-x-hidden overflow-y-scroll scrollbar-none">
        <Outlet />
      </main>
      {popupModal.stack.length > 0 && popupModal.stack[0]}
    </div>
  );
};

export default MobileMainWrapper;
