export const SHOW_BLOCK_USER_MODAL = "SHOW_BLOCK_USER_MODAL" as const;
export const HIDE_BLOCK_USER_MODAL = "HIDE_BLOCK_USER_MODAL" as const;

export type ShowBlockUserModalAction = {
  type: typeof SHOW_BLOCK_USER_MODAL;
  payload: {
    userId: string;
  };
};

export type HideBlockUserModalAction = {
  type: typeof HIDE_BLOCK_USER_MODAL;
};

export type BlockUserModalActions = ShowBlockUserModalAction | HideBlockUserModalAction;
