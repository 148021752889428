import { XMarkIcon } from "@heroicons/react/24/outline";
import { useLocation, useNavigate } from "react-router-dom";
import tw, { TwStyle } from "twin.macro";

type Props = {
  className?: TwStyle;
  bgColor?: TwStyle;
  title?: string;
  stepback?: boolean;
  desktopVisible?: boolean;
};

function BackButton({
  className,
  bgColor,
  title,
  stepback = false,
  desktopVisible = false,
}: Props) {
  const location = useLocation();
  const navigate = useNavigate();

  if (location.state?.backlink || stepback)
    return (
      <button
        css={[
          tw`flex gap-4 items-center rounded-full w-fit`,
          !desktopVisible && tw`lg:hidden`,
          className,
        ]}
        onClick={() => {
          if (stepback) {
            if (location.state?.backlink) {
              navigate(location.state.backlink);
            } else {
              navigate(-1);
            }
          } else {
            navigate(location.state?.backlink);
          }
        }}
      >
        <div css={[tw`rounded-full`, bgColor]}>
          <XMarkIcon className="size-5 text-gray-700" />
          {title}
        </div>
      </button>
    );

  return <></>;
}

export default BackButton;
