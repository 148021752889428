import Panel from "@src/components/box/Panel";
import ScrollIcons from "@src/components/elements/shared/ScrollIcons";
import { ReactNode, useRef } from "react";
import { useTranslation } from "react-i18next";

type Props = {
  children?: ReactNode;
  title?: string;
};

const PopularWidget = ({ children, title }: Props) => {
  const galleryRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  return (
    <Panel title={title ?? t("main.contacts.popular")} mobileBorder={false}>
      <div className="no-scrollbar flex h-[75px] gap-2 overflow-x-auto" ref={galleryRef}>
        {children}
        {!!(galleryRef && children) && <ScrollIcons galleryRef={galleryRef} />}
      </div>
    </Panel>
  );
};

export default PopularWidget;
