import { XCircleIcon } from "@heroicons/react/24/outline";
import Button from "@src/components/elements/input/Button";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import tw, { TwStyle } from "twin.macro";

enum MessageVariants {
  Error = "error",
  Info = "info",
  OK = "ok",
  Warning = "warning",
}

enum ButtonVariants {
  None = "none",
  Clean = "clean",
  OK = "ok",
  YesNo = "yesno",
  RetryOK = "retryok",
  YesNoCancel = "yesnocancel",
  RetryCancel = "retrycancel",
}

type Props = {
  title?: string;
  message?: string;
  timeout?: number;
  variant?: MessageVariants;
  buttons?: ButtonVariants;
  yesClick?: () => void;
  noClick?: () => void;
  retryClick?: () => void;
  cancelClick?: () => void;
  onClose: () => void;
  okLabel?: string;
  yesLabel?: string;
  noLabel?: string;
  cancelLabel?: string;
  retryLabel?: string;
  windowStyle?: TwStyle;
};

const _Alert = ({
  title = "title",
  message = "message text",
  variant = MessageVariants.Error,
  buttons = ButtonVariants.OK,
  timeout = 5000,
  yesClick,
  noClick,
  retryClick,
  cancelClick,
  onClose,
  okLabel,
  yesLabel,
  noLabel,
  cancelLabel,
  retryLabel,
  windowStyle,
}: Props) => {
  const [isClosing, setIsClosing] = useState(false);
  const { t } = useTranslation();

  const colorStyle = {
    error: {
      backgroundColor: "bg-red-100",
      borderColor: "border-red-500",
      textColor: "text-red-700",
    },
    info: {
      backgroundColor: "bg-orange-100",
      borderColor: "border-orange-500",
      textColor: "text-orange-700",
    },
    ok: {
      backgroundColor: "bg-green-100",
      borderColor: "border-green-500",
      textColor: "text-green-700",
    },
    warning: {
      backgroundColor: "bg-yellow-100",
      borderColor: "border-yellow-500",
      textColor: "text-yellow-700",
    },
  };

  const buttonConfig = {
    clean: ["clean"],
    none: [""],
    ok: ["ok"],
    yesno: ["yes", "no"],
    yesnocancel: ["yes", "no", "cancel"],
    retrycancel: ["retry", "cancel"],
    retryok: ["retry", "ok"],
  };

  useEffect(() => {
    if (timeout > 0) {
      const closeTimer = setTimeout(() => {
        onClose();
      }, timeout);
      return () => {
        clearTimeout(closeTimer);
      };
    }
  }, [timeout, onClose]);

  /**
   * Run onclick function of button then close alert box
   * @param clickFunction onclick function of the clicked button
   */
  function clickHandler(clickFunction: (() => void) | undefined, closeOnClick = true) {
    if (clickFunction) clickFunction();
    if (closeOnClick) {
      setIsClosing(true);
      setTimeout(function () {
        onClose();
      }, 500);
    }
  }

  return (
    <div
      className={`${colorStyle[variant].backgroundColor} border-l-4 ${
        colorStyle[variant].borderColor
      } ${
        colorStyle[variant].textColor
      } fixed inset-0 left-1/2 z-50 mb-auto mt-[env(safe-area-inset-top)] h-fit min-h-[50px] w-[90vw] animate-slidedown py-1 pl-2 pr-1 ${
        isClosing &&
        "animate-slidedown opacity-0 transition-opacity delay-[1000] duration-1000 ease-out"
      }`}
      css={[windowStyle ?? tw`max-w-[300px]`]}
    >
      <div className="flex flex-row items-start justify-between pb-2">
        <div className="font-bold">{title}</div>
        {!buttonConfig[buttons].includes("clean") && (
          <button
            onClick={() => {
              clickHandler(cancelClick);
            }}
          >
            <XCircleIcon className="w-7 cursor-pointer hover:brightness-125" />
          </button>
        )}
      </div>
      <div className="whitespace-pre-line">{message}</div>
      <div className="flex flex-row gap-2">
        {buttonConfig[buttons].includes("ok") && (
          <Button
            onClick={() => {
              clickHandler(yesClick);
            }}
            size={Button.Sizes.Small}
          >
            {okLabel ?? "OK"}
          </Button>
        )}
        {buttonConfig[buttons].includes("yes") && (
          <Button
            onClick={() => {
              clickHandler(yesClick);
            }}
            size={Button.Sizes.Small}
          >
            {yesLabel ?? t("components.shared.yes")}
          </Button>
        )}
        {buttonConfig[buttons].includes("no") && (
          <Button
            onClick={() => {
              clickHandler(noClick);
            }}
            size={Button.Sizes.Small}
          >
            {noLabel ?? t("components.shared.no")}
          </Button>
        )}
        {buttonConfig[buttons].includes("retry") && (
          <Button
            onClick={() => {
              clickHandler(retryClick, false);
            }}
            size={Button.Sizes.Small}
          >
            {retryLabel ?? t("components.shared.retry")}
          </Button>
        )}
        {buttonConfig[buttons].includes("cancel") && (
          <Button
            onClick={() => {
              clickHandler(cancelClick);
            }}
            size={Button.Sizes.Small}
          >
            {cancelLabel ?? t("buttons.cancel")}
          </Button>
        )}
      </div>
    </div>
  );
};

const Alert = Object.assign(_Alert, {
  MessageVariants,
  ButtonVariants,
});

export default Alert;
