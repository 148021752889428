import { Menu } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import ModalWrapper from "@src/components/elements/shared/ModalWrapper";
import { useAppSelector } from "@src/state/hooks";
import getName from "@src/utils/getName";
import isApp from "@src/utils/isApp";
import { User } from "@src/utils/user";
import { useRef, useState } from "react";
import tw from "twin.macro";

type Props = {
  dark?: boolean;
  onSelect?: (user: User) => void;
  typeFilter?: Array<string>;
};

const PostUserSelect = (props: Props) => {
  const { user } = useAppSelector(state => state.user);
  const [open, setOpen] = useState(false);
  const ref = useRef<HTMLButtonElement>(null);

  const [selectedUser, setSelectedUser] = useState<number>(0);

  if (!user) {
    return null;
  }

  const userOptions: Array<{
    value: User;
    icon: JSX.Element;
    label: string;
  }> = [
    {
      value: user,
      icon: (
        <img
          src={(user.avatar !== "" && user.avatar) || `/images/placeholder/${user.type}.png`}
          className="size-8 rounded-full border"
        />
      ),
      label: (user ? getName(user) : "") || "",
    },
  ];

  if (isApp || window.innerWidth < 1280) {
    return (
      <>
        <button
          className="flex items-center gap-2 rounded-full text-gray-400 hover:text-gray-600"
          onClick={e => {
            setOpen(true);
            e.stopPropagation();
          }}
        >
          {userOptions[selectedUser].icon}
          <ChevronDownIcon className="size-4 text-black" />
        </button>
        <ModalWrapper open={open} onCancel={() => setOpen(false)} hideActions={true}>
          {userOptions.map(
            (item, index) =>
              !!item && (
                <button
                  key={index}
                  onClick={e => {
                    props.onSelect && props.onSelect(item.value);

                    setSelectedUser(index);
                    e.stopPropagation();
                  }}
                  type="submit"
                  css={[
                    tw`text-gray-700 overflow-x-hidden`,
                    tw`block w-full py-2 text-left text-sm inline-flex items-center justify-between`,
                    tw`flex-row-reverse`,
                    tw`justify-end gap-2`,
                  ]}
                >
                  <input type="radio" checked={index === selectedUser} />
                  <span className="truncate whitespace-nowrap">{item.label}</span>
                  {item.icon}
                </button>
              ),
          )}
        </ModalWrapper>
      </>
    );
  } else {
    return (
      <>
        <Menu as="div" className="inline-block text-left" ref={ref}>
          <Menu.Button
            className="flex items-center gap-2 rounded-full text-gray-400 hover:text-gray-600"
            onClick={e => {
              setOpen(true);
              e.stopPropagation();
            }}
          >
            {userOptions[selectedUser].icon}
            <ChevronDownIcon className="size-4 text-black" />
          </Menu.Button>

          <Menu.Items className="absolute z-20 mt-2 w-56 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="py-1">
              {userOptions.map(
                (item, index) =>
                  !!item && (
                    <div
                      className="mx-1 rounded-md text-gray-700 hover:bg-gray-200 hover:text-gray-900"
                      key={index}
                    >
                      <button
                        key={index}
                        onClick={e => {
                          setSelectedUser(index);
                          e.stopPropagation();
                        }}
                        type="submit"
                        css={[
                          tw`overflow-x-hidden`,
                          tw`block w-full px-2 py-2 text-left text-sm inline-flex items-center justify-between`,
                          tw`flex-row-reverse`,
                          tw`justify-end gap-2`,
                        ]}
                      >
                        <input type="radio" checked={index === selectedUser} />
                        <span className="truncate whitespace-nowrap">{item.label}</span>
                        {item.icon}
                      </button>
                    </div>
                  ),
              )}
            </div>
          </Menu.Items>
        </Menu>
      </>
    );
  }
};

export default PostUserSelect;
