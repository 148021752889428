import api, { requestParams, RequestWithId, RequestWithParams } from "@src/api/api";

export type MediaParams = {
  page?: number;
  watch: string;
};

export type MediaRequest = RequestWithId & RequestWithParams<MediaParams>;

export type MediaData = {
  media_id: string;
  post_id: string;
  media_thumbnail: string;
  media_file_name: string;
  media_key: string;
  media_type: string;
  data_url: string;
};

export type MediaResponse = {
  data: MediaData[];
  current_page: number;
  total_pages: number;
};

export default async function media(request: MediaRequest): Promise<MediaResponse> {
  const { data } = await api.get(
    `/api/v1/users/${request.id}/media${requestParams({ page: 0, ...request.params })}`,
  );
  return data;
}
