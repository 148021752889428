import payment from "@src/api/payment/payment";
import Alert from "@src/components/elements/Alert";
import PopupWrapper from "@src/components/elements/PopupWrapper";
import Button from "@src/components/elements/input/Button";
import usePaymentModal from "@src/state/modal/usePaymentModal";
import usePopupModal from "@src/state/modal/usePopupModal";
import { useMutation } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

const PaymentPopup = () => {
  const { t } = useTranslation();
  const paymentModal = usePaymentModal();
  const popupModal = usePopupModal();

  const paymentMutation = useMutation({
    mutationKey: ["payment-integration"],
    mutationFn: payment,
    onSuccess: ({ data }) => {
      const account_link = data.account_link;
      setTimeout(() => window.location.assign(account_link), 200);
      paymentModal.close();
    },
    onError: () => {
      popupModal.open(
        <Alert
          buttons={Alert.ButtonVariants.OK}
          onClose={popupModal.close}
          message={t("main.alerts.paymentError.message")}
          title={t("main.alerts.paymentError.title")}
        />,
      );
    },
  });

  return (
    <PopupWrapper onClose={() => paymentModal.close()}>
      <div className="w-full md:max-w-[310px]">
        <div className="grid grid-flow-row justify-center gap-1 text-center text-black">
          <div className="text-xl font-medium text-darkblue">
            TRADEFOOX <br /> {t("main.alerts.paymentError.title")}
          </div>
          <Button
            submit
            onClick={() => {
              if (!paymentMutation.isPending) paymentMutation.mutate();
            }}
          >
            {t("components.shared.integrate")}
          </Button>
          <Button
            onClick={() => {
              paymentModal.close();
            }}
            variant={Button.Variants.Cyan}
          >
            {t("buttons.cancel")}
          </Button>
        </div>
      </div>
    </PopupWrapper>
  );
};

export default PaymentPopup;
