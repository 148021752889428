import blocked from "@src/api/me/blocked";
import ShowMoreButton from "@src/components/elements/input/ShowMoreButton";
import ContactItem from "@src/pages/contacts/ContactItem";
import { User } from "@src/utils/user";
import { useInfiniteQuery } from "@tanstack/react-query";

type Props = {
  searchQuery?: string;
};

const BlockedList = ({ searchQuery }: Props) => {
  const blockedResult = useInfiniteQuery({
    queryKey: ["blocked", searchQuery],
    queryFn: ({ pageParam }) => blocked({ params: { page: pageParam, q: searchQuery } }),
    initialPageParam: 0,
    getNextPageParam: lastPage => {
      const nextPage = lastPage.current_page + 1;
      return nextPage <= lastPage.total_pages ? nextPage : null;
    },
  });

  return (
    <>
      <div className="grid grid-cols-2 gap-4 md:grid-cols-3">
        {blockedResult.data?.pages.map(page =>
          page.data.map((item: User) => (
            <ContactItem key={item.id} mode="my-followers" user={item} />
          )),
        )}
      </div>
      {blockedResult.hasNextPage && !blockedResult.isFetchingNextPage && (
        <ShowMoreButton onClick={() => blockedResult.fetchNextPage()} />
      )}
    </>
  );
};

export default BlockedList;
