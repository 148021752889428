import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "@src/state/store";

interface HeaderImageState {
  headerImage: File | null;
}

const initialState: HeaderImageState = {
  headerImage: null,
};

export const headerImageSlice = createSlice({
  name: "headerImage",
  initialState,
  reducers: {
    setHeaderImage: (state, { payload }: PayloadAction<File>) => {
      state.headerImage = payload;
    },
  },
});

export const selectNotifications = (state: RootState) => state.notifications.value;

export const { setHeaderImage } = headerImageSlice.actions;

export default headerImageSlice.reducer;
