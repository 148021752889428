import { useAppSelector } from "@src/state/hooks";
import { ReactNode } from "react";
import tw, { TwStyle } from "twin.macro";

type Props = {
  value: string;
  options: Array<
    | {
        name: string;
        key: string;
        auth?: boolean;
        mobileHidden?: boolean;
        icon?: ReactNode;
        selectIcon?: ReactNode;
      }
    | undefined
    | false
  >;
  subTab?: boolean;
  slideable?: boolean;
  scrollbar?: TwStyle;
  onChange: (value: string) => void;
  centered?: boolean;
};

const TabFilter = ({ value, options, onChange, subTab, slideable, scrollbar, centered }: Props) => {
  const { user } = useAppSelector(state => state.user);
  return (
    <div
      className=""
      css={[
        subTab ? tw`flex justify-start gap-2 text-xs` : tw`flex justify-between text-sm`,
        tw`mb-3`,
        slideable && tw`overflow-x-auto gap-8`,
        centered && tw`justify-center gap-4`,
        scrollbar,
      ]}
    >
      {options.map(option =>
        option && (!option.auth || !!user) ? (
          <button
            key={option.key}
            css={[
              tw`relative`,
              option.key === value ? tw`border-b-2 text-gray-900` : tw`text-gray-500`,
              subTab ? tw`border-gray-400` : tw`border-darkblue`,
              option?.mobileHidden && tw`max-lg:hidden`,
            ]}
            onClick={() => onChange(option.key)}
            className="flex items-center gap-1 whitespace-nowrap"
          >
            {option.key === value ? (option.selectIcon ?? option.icon) : option.icon}
            {option.name}
          </button>
        ) : null,
      )}
    </div>
  );
};

export default TabFilter;
