import ProgressBar from "@ramonak/react-progress-bar";
import apiStatistics from "@src/api/users/statistics";
import StatisticsItem from "@src/pages/contacts/contacts/statistics/StatisticsItem";
import { User } from "@src/utils/user";
import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart,
  Legend,
  LinearScale,
  Tooltip,
} from "chart.js";
import "chartjs-plugin-datalabels";
import { useEffect, useState } from "react";
import { Bar, Doughnut } from "react-chartjs-2";
import { useTranslation } from "react-i18next";
interface StatisticsData {
  ageCount: Record<number, number>;
  agePercentage: Record<number, number>;
  cityCount: Record<string, number>;
  cityPercentage: Record<string, number>;
  commentCountLastWeek: number;
  commentCountWeekBefore: number;
  countryCount: Record<string, number>;
  countryPercentage: Record<string, number>;
  genderCount: Record<string, number>;
  genderPercentage: Record<string, number>;
}

type Props = {
  user: User;
};

Chart.register(CategoryScale, LinearScale, ArcElement, BarElement, Tooltip, Legend);

const PrivateStatisticsContainer = ({ user }: Props) => {
  const { t } = useTranslation();
  const [statistics, setStatistics] = useState<StatisticsData | null>(null);
  const countryPercentages = statistics?.countryPercentage || {};
  const cityPercentages = statistics?.cityPercentage || {};

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await apiStatistics({ id: user.id });

        setStatistics(data);
      } catch (error) {
        console.error("Error fetching user statistics", error);
      }
    };

    fetchData();
  }, [user]);
  if (user.follower_count === 0) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <h1 style={{ fontSize: "2em", color: "#18677d", lineHeight: "1.1" }}>
          {t("main.profileViewTabs.aboutMe.statistics.nullFollowers")}
        </h1>
        <div
          style={{
            fontSize: "1.2em",
            color: "#555",
            textAlign: "center",
            maxWidth: "500px",
            marginTop: "50px",
          }}
        >
          {t("main.profileViewTabs.aboutMe.statistics.nullFollowersSubText")}
        </div>
      </div>
    );
  }

  const ageRanges = {
    "18-24": 0,
    "25-34": 0,
    "35-44": 0,
    "45-54": 0,
    "55+": 0,
  };
  Object.entries(statistics?.ageCount || {}).forEach(([age, count]) => {
    const ageNumber = Number(age);
    if (ageNumber >= 18 && ageNumber <= 24) {
      ageRanges["18-24"] += count;
    } else if (ageNumber >= 25 && ageNumber <= 34) {
      ageRanges["25-34"] += count;
    } else if (ageNumber >= 35 && ageNumber <= 44) {
      ageRanges["35-44"] += count;
    } else if (ageNumber >= 45 && ageNumber <= 54) {
      ageRanges["45-54"] += count;
    } else if (ageNumber >= 55) {
      ageRanges["55+"] += count;
    }
  });

  const totalAgeCount = Object.values(ageRanges).reduce((sum, count) => sum + count, 0);

  const agePercentages = Object.values(ageRanges).map(count => (count / totalAgeCount) * 100);

  const barData = {
    labels: Object.keys(ageRanges),
    datasets: [
      {
        label: "Alter",
        data: agePercentages,
        backgroundColor: "#10A2C5",
        borderColor: "#10A2C5",
        barPercentage: 0.5,
        categoryPercentage: 1,
      },
    ],
  };

  //TortenDiagram

  const genderColors: { [key: string]: string } = {
    male: "#18677d",
    female: "#17bdeb",
    diverse: "#0d272e",
  };

  const genderNames: { [key: string]: string } = {
    male: "Männlich",
    female: "Weiblich",
    diverse: "Divers",
  };

  const pieData = {
    labels: Object.keys(statistics?.genderPercentage || {}).map(gender => genderNames[gender]),
    datasets: [
      {
        data: Object.values(statistics?.genderPercentage || {}),
        backgroundColor: Object.keys(statistics?.genderPercentage || {}).map(
          gender => genderColors[gender],
        ),
      },
    ],
  };

  if (!statistics) {
    return <h1>Loading.....</h1>;
  }

  return (
    <div className="flex flex-col gap-4 pb-8">
      <div className="max-lg:flex max-lg:flex-col max-lg:px-4 lg:grid lg:grid-cols-3 lg:justify-evenly lg:gap-12">
        <div className="flex flex-col lg:gap-8">
          {/* Doughnut chart for gender distribution */}
          {Object.keys(statistics.genderPercentage).length > 0 && (
            <div className="min-w-[280px] max-w-full rounded-lg bg-white p-4 lg:border lg:shadow-md">
              <h1 className="mb-2 text-xl font-bold">
                {t("main.profileViewTabs.editProfile.form.gender")}
              </h1>
              <Doughnut
                data={pieData}
                options={{
                  cutout: "60%",
                  responsive: true,
                  plugins: {
                    legend: {
                      display: true,
                    },
                  },
                }}
              />
            </div>
          )}
        </div>
        <div className="flex flex-col lg:gap-8">
          {/* Bar chart for age distribution */}
          {Object.keys(statistics.ageCount).length > 0 && (
            <div className="min-w-[280px] max-w-full flex-auto rounded-lg bg-white p-4 lg:border lg:shadow-md">
              <h1 className="mb-2 text-xl font-bold">
                {t("main.profileViewTabs.editProfile.form.age")}
              </h1>
              <div className="h-72">
                <Bar
                  data={barData}
                  options={{
                    responsive: true,
                    maintainAspectRatio: false,
                    plugins: {
                      datalabels: {
                        display: false,
                      },
                      legend: {
                        display: false,
                      },
                    },
                    scales: {
                      y: {
                        beginAtZero: true,
                        ticks: {
                          callback: function (value) {
                            return value + "%";
                          },
                        },
                      },
                      x: {
                        grid: {
                          display: false,
                        },
                      },
                    },
                  }}
                />
              </div>
            </div>
          )}
        </div>
        <div className="flex flex-col lg:gap-8">
          {/* Progress bar for country distribution */}
          {Object.keys(statistics.countryPercentage).length > 0 && (
            <div className="min-w-[280px] max-w-full flex-auto rounded-lg bg-white p-4 lg:border lg:shadow-md">
              <h1 className="mb-2 text-xl font-bold">
                {t("main.profileViewTabs.editProfile.form.country/city")}
              </h1>
              {Object.entries(countryPercentages).map(([country, percentage]) => (
                <div key={country} tw="mb-4">
                  <h4>{country}</h4>
                  <ProgressBar
                    completed={parseFloat(percentage.toFixed(1))}
                    bgColor="#10A2C5"
                    height="18px"
                    baseBgColor="#e0e0de"
                    borderRadius="5px"
                    labelColor="#fff"
                  />
                </div>
              ))}
            </div>
          )}
          {Object.keys(statistics.cityPercentage).length > 0 && (
            <div className="min-w-[280px] max-w-full flex-auto rounded-lg bg-white p-4 lg:border lg:shadow-md">
              <h1 className="mb-2 text-xl font-bold">
                {t("main.profileViewTabs.editProfile.form.city")}
              </h1>
              {Object.entries(cityPercentages).map(([city, percentage]) => {
                // Replace "not_known" with "Unbekannt"
                city =
                  city === "not_known"
                    ? t("main.profileViewTabs.aboutMe.content.sidebarButtons.unknown")
                    : city;

                return (
                  <div key={city} tw="mb-4">
                    <h4>{city}</h4>
                    <ProgressBar
                      completed={parseFloat(percentage.toFixed(1))}
                      bgColor="#10A2C5"
                      height="18px"
                      baseBgColor="#e0e0de"
                      borderRadius="5px"
                      labelColor="#fff"
                    />
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
      <div className="min-w-[280px] max-w-3xl rounded-lg bg-white p-4 lg:border lg:shadow-md">
        <StatisticsItem />
      </div>
    </div>
  );
};

export default PrivateStatisticsContainer;
