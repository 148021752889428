import api, { requestParams, RequestWithParams } from "@src/api/api";
import { User } from "@src/utils/user";

export type MyFollowersParams = {
  page?: number;
  q?: string;
};

export type MyFollowersRequest = RequestWithParams<MyFollowersParams>;

export type MyFollowersResponse = {
  data: User[];
  current_page: number;
  total_pages: number;
};

export default async function myFollowers(
  request: MyFollowersRequest,
): Promise<MyFollowersResponse> {
  const { data } = await api.get(
    `/api/v1/contacts/my_followers${requestParams({ page: 0, ...request.params })}`,
  );
  return data;
}
