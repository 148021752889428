import { Action, combineReducers, configureStore, ThunkAction } from "@reduxjs/toolkit";
import blockUserModalReducer from "@src/state/blockUserModal/reducer";
import { cartSlice } from "@src/state/cart/cartSlice";
import { modalSlice } from "@src/state/modal/modalSlice";
import { paymentModalSlice } from "@src/state/modal/paymentModalSlice";
import { popupSlice } from "@src/state/modal/popupSlice";
import { userBubbleSlice } from "@src/state/modal/userBubbleSlice";
import notificationsReducer from "@src/state/notifications/notificationsSlice";
import { avatarSlice } from "@src/state/profileImages/avatar";
import { headerImageSlice } from "@src/state/profileImages/headerImage";
import { imageEditorSlice } from "@src/state/profileImages/imageEditor";
import reportModalReducer from "@src/state/reportModal/reducer";
import userReducer from "@src/state/user/userSlice";
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from "redux-persist";
import storage from "redux-persist/lib/storage";

const userPersisted = persistReducer(
  {
    key: "user",
    version: 2,
    storage,
    whitelist: ["user", "token", "refreshToken", "notificationToken"],
  },
  userReducer,
);

const rootReducer = combineReducers({
  user: userPersisted,
  notifications: notificationsReducer,
  userBubble: userBubbleSlice.reducer,
  login: modalSlice.reducer,
  popups: popupSlice.reducer,
  reportModal: reportModalReducer,
  blockUserModal: blockUserModalReducer,
  paymentModal: paymentModalSlice.reducer,
  cart: cartSlice.reducer,
  avatar: avatarSlice.reducer,
  headerImage: headerImageSlice.reducer,
  // Only for s3 CORS workaround
  imageEditor: imageEditorSlice.reducer,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      immutableCheck: { warnAfter: 256 },
      serializableCheck: {
        warnAfter: 256,
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
