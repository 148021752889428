import api, { requestParams, RequestWithParams } from "@src/api/api";

export type ActivateParams = {
  code: string;
};

export type ActivateRequest = RequestWithParams<ActivateParams>;

export default async function activate(request: ActivateRequest): Promise<void> {
  const { data } = await api.get(`/api/v1/activate${requestParams(request.params)}`);
  return data;
}
