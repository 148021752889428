import { TrashIcon } from "@heroicons/react/24/outline";
import { Cart } from "@src/utils/cart";
import { useState } from "react";
import { NavLink } from "react-router-dom";

type Props = {
  item: Cart;
  key: number;
};

const SidebarCartItem = ({ item }: Props) => {
  const [count, setCount] = useState(item.quantity);

  function handleItemQuantity(event: React.ChangeEvent<HTMLSelectElement>) {
    const newQuantity = parseInt(event.target.value, 10);
    setCount(newQuantity);
  }

  function generateNumberList(count = 0) {
    const list: Array<{
      name: string;
      value: string;
    }> = [];
    for (let i = 1; i < count; i++) {
      const count = {
        name: String(i),
        value: String(i),
      };
      list.push(count);
    }
    list.push({
      name: String(count) + "+",
      value: String(count),
    });
    return list;
  }

  return (
    <>
      <div className="border-b border-gray-300 p-2">
        <NavLink to={"/marketplace/" + item.product_id} className="pb-1 hover:bg-gray-100">
          <img
            className="aspect-square w-full rounded object-cover outline-none dark:border-transparent dark:bg-gray-300"
            src="/placeholder.png"
          />
          <div className="text-center text-sm font-semibold">
            {(parseFloat(`${item.price_gross}`) / 100).toFixed(2)}€
          </div>
        </NavLink>
        <div className="flex flex-row items-center justify-between">
          <div className="rounded-xl py-0 text-left text-xs">
            <select
              className="h-[20px] w-[60px] rounded-xl border border-gray-300 px-4 py-0 text-left text-xs"
              value={count}
              onChange={handleItemQuantity}
            >
              {generateNumberList(10).map(count => {
                return (
                  <option key={count.name} value={count.value}>
                    {count.name}
                  </option>
                );
              })}
            </select>
          </div>

          <div className="flex h-[20px] w-[30px] cursor-pointer items-center justify-center rounded-xl border border-gray-300 p-1 py-0 text-xs">
            <TrashIcon className="size-4" />
          </div>
        </div>
      </div>
    </>
  );
};

export default SidebarCartItem;
