import ExternalLink from "@src/components/elements/shared/ExternalLink";
import BackButton from "@src/components/nav/BackButton";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

const LegalNoticeContainer = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="pl-4 pt-4">
        <BackButton stepback desktopVisible />
      </div>
      <div className="flex flex-col gap-3 pb-4 text-justify text-xs text-gray-700">
        <div className="text-left text-smbase font-bold">{t("main.signIn.imprint")}</div>
        <div className="font-semibold">{t("pages.legalNoticeContainer.text1")}</div>
        <div>{t("pages.legalNoticeContainer.text2")}</div>
        <div className="flex flex-col ">
          <div className="font-semibold">day one mediagroup GmbH & Co. KG</div>
          <div>August-Bebel-Straße 11/13</div>
          <div>09113 Chemnitz</div>
          <div>{t("pages.partner.placeholder1")}</div>
          <div>{t("main.navLinks.jobApplicationForm.phone")}: +49 (0) 371 / 52 480 900</div>
          <div>E-Mail: info@tradefoox.com</div>
          <div className="inline-flex gap-1">
            <div className="font-semibold">USt-IdNr.:</div>
            DE208140174
          </div>
        </div>
        <div className="flex flex-col ">
          <div className="inline-flex gap-1">
            <div className="font-semibold">{t("pages.legalNoticeContainer.text3")}:</div>
            Ronny Streit
          </div>
          <div className="inline-flex gap-1">
            <div className="font-semibold">{t("pages.legalNoticeContainer.text4")}:</div>
            Amtsgericht Chemnitz, HRA 8905
          </div>
        </div>
        <div>
          {t("pages.legalNoticeContainer.text5")}{" "}
          <ExternalLink to="https://ec.europa.eu/odr">https://ec.europa.eu/odr</ExternalLink>
        </div>
        <div>
          <div className=" font-semibold">{t("pages.legalNoticeContainer.text6")}</div>
          <div>{t("pages.legalNoticeContainer.text7")}</div>
        </div>
        <div className=" font-semibold">{t("pages.legalNoticeContainer.text8")}</div>
        <div>
          <div className=" font-semibold">{t("pages.legalNoticeContainer.text9")}</div>
          <div>{t("pages.legalNoticeContainer.text10")}</div>
        </div>
        <div>
          <div className=" font-semibold">{t("pages.legalNoticeContainer.text11")}</div>
          <div>{t("pages.legalNoticeContainer.text12")}</div>
        </div>
        <div>
          <div className=" font-semibold">{t("pages.legalNoticeContainer.text13")}</div>
          <div>{t("pages.legalNoticeContainer.text14")}</div>
        </div>
        <div>
          <div className=" font-semibold">{t("pages.conditionContainer.text13")}</div>
          <div className=" flex flex-col gap-0.5">
            <NavLink to="/datenschutz" className="text-darkcyan">
              {t("pages.conditionContainer.text14")}
            </NavLink>
            <NavLink to="/urheberrecht" className="text-darkcyan">
              {t("pages.conditionContainer.text15")}
            </NavLink>
            <NavLink to="/cookies" className="text-darkcyan">
              {t("pages.conditionContainer.text16")}
            </NavLink>
            <NavLink to="/community" className="text-darkcyan">
              {t("pages.comRulesContainer.text1")}
            </NavLink>
            <NavLink to="/nutzungsbedingungen" className="text-darkcyan">
              {t("pages.conditionContainer.text17")}
            </NavLink>
          </div>
        </div>
      </div>
    </>
  );
};

export default LegalNoticeContainer;
