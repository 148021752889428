import React, { ReactElement } from "react";

interface TabProps {
  id: string;
}

interface TabListProps {
  children: ReactElement<TabProps>[] | ReactElement<TabProps>;
}

/**
 * Component that contains the tab content
 *
 * @param activeTab Current active tab
 * @param isPersistent Keeps tab content rendered when hidden
 */
const TabBodies: React.FC<TabListProps> = ({ children, activeTab, isPersistent }: any) => {
  if (isPersistent)
    return (
      <>
        {React.Children.map(children, child => {
          if ((child as ReactElement<TabProps>).props.id !== activeTab) {
            return (
              <div className="hidden" aria-hidden="true">
                {child}
              </div>
            );
          } else return child;
        })}
      </>
    );
  else
    return (
      <>
        {React.Children.toArray(children).filter(child => {
          return (child as ReactElement<TabProps>).props.id === activeTab;
        })}
      </>
    );
};

export default TabBodies;
