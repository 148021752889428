import api, { RequestWithData, RequestWithId } from "@src/api/api";

export type RequestData = {
  link_preview_id?: string;
  link?: string;
  text: string;
  media?: string[];
};

export type CreateRequest = RequestWithId & RequestWithData<RequestData>;

export default async function create(request: CreateRequest): Promise<void> {
  const { data } = await api.post(`/api/v1/chats/${request.id}/messages`, request.data);
  return data;
}
