import React, { ReactNode } from "react";

interface TabProps {
  children?: ReactNode;
  hideMobile?: boolean;
  hideDesktop?: boolean;
  isHidden?: boolean;
  id: string;
  url?: string;
  key?: string;
  state?: { backlink?: string };
}

const TabHeader: React.FC<TabProps> = props => {
  return <>{props.children}</>;
};
export default TabHeader;
