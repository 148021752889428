import { ChatBubbleOvalLeftIcon } from "@heroicons/react/24/outline";
import PostsActionBarItem from "@src/pages/news/posts/PostsActionBarItem";

type Props = {
  label?: string;
  onClick?: () => void;
};

const ChatButton = ({ label = "", onClick }: Props) => {
  return (
    <PostsActionBarItem onClick={onClick} label={label} hideCount={true}>
      <ChatBubbleOvalLeftIcon />
    </PostsActionBarItem>
  );
};

export default ChatButton;
