import api, { RequestWithFile } from "@src/api/api";
import { MediaType } from "@src/utils/types/MediaType";
import { AxiosProgressEvent } from "axios";

export type UploadRequest = RequestWithFile;

export type UploadResponse = {
  data: MediaType;
};

export default async function upload(
  request: UploadRequest,
  onUploadProgress?: (event: AxiosProgressEvent) => void,
): Promise<UploadResponse> {
  const formData = new FormData();
  formData.append("file", request.file);
  const { data } = await api.post("/api/v1/media/upload", formData, {
    headers: {
      "content-type": "multipart/form-data",
    },
    onUploadProgress,
  });
  return data;
}
