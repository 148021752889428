import { Capacitor } from "@capacitor/core";
import { Haptics, ImpactStyle } from "@capacitor/haptics";
import { BookmarkIcon } from "@heroicons/react/24/solid";
import bookmark from "@src/api/posts/bookmark";
import remove from "@src/api/posts/bookmark/remove";
import { useAppSelector } from "@src/state/hooks";
import useLoginModal from "@src/state/modal/useLoginModal";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import tw from "twin.macro";

type Props = {
  postId: string;
  bookmarked: boolean;
};

const WatchBookmarkButton = ({ postId, bookmarked }: Props) => {
  const [actionEffect, setActionEffect] = useState(false);
  const queryClient = useQueryClient();
  const { user } = useAppSelector(state => state.user);
  const loginModal = useLoginModal();

  const bookmarkMutation = useMutation({
    mutationKey: ["bookmark"],
    mutationFn: bookmark,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["posts", "watch"] });
    },
    onError: () => {
      alert("Failed");
    },
  });

  const removeMutation = useMutation({
    mutationKey: ["bookmark"],
    mutationFn: remove,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["posts", "watch"] });
    },
    onError: () => {
      alert("Failed");
    },
  });
  return (
    <button
      onClick={e => {
        if (user) {
          if (Capacitor.isNativePlatform()) Haptics.impact({ style: ImpactStyle.Light });
          bookmarked
            ? removeMutation.mutate({ id: postId })
            : bookmarkMutation.mutate({ id: postId });
        } else {
          loginModal.open();
        }
        e.stopPropagation();
      }}
      css={[
        tw`w-8 h-8`,
        tw`transition-all ease-out duration-200`,
        bookmarked && tw`text-yellow-400`,
      ]}
    >
      {bookmarked ? (
        <BookmarkIcon className={`${actionEffect && "animate-ping-once"}`} />
      ) : (
        <BookmarkIcon
          onClick={() => {
            setActionEffect(true);
          }}
          onAnimationEnd={() => {
            setActionEffect(false);
          }}
        />
      )}
    </button>
  );
};

export default WatchBookmarkButton;
