import api, { RequestWithId } from "@src/api/api";

export type RemoveRequest = RequestWithId & {
  messageId: string;
};

export default async function remove(request: RemoveRequest): Promise<void> {
  const { data } = await api.delete(`/api/v1/chats/${request.id}/messages/${request.messageId}`);
  return data;
}
