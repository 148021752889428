import { ReactNode } from "react";
import { styled } from "styled-components";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/scrollbar";
import { Autoplay, Mousewheel, Navigation, Pagination } from "swiper/modules";
import { Swiper } from "swiper/react";

type JobCarouselProps = {
  children: ReactNode;
  spaceBetween?: number;
  slidesPerView?: number;
  loop?: boolean;
  navigation?: boolean;
  pagination?: boolean;
  autoplay?: boolean;
  mousewheel?: boolean;
};

const ArrowButton = styled.div`
  &.swiper-button-next::after,
  &.swiper-button-prev::after {
    color: gray;
    font-size: 15px;
  }
  @media (max-width: 768px) {
    &.swiper-button-next::after,
    &.swiper-button-prev::after {
      font-size: 15px;
    }
  }
  @media (min-width: 768px) {
    &.swiper-button-next::after,
    &.swiper-button-prev::after {
      font-size: 20px;
    }
  }
`;

const JobCarousel: React.FC<JobCarouselProps> = ({ children }: JobCarouselProps) => {
  return (
    <Swiper
      modules={[Navigation, Pagination, Autoplay, Mousewheel]}
      spaceBetween={1}
      slidesPerView={1}
      navigation={{
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      }}
      loop={true}
      className="w-full"
      mousewheel={{ releaseOnEdges: true }}
      breakpoints={{
        768: {
          slidesPerView: 3,
        },
        1024: {
          slidesPerView: 4,
        },
      }}
      onSlideChange={swiper => {
        if (
          swiper.navigation.nextEl &&
          Array.isArray(swiper.navigation.nextEl) &&
          swiper.navigation.nextEl.classList
        ) {
          swiper.navigation.nextEl[0].classList.add("swiper-button-next");
        }
        if (
          swiper.navigation.prevEl &&
          Array.isArray(swiper.navigation.prevEl) &&
          swiper.navigation.nextEl.classList
        ) {
          swiper.navigation.prevEl[0].classList.add("swiper-button-prev");
        }
      }}
    >
      {children}

      <div className="hidden md:block">
        {/* eslint-disable-next-line tailwindcss/no-custom-classname */}
        <ArrowButton className="swiper-button-next"></ArrowButton>
        {/* eslint-disable-next-line tailwindcss/no-custom-classname */}
        <ArrowButton className="swiper-button-prev"></ArrowButton>
      </div>
    </Swiper>
  );
};

export default JobCarousel;
