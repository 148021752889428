import tw from "twin.macro";

type Props = {
  onClick: () => void;
  active?: boolean;
  blue?: boolean;
};
const ShowMoreButton = ({ onClick, active, blue }: Props) => {
  return (
    <button
      onClick={onClick}
      css={[
        tw`rounded-full bg-gray-200 hover:bg-gray-300 mt-3 w-full py-1 text-xs`,
        blue && tw`bg-blue-700 hover:bg-blue-500 text-white border border-white`,
      ]}
    >
      {active ? "Weniger Anzeigen" : "Mehr Anzeigen"}
    </button>
  );
};

export default ShowMoreButton;
