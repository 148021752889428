import forYou from "@src/api/contacts/forYou";
import TabBodies from "@src/components/elements/shared/tab/TabBodies";
import TabBody from "@src/components/elements/shared/tab/TabBody";
import TabController from "@src/components/elements/shared/tab/TabController";
import TabHeader from "@src/components/elements/shared/tab/TabHeader";
import TabHeaders from "@src/components/elements/shared/tab/TabHeaders";
import { useInfiniteQueryOnScroll } from "@src/hooks/useInfiniteQueryOnScroll";
import ContactItem from "@src/pages/contacts/ContactItem";
import { User } from "@src/utils/user";
import { useTranslation } from "react-i18next";

type Props = {
  searchQuery?: string;
  tab?: string;
};

const ContactForYouContainer = ({ searchQuery, tab }: Props) => {
  const { t } = useTranslation();

  const { result: forYouResult } = useInfiniteQueryOnScroll({
    queryKey: ["contacts", "for-you", searchQuery, tab],
    queryFn: ({ pageParam }) =>
      forYou({
        params: { type: tab === "all" ? undefined : tab, page: pageParam, q: searchQuery },
      }),
    initialPageParam: 0,
    getNextPageParam: lastPage => {
      const nextPage = lastPage.current_page + 1;
      return nextPage <= lastPage.total_pages ? nextPage : null;
    },
  });

  return (
    <>
      <TabController isSubTab defaultTab={tab}>
        <TabHeaders>
          <TabHeader id="all" url="/kontakte/für_dich/alle">
            {t("main.contacts.tabs.all")}
          </TabHeader>
          <TabHeader id="person" url="/kontakte/für_dich/personen">
            {t("main.contacts.tabs.person")}
          </TabHeader>
          <TabHeader id="company" url="/kontakte/für_dich/firmen">
            {t("main.contacts.tabs.company")}
          </TabHeader>
          <TabHeader id="club" url="/kontakte/für_dich/vereine">
            {t("main.contacts.tabs.club")}
          </TabHeader>
        </TabHeaders>

        <TabBodies>
          <TabBody id="all">
            <div className="grid grid-cols-2 gap-4 md:grid-cols-3">
              {forYouResult.data?.pages.map(page =>
                page.data.map((item: User) => (
                  <ContactItem key={item.id} mode="default" user={item} />
                )),
              )}
            </div>
          </TabBody>
          <TabBody id="person">
            <div className="grid grid-cols-2 gap-4 md:grid-cols-3">
              {forYouResult.data?.pages.map(page =>
                page.data.map((item: User) => (
                  <ContactItem key={item.id} mode="default" user={item} />
                )),
              )}
            </div>
          </TabBody>
          <TabBody id="company">
            <div className="grid grid-cols-2 gap-4 md:grid-cols-3">
              {forYouResult.data?.pages.map(page =>
                page.data.map((item: User) => (
                  <ContactItem key={item.id} mode="default" user={item} />
                )),
              )}
            </div>
          </TabBody>
          <TabBody id="club">
            <div className="grid grid-cols-2 gap-4 md:grid-cols-3">
              {forYouResult.data?.pages.map(page =>
                page.data.map((item: User) => (
                  <ContactItem key={item.id} mode="default" user={item} />
                )),
              )}
            </div>
          </TabBody>
        </TabBodies>
      </TabController>
    </>
  );
};

export default ContactForYouContainer;
