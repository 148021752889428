import Panel from "@src/components/box/Panel";
import UserWidget from "@src/components/elements/UserWidget";
import getUserInformation from "@src/utils/getUserInformation";
import { User } from "@src/utils/user";
import { useTranslation } from "react-i18next";

type Props = { user: User; minimized?: boolean };

const PrivateContainer = ({ user, minimized = true }: Props) => {
  const { t } = useTranslation();

  return (
    <div className="flex justify-between">
      <Panel>
        <div className="flex flex-col text-sm">
          <div className="mb-2 text-lg">
            <UserWidget user={user} showName={true} follow={false} verified={true} />
          </div>
          <div
            dangerouslySetInnerHTML={getUserInformation(user, t, {
              country: true,
              zipCode: true,
              email: true,
              phone_number: true,
            })}
          />

          {!minimized && (
            <div className="flex flex-col gap-1">
              <div className="flex w-full flex-col justify-between text-xs">
                {/* <div className="flex flex-row gap-x-1">
                <div className="font-semibold shrink-0">PLZ, Ort:</div>
                <div className="truncate"></div>
              </div>
              <div className="flex flex-row gap-x-1">
                <div className="font-semibold">Geburtag:</div>
                <div></div>
              </div> */}
              </div>
            </div>
          )}
        </div>
      </Panel>
    </div>
  );
};

export default PrivateContainer;
