import likes from "@src/api/users/likes";
import { useInfiniteQueryOnScroll } from "@src/hooks/useInfiniteQueryOnScroll";
import ContactItem from "@src/pages/contacts/ContactItem";
import useDebounce from "@src/utils/useDebounce";
import { User } from "@src/utils/user";

type Props = {
  userId: string;
  searchQuery: string;
};

const UserLikes = (props: Props) => {
  const { userId } = props;
  const debouncedSearchQuery = useDebounce(props.searchQuery, 500);

  const { result: likesResult } = useInfiniteQueryOnScroll({
    queryKey: ["likes", userId, debouncedSearchQuery],
    queryFn: async ({ pageParam }) =>
      likes({ id: userId, params: { page: pageParam, q: debouncedSearchQuery } }),
    initialPageParam: 0,
    getNextPageParam: lastPage => {
      const nextPage = lastPage.current_page + 1;
      return nextPage <= lastPage.total_pages ? nextPage : null;
    },
  });

  return (
    <>
      {likesResult.data?.pages.map(page =>
        page.data.map((item: User) => <ContactItem key={item.id} mode="default" user={item} />),
      )}
    </>
  );
};

export default UserLikes;
