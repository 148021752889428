import UserWidget from "@src/components/elements/UserWidget";
import VerifyBadge from "@src/components/elements/VerifyBadge";
import { User } from "@src/utils/user";
import { useRef } from "react";
import tw from "twin.macro";

type Props = {
  onChange: (checked: boolean) => void;
  checked?: boolean;
  user: User;
};

const ShareUser = ({ onChange, user, checked = false }: Props) => {
  const checkboxRef = useRef<HTMLInputElement>(null);

  return (
    <>
      <button
        className="grid w-full grid-cols-[1fr,auto] items-center gap-4"
        onClick={() => {
          onChange(!checkboxRef.current?.checked);
        }}
      >
        <div className="pointer-events-none inline-flex items-center gap-1">
          <div className="grid grid-cols-[1fr,auto] items-center gap-1">
            <div className="overflow-x-hidden">
              <UserWidget
                user={user}
                follow={false}
                online={false}
                size={45}
                sideName={true}
                showName={true}
                nameStyle={tw`font-normal truncate`}
              />
            </div>
            <VerifyBadge type={user.type} />
          </div>
        </div>
        <input
          ref={checkboxRef}
          type="checkbox"
          checked={checked}
          className="pointer-events-none size-4 rounded-full border-gray-300 text-blue-500 focus:ring-offset-0"
        />
      </button>
    </>
  );
};

export default ShareUser;
