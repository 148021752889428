import posts from "@src/api/hashtags/posts";
import PostWidget from "@src/components/elements/PostWidget";
import { useInfiniteQueryOnScroll } from "@src/hooks/useInfiniteQueryOnScroll";
import { useAppSelector } from "@src/state/hooks";
import { Post } from "@src/utils/post";
import { useState } from "react";

type Props = {
  hashtag?: string;
  searchQuery?: string;
  type?: string;
};

const PostsContainer = ({ hashtag, searchQuery, type }: Props) => {
  const { user } = useAppSelector(state => state.user);
  const [lightboxSelection, setLightboxSelection] = useState("");

  const { result: postsResult } = useInfiniteQueryOnScroll({
    queryKey: ["posts", hashtag],
    queryFn: async ({ pageParam }) =>
      posts({
        params: {
          page: pageParam,
          user_id: user?.id,
          q: searchQuery,
          watch: type === "watch",
        },
        hashtag: `${hashtag}`,
      }),
    initialPageParam: 0,
    getNextPageParam: lastPage => {
      const nextPage = lastPage.current_page + 1;
      return nextPage <= lastPage.total_pages ? nextPage : null;
    },
  });

  return (
    <>
      {postsResult.data?.pages.map((page, pageIndex) =>
        page?.data?.map(
          (post: Post, index: number) =>
            (type === "watch" ? post.media?.[0]?.type === "video" : !post.watch) && (
              <PostWidget
                postId={post.id}
                post={post}
                key={post.id}
                text={post.text}
                author={post.author}
                repost={post.reposted}
                created_at={post.created_at}
                onSwitch={(id: string) => {
                  setLightboxSelection(id);
                }}
                lightboxSelection={lightboxSelection}
                prev={
                  index == 0 && pageIndex > 0
                    ? postsResult.data.pages[pageIndex - 1].data[
                        postsResult.data.pages[pageIndex - 1].data.length - 1
                      ].id
                    : index > 0
                      ? postsResult.data.pages[pageIndex].data[index - 1].id
                      : ""
                }
                next={
                  pageIndex + 1 < postsResult.data.pages.length &&
                  index + 1 == postsResult.data.pages[pageIndex].data.length
                    ? postsResult.data.pages[pageIndex + 1].data[0].id
                    : index + 1 < postsResult.data.pages[pageIndex].data.length
                      ? postsResult.data.pages[pageIndex].data[index + 1].id
                      : ""
                }
              />
            ),
        ),
      )}
    </>
  );
};

export default PostsContainer;
