import { ClockIcon } from "@heroicons/react/24/outline";

type Props = {
  onClick: () => void;
};

const ScheduleButton = ({ onClick }: Props) => (
  <button onClick={onClick}>
    <ClockIcon className="size-6" />
  </button>
);

export default ScheduleButton;
