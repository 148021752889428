import api, { RequestWithData, RequestWithId } from "@src/api/api";

export type UpdateData = {
  name: string;
  members: string[];
  avatar?: string;
};

export type UpdateRequest = RequestWithId & RequestWithData<UpdateData>;

export default async function update(request: UpdateRequest): Promise<void> {
  const { data } = await api.patch(`/api/v1/chats/${request.id}/groups`);
  return data;
}
