import { Capacitor } from "@capacitor/core";
import { StatusBar, Style } from "@capacitor/status-bar";
import { XMarkIcon } from "@heroicons/react/24/outline";
import usePopupModal from "@src/state/modal/usePopupModal";
import { User } from "@src/utils/user";
import { QRCodeSVG } from "qrcode.react";
import { useEffect } from "react";
import tw from "twin.macro";

type Props = {
  user?: User;
  id?: string;
  width?: number;
  height?: number;
};

type FullScreenProps = {
  onClose: () => void;
  user?: User;
  id?: string;
};

const FullScreen = (props: FullScreenProps) => {
  useEffect(() => {
    if (Capacitor.isNativePlatform()) {
      StatusBar.setStyle({ style: Style.Dark });
    }
    return () => {
      if (Capacitor.isNativePlatform()) {
        StatusBar.setStyle({ style: Style.Light });
      }
    };
  }, []);

  return (
    <div className="fixed left-0 top-0 z-[500] size-full" onClick={props.onClose}>
      <div css={[tw`absolute top-0 left-0 w-full h-full bg-black`, tw`bg-opacity-70`]}></div>
      <button
        onClick={props.onClose}
        className="absolute right-5 top-5 mt-[env(safe-area-inset-top)]"
      >
        <XMarkIcon className="size-8 text-white" />
      </button>
      <div className="absolute left-0 top-[calc(50%+env(safe-area-inset-top))] w-full -translate-y-1/2">
        <div className="overflow-scrolling[touch] relative mt-3 flex snap-x snap-mandatory overflow-x-auto overflow-y-hidden scroll-smooth scrollbar-none">
          <div className="relative cursor-pointer">
            <div className="relative w-screen">
              <div
                css={[
                  tw`mx-auto max-h-[calc(calc(100dvh-120px)-env(safe-area-inset-top))] h-screen max-w-[90vw] object-contain`,
                  tw`max-w-[300px] max-h-[300px]`,
                ]}
              >
                <QRCodeSVG
                  width="100%"
                  height="100%"
                  value={"http://tradefoox.com/profile/" + (props.user?.id ?? props.id)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const UserQRCode = ({ user, id, width = 65, height = 65 }: Props) => {
  const popupModal = usePopupModal();

  return (
    <QRCodeSVG
      width={width}
      height={height}
      value={"http://tradefoox.com/profile/" + (user?.id ?? id)}
      onClick={() => popupModal.open(<FullScreen user={user} id={id} onClose={popupModal.close} />)}
    />
  );
};

export default UserQRCode;
