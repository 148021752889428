import api, { requestParams, RequestWithId, RequestWithParams } from "@src/api/api";
import { User } from "@src/utils/user";

export type LikesParams = {
  page?: number;
  q?: string;
};

export type LikesRequest = RequestWithId & RequestWithParams<LikesParams>;

export type LikesResponse = {
  data: User[];
  current_page: number;
  total_pages: number;
};

export default async function likes(request: LikesRequest): Promise<LikesResponse> {
  const { data } = await api.get(
    `/api/v1/users/${request.id}/likes${requestParams({ page: 0, ...request.params })}`,
  );
  return data;
}
