import {
  HIDE_REPORT_MODAL,
  HideReportModalAction,
  SHOW_REPORT_MODAL,
  ShowReportModalAction,
} from "@src/state/reportModal/types";

export const showReportModal = (
  itemId: string,
  type: string,
  title?: string,
): ShowReportModalAction => ({
  type: SHOW_REPORT_MODAL,
  payload: { itemId, type, title },
});

export const hideReportModal = (): HideReportModalAction => ({
  type: HIDE_REPORT_MODAL,
});
