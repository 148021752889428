import { closePopup, openPopup } from "@src/state/modal/popupSlice";
import { AppDispatch, RootState } from "@src/state/store";
import { ReactNode } from "react";
import { useDispatch, useSelector } from "react-redux";

const usePopupModal = () => {
  const dispatch = useDispatch<AppDispatch>();
  const stack = useSelector((state: RootState) => state.popups.stack);

  const open = (popup: ReactNode) => {
    dispatch(openPopup(popup));
  };

  const close = () => {
    if (stack.length > 1) {
      dispatch(closePopup(stack.splice(-1)));
    } else {
      dispatch(closePopup([]));
    }
  };

  return { stack, open, close };
};

export default usePopupModal;
