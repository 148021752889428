import { PropsWithChildren } from "react";
import tw, { TwStyle } from "twin.macro";

type Props = {
  height?: TwStyle;
  showBackground?: boolean;
};

const ScrollListH = ({ children, height, showBackground = true }: PropsWithChildren<Props>) => {
  return (
    <div
      className="group"
      css={[
        tw`relative w-full`,
        showBackground && tw`lg:border lg:border-gray-300 bg-white rounded-2xl p-3`,
        height ?? tw`h-[240px]`,
      ]}
    >
      <div className="flex w-full flex-row justify-between gap-3 overflow-x-hidden pb-2 scrollbar-thin group-hover:overflow-x-auto">
        {children}
      </div>
    </div>
  );
};

export default ScrollListH;
