import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "@src/state/store";

interface AvatarState {
  avatar: File | null;
}

const initialState: AvatarState = {
  avatar: null,
};

export const avatarSlice = createSlice({
  name: "avatar",
  initialState,
  reducers: {
    setAvatar: (state, { payload }: PayloadAction<File>) => {
      state.avatar = payload;
    },
  },
});

export const selectNotifications = (state: RootState) => state.notifications.value;

export const { setAvatar } = avatarSlice.actions;

export default avatarSlice.reducer;
