import api, { requestParams, RequestWithParams } from "@src/api/api";
import { Post } from "@src/utils/post";

export type FeedParams = {
  page?: number | null;
  q?: string | null;
  featured_post_id?: string | null;
  user_id?: string | null;
  liked_by?: string | null;
};

export type FeedRequest = RequestWithParams<FeedParams>;

export type FeedResponse = {
  data: Post[];
  current_page: number;
  total_pages: number;
};

export default async function feed(request: FeedRequest): Promise<FeedResponse> {
  const { data } = await api.get(`/api/v1/feed${requestParams({ page: 0, ...request.params })}`);
  return data;
}
