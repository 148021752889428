import React, { ReactElement } from "react";
import { NavLink } from "react-router-dom";
import tw, { TwStyle } from "twin.macro";

interface TabProps {
  id?: string;
  url?: string;
  state?: { backlink?: string };
  isHidden?: boolean;
  hideDesktop?: boolean;
  hideMobile?: boolean;
  children?: React.ReactNode;
}

interface TabHeadersProps {
  children: ReactElement<TabProps>[] | ReactElement<TabProps>;
  isSubTab?: boolean;
  center?: boolean;
  headerCSS?: TwStyle[] | TwStyle;
  tabCSS?: TwStyle[] | TwStyle;
  hideOnTabs?: string[];
  activeTab?: string;
  setActiveTab?: (id: string) => void;
}

/**
 * Component that contains tab buttons
 *
 * @param activeTab Current active tab
 * @param setActiveTab Setter for tabs
 * @param defaultTab Selected tab by default
 * @param isSubTab Tab menu is using sub tab styles
 * @param center Centers the tab buttons
 * @param headerCSS TW Styles for the tabs container
 * @param tabCSS TW Styles for each individual tab
 * @param hideOnTabs Hide tab bar when a specific tab is selected
 */
const TabHeaders: React.FC<TabHeadersProps> = ({
  children,
  activeTab = "",
  setActiveTab,
  isSubTab,
  center,
  headerCSS,
  tabCSS,
  hideOnTabs,
}: TabHeadersProps) => {
  return (
    <div
      css={[
        tw`flex gap-4 overflow-x-scroll scrollbar-none`,
        isSubTab ? tw`justify-start text-xs` : tw`justify-between text-sm`,
        center && tw`mx-auto`,
        headerCSS,
        hideOnTabs && hideOnTabs.includes(activeTab) && tw`max-xl:hidden`,
      ]}
    >
      {React.Children.map(children, child => {
        if (child.props.isHidden) return <></>;
        return (
          <NavLink
            to={child.props.url ?? ""}
            state={child.props.state}
            css={[
              tw`h-fit relative whitespace-nowrap flex gap-1 items-center border-b-2 border-darkblue`,
              child.props.id === activeTab
                ? tw`text-gray-900`
                : tw`text-gray-500 border-transparent`,
              child.props.hideDesktop && tw`xl:hidden`,
              child.props.hideMobile && tw`max-xl:hidden`,
              tabCSS,
            ]}
            onClick={e => {
              setActiveTab && setActiveTab(child.props.id ?? "");
              e.currentTarget.scrollIntoView({
                behavior: "smooth",
                block: "nearest",
                inline: "center",
              });
            }}
          >
            {child.props.children}
          </NavLink>
        );
      })}
    </div>
  );
};

export default TabHeaders;
