import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import isMobile from "@src/utils/isMobile";
import { User } from "@src/utils/user";

interface ModalState {
  user: User | undefined;
  position: { x: number; y: number };
  offset?: { x: number; y: number };
  isOpen?: boolean;
  isFocused?: boolean;
}

const initialState: ModalState = {
  user: undefined,
  position: { x: 0, y: 0 },
  offset: { x: 0, y: 0 },
  isOpen: false,
  isFocused: false,
};

export const userBubbleSlice = createSlice({
  name: "userBubble",
  initialState,
  reducers: {
    openModal: (state, action: PayloadAction<Omit<ModalState, "isOpen">>) => {
      if (!isMobile) {
        state.user = action.payload.user;
        state.position = action.payload.position;
        state.offset = action.payload?.offset;
        state.isOpen = true;
      }
    },
    closeModal: (state, action: PayloadAction<{ force: boolean }>) => {
      if (!state.isFocused || action.payload.force) {
        state.isOpen = false;
        state.isFocused = false;
      }
    },
    focusModal: state => {
      state.isFocused = true;
    },
  },
});

export const { openModal, closeModal, focusModal } = userBubbleSlice.actions;

export default userBubbleSlice.reducer;
