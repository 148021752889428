import api, { requestParams, RequestWithId, RequestWithParams } from "@src/api/api";
import { User } from "@src/utils/user";

export type FollowingParams = {
  page?: number;
  q?: string;
};

export type FollowingRequest = RequestWithId & RequestWithParams<FollowingParams>;

type FollowingResponse = {
  data: User[];
  current_page: number;
  total_pages: number;
};

export default async function following(request: FollowingRequest): Promise<FollowingResponse> {
  const { data } = await api.get(
    `/api/v1/users/${request.id}/following${requestParams({ page: 0, ...request.params })}`,
  );
  return data;
}
