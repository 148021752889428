import api, { RequestWithId } from "@src/api/api";
import { User } from "@src/utils/user";

export type UserRequest = RequestWithId;

export type UserResponse = {
  data: User;
};

export default async function user(request: UserRequest): Promise<UserResponse> {
  const { data } = await api.get(`/api/v1/users/${request.id}`);
  return data;
}
