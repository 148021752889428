import { useAppSelector } from "@src/state/hooks";
import useLoginModal from "@src/state/modal/useLoginModal";
import { NavLink, useLocation } from "react-router-dom";
import tw, { TwStyle } from "twin.macro";

type Props = {
  style?: TwStyle;
  icon: JSX.Element;
  title: string;
  count?: number;
  href: string;
  key?: string;
  dark?: boolean;
  activeLinks?: string[];
  authRequired?: boolean;
};

function HeaderButton({
  style,
  icon,
  title,
  count = 0,
  href,
  dark,
  activeLinks = [],
  authRequired = false,
}: Props) {
  const { user } = useAppSelector(state => state.user);
  const location = useLocation().pathname;
  const loginModal = useLoginModal();

  const checkAuth = () => {
    if (!user && authRequired) {
      loginModal.open();
      return false;
    }
    return true;
  };

  return (
    <div css={[style]}>
      <NavLink to={href} onClick={e => checkAuth() || e.preventDefault()}>
        {({ isActive }) => {
          if (activeLinks.length > 0) {
            isActive = activeLinks.some(item => {
              return location.startsWith(item);
            });
          }
          return (
            <div
              className="group"
              css={[
                tw`relative flex flex-col cursor-pointer items-center py-0`,
                isActive && tw`border-b-2 border-darkblue`,
              ]}
            >
              <div
                css={[
                  tw`w-7 h-7 `,
                  dark
                    ? tw`text-white opacity-100`
                    : tw`text-darkblue opacity-60 group-hover:opacity-100`,
                  isActive && tw`opacity-100`,
                ]}
              >
                {icon}
              </div>
              {count > 0 && (
                <div
                  css={[
                    tw`absolute -translate-y-2 translate-x-3 w-5 h-5 bg-red-500 text-white rounded-full flex items-center justify-center`,
                    count > 99 ? tw`text-micro font-semibold` : tw`text-xs`,
                  ]}
                >
                  {count > 99 ? "99+" : count}
                </div>
              )}
              <span
                css={[
                  tw`max-xl:hidden text-xs text-center font-semibold pb-0.5`,
                  dark
                    ? tw`text-white opacity-100`
                    : tw`text-darkblue opacity-60 group-hover:opacity-100`,
                  isActive && tw`opacity-100`,
                ]}
              >
                {title}
              </span>
            </div>
          );
        }}
      </NavLink>
    </div>
  );
}

export default HeaderButton;
