import api, { requestParams, RequestWithParams } from "@src/api/api";

export type AvailableParams = {
  username: string;
};

export type AvailableRequest = RequestWithParams<AvailableParams>;

export type AvailableResponse = {
  available: boolean;
};

export default async function available(request: AvailableRequest): Promise<AvailableResponse> {
  const { data } = await api.get(
    `/api/v1/users/username/available${requestParams(request.params)}`,
  );
  return data;
}
