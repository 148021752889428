import linkPreview from "@src/api/linkPreview/linkPreview";
import LinkPreview from "@src/components/elements/LinkPreview";
import { useQuery } from "@tanstack/react-query";
import { useEffect } from "react";

type Props = {
  link: string;
  onChange: (linkPreviewId?: string) => void;
  onDelete?: () => void;
  small?: boolean;
};

const LegacyLinkPreview = ({ link, small, onChange, onDelete }: Props) => {
  const linkPreviewResult = useQuery({
    queryKey: [`link-preview-${link}`],
    queryFn: () => linkPreview({ params: { url: link } }),
  });

  useEffect(() => {
    if (linkPreviewResult.isSuccess) {
      onChange(linkPreviewResult.data.data.id);
    }
  }, [linkPreviewResult, onChange]);

  if (!linkPreviewResult.isSuccess)
    return (
      <div className="h-[374px] w-full animate-pulse rounded-2xl border border-gray-400 bg-gray-300"></div>
    );

  return <LinkPreview link={linkPreviewResult.data.data} small={small} onDelete={onDelete} />;
};

export default LegacyLinkPreview;
