import api, { requestParams, RequestWithParams } from "@src/api/api";
import { WatchType } from "@src/utils/types/WatchType";

export type WatchParams = {
  page?: number;
  q?: string;
  featured_media_id?: string | null;
  user_id?: string | null;
  liked_by?: string | null;
  bookmarked?: boolean;
  following?: boolean;
};

export type WatchRequest = RequestWithParams<WatchParams>;

export type WatchResponse = {
  data: WatchType[];
  current_page: number;
  total_pages: number;
};

export default async function watch(request: WatchRequest): Promise<WatchResponse> {
  const { data } = await api.get(`/api/v1/watch${requestParams({ page: 0, ...request.params })}`);
  return data;
}
