import { PropsWithChildren } from "react";
import tw from "twin.macro";

type Props = {
  subTab?: boolean;
  scrollable?: boolean;
};

const TabMenu = ({ subTab, children, scrollable = true }: PropsWithChildren<Props>) => (
  <div
    css={[
      subTab ? tw`flex justify-start gap-4 text-xs` : tw`flex justify-between gap-4 text-sm`,
      tw`mb-3 pb-0.5`,
    ]}
    className={`${scrollable && "overflow-x-scroll scrollbar-none"}`}
  >
    {children}
  </div>
);

export default TabMenu;
