import upload from "@src/api/media/upload";
import Panel from "@src/components/box/Panel";
import Button from "@src/components/elements/input/Button";
import BackButton from "@src/components/nav/BackButton";
import AvatarEditElement from "@src/pages/messenger/AvatarEditElement";
import { useAppSelector } from "@src/state/hooks";
import getName from "@src/utils/getName";
import { MediaType } from "@src/utils/types/MediaType";
import { useMutation } from "@tanstack/react-query";
import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

const PrintContainer = () => {
  const { user } = useAppSelector(state => state.user);
  const [_data, setData] = useState<any>({});
  const imageRef = useRef<HTMLInputElement>(null);
  const [image, setImage] = useState<MediaType | undefined>(undefined);
  const navigate = useNavigate();

  const uploadMutation = useMutation({
    mutationKey: ["upload"],
    mutationFn: upload,
    onSuccess: ({ data }) => setImage(data),
    onError: () => {
      alert("Failed");
    },
  });

  const changeProp = (value: any) => {
    setData((currentData: any) => {
      return { ...currentData, ...value };
    });
  };

  const city = "Stadt";
  const street = "Straße";

  if (!user) return <></>;
  if (user.type !== "person") navigate("/news/für_dich");

  return (
    <>
      <BackButton />
      <Panel mobileBorder={false}>
        <div className="flex flex-col">
          <ul className="flex list-outside list-disc flex-wrap justify-center gap-x-3.5 text-mini">
            <li
              className="truncate"
              contentEditable
              suppressContentEditableWarning
              onInput={e => changeProp({ fullname: e.currentTarget.textContent })}
            >
              {getName(user)}
            </li>
            <li
              contentEditable
              suppressContentEditableWarning
              onInput={e => changeProp({ street: e.currentTarget.textContent })}
            >
              {street}
            </li>
            <li
              contentEditable
              suppressContentEditableWarning
              onInput={e => changeProp({ city: e.currentTarget.textContent })}
            >
              {city}
            </li>
          </ul>
          <ul className="flex list-outside list-disc flex-wrap justify-center gap-x-3.5 text-mini">
            <li
              className="truncate"
              contentEditable
              suppressContentEditableWarning
              onInput={e => changeProp({ phone_number: e.currentTarget.textContent })}
            >
              Telefon: {user.phone_number}
            </li>
            <li
              contentEditable
              suppressContentEditableWarning
              onInput={e => changeProp({ email: e.currentTarget.textContent })}
            >
              E-Mail: {user.email}
            </li>
          </ul>
        </div>

        <div className="mt-6 bg-gray-200 py-3 md:px-12">
          <div
            className="break-all font-semibold"
            contentEditable
            suppressContentEditableWarning
            onInput={e => changeProp({ fullname: e.currentTarget.textContent })}
          >
            {getName(user)}
          </div>
          <div className="grid grid-cols-[1fr,130px] gap-4 pt-6">
            <div className="grid grid-cols-[auto,1fr] gap-x-2 text-sm child:break-all">
              <div>Adresse:</div>
              <div
                contentEditable
                suppressContentEditableWarning
                onInput={e => changeProp({ city: e.currentTarget.textContent })}
              >
                {street + ", " + city}
              </div>
              <div>Telefon:</div>
              <div
                contentEditable
                suppressContentEditableWarning
                onInput={e => changeProp({ phone_number: e.currentTarget.textContent })}
              >
                {user.phone_number}
              </div>
              <div>E-Mail:</div>
              <div
                contentEditable
                suppressContentEditableWarning
                onInput={e => changeProp({ email: e.currentTarget.textContent })}
              >
                {user.email}
              </div>
              <div>Geburtsdatum:</div>
              <div
                contentEditable
                suppressContentEditableWarning
                onInput={e =>
                  changeProp({
                    person: { birthday: e.currentTarget.textContent },
                  })
                }
              >
                {new Date(user?.person?.birthday ?? "").toLocaleDateString()}
              </div>
              <div>Familienstand:</div>
              <div contentEditable suppressContentEditableWarning className="truncate"></div>
              <div>Sttatsangehörigkeit:</div>
              <div
                contentEditable
                suppressContentEditableWarning
                onInput={e =>
                  changeProp({
                    country: e.currentTarget.textContent,
                  })
                }
              ></div>
            </div>
            <div>
              <input
                type="file"
                className="hidden"
                ref={imageRef}
                multiple={false}
                onChange={e => {
                  if (e.target.files) {
                    uploadMutation.mutate({ file: e.target.files[0] });
                  }
                }}
                accept="image/png, image/gif, image/jpeg"
              />
              <AvatarEditElement
                image={
                  image ?? {
                    data_url: user.avatar ?? "",
                    file_name: "",
                    id: "",
                    key: "",
                    thumbnail: "",
                    type: "",
                  }
                }
                loading={uploadMutation.isPending}
                onClick={() => imageRef.current?.click()}
                placeholder="/images/placeholder/person.png"
                urlExtension={image ? undefined : ""}
              />
            </div>
          </div>
        </div>

        <div className="mt-4 grid grid-flow-row gap-4 md:px-12">
          <div>
            <div className="mb-1 border-b border-gray-900 pb-1 font-semibold">
              Beruflicher Werdegang
            </div>
            <div className="grid grid-cols-[175px,1fr] gap-2 text-sm child:truncate">
              <div
                contentEditable
                suppressContentEditableWarning
                className="flex flex-wrap gap-1 truncate"
              >
                <div contentEditable suppressContentEditableWarning>
                  12.31.23
                </div>{" "}
                -
                <div contentEditable suppressContentEditableWarning>
                  12.31.23
                </div>
              </div>
              <div>
                <div contentEditable suppressContentEditableWarning>
                  testdata
                </div>
                <div contentEditable suppressContentEditableWarning>
                  testdata
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="mb-1 border-b border-gray-900 pb-1 font-semibold">Ausbildung</div>
          </div>
        </div>
        <div className="flex flex-row gap-2 max-md:justify-end">
          <Button
            onClick={() => {
              console.log("saving");
            }}
            variant={Button.Variants.Transparent}
          >
            Vorlage speichern
          </Button>
          <Button onClick={() => console.log("download")} variant={Button.Variants.Transparent}>
            Download
          </Button>
        </div>
      </Panel>
    </>
  );
};

export default PrintContainer;
