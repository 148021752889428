import changeEmail from "@src/api/auth/changeEmail";
import AuthWrapper from "@src/pages/auth/AuthWrapper";
import { useQuery } from "@tanstack/react-query";
import { useSearchParams } from "react-router-dom";

const EmailConfirmContainer = () => {
  const [searchParams] = useSearchParams();
  const code = searchParams.get("code") ?? "";

  const activateResult = useQuery({
    queryKey: ["changeEmail", code],
    queryFn: () => changeEmail({ params: { code } }),
    retry: false,
  });

  return (
    <AuthWrapper>
      {activateResult.isSuccess && "Success"}
      {activateResult.isError && "Error"}
      {activateResult.isLoading && "Loading..."}
    </AuthWrapper>
  );
};

export default EmailConfirmContainer;
