import { UserCircleIcon } from "@heroicons/react/24/outline";
import UserWidget from "@src/components/elements/UserWidget";
import { useAppSelector } from "@src/state/hooks";
import { useTranslation } from "react-i18next";
import { NavLink, useLocation } from "react-router-dom";
import tw from "twin.macro";

type Props = {
  dark?: boolean;
};

const NavUserElement = ({ dark }: Props) => {
  const { user } = useAppSelector(state => state.user);
  const { t } = useTranslation();
  const location = useLocation();

  if (user) {
    return (
      <NavLink
        to={`/profile/${user ? user.id : ""}`}
        state={{ backlink: location.state?.backlink ?? location.pathname }}
      >
        {({ isActive }) => (
          <div
            css={[
              tw`relative flex flex-col cursor-pointer items-center py-0`,
              isActive && tw`border-b-2 border-darkblue`,
            ]}
          >
            <div css={[tw`w-7 h-7 text-white`]}>
              <UserWidget size={28} user={user} showInfoBubble={false} />
            </div>
            <span
              css={[
                tw`hidden md:block text-xs text-center font-semibold pb-0.5`,
                dark
                  ? tw`text-white opacity-90`
                  : tw`text-darkblue opacity-60 group-hover:opacity-100`,
                isActive && tw`opacity-100`,
              ]}
            >
              {t("main.header.links.profileView")}
            </span>
          </div>
        )}
      </NavLink>
    );
  }

  return (
    <NavLink
      className="group"
      to="/login"
      css={[tw`relative flex flex-col cursor-pointer items-center py-0`]}
    >
      <div
        css={[
          tw`w-7 h-7 group-hover:opacity-100 opacity-60`,
          dark ? tw`text-white opacity-90` : tw`text-darkblue opacity-60 group-hover:opacity-100`,
        ]}
      >
        <UserCircleIcon className="heroicon-stroke-w-1.2" />
      </div>
      <span
        css={[
          tw`hidden md:block text-xs text-center font-semibold`,
          dark ? tw`text-white opacity-90` : tw`text-gray-600 opacity-60 group-hover:opacity-100`,
        ]}
      >
        Login
      </span>
    </NavLink>
  );
};

export default NavUserElement;
