import {
  ArrowPathRoundedSquareIcon,
  ChatBubbleOvalLeftIcon,
  EyeIcon,
  HeartIcon,
} from "@heroicons/react/24/outline";
import { HeartIcon as HeartIconSolid } from "@heroicons/react/24/solid";
import SharePopup from "@src/components/popup/SharePopup";
import PostsActionBarItem from "@src/pages/news/posts/PostsActionBarItem";
import PostBookmarkButton from "@src/pages/news/posts/actions/PostBookmarkButton";
import { useAppSelector } from "@src/state/hooks";
import useLoginModal from "@src/state/modal/useLoginModal";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import tw from "twin.macro";

type Props = {
  toggleComments: () => void;
  toggleLike: () => void;
  commentCount: number;
  likeCount: number;
  viewCount: number;
  bookmarkCount: number;
  repostCount: number;
  bookmarked: boolean;
  isReposted: boolean;
  postId: string;
  isLiked: boolean;
  dark?: boolean;
  fullscreen?: boolean;
};

const PostsActionBar = (props: Props) => {
  const [sharePopupOpen, setSharePopupOpen] = React.useState(false);
  const loginModal = useLoginModal();
  const { user } = useAppSelector(state => state.user);
  const [likeEffect, setLikeEffect] = useState(false);
  const { t } = useTranslation();

  const checkAuth = () => {
    if (!user) {
      loginModal.open();
      return false;
    }
    return true;
  };

  return (
    <div
      css={[
        tw`flex flex-row justify-between mt-2 text-darkblue relative h-6`,
        props.fullscreen && tw`max-md:w-screen px-3`,
      ]}
    >
      <PostsActionBarItem
        onClick={props.toggleComments}
        label={t("components.posts.answer")}
        count={props.commentCount}
        textColor={{
          // eslint-disable-next-line no-constant-condition
          ...(false
            ? props.dark
              ? tw`text-blue-200`
              : tw`text-blue-700`
            : props.dark
              ? tw`text-gray-200 group-hover:text-blue-200`
              : tw`text-gray-600 group-hover:text-blue-700`),
        }}
        color={{
          // eslint-disable-next-line no-constant-condition
          ...(false
            ? props.dark
              ? tw`bg-blue-900`
              : tw`bg-blue-100`
            : props.dark
              ? tw`group-hover:bg-blue-900`
              : tw`group-hover:bg-blue-100`),
        }}
      >
        <ChatBubbleOvalLeftIcon />
      </PostsActionBarItem>
      <PostsActionBarItem
        label={t("pages.post.sharePost")}
        count={props.repostCount}
        onClick={() => checkAuth() && setSharePopupOpen(true)}
        textColor={{
          ...(props.isReposted
            ? props.dark
              ? tw`text-green-200`
              : tw`text-green-700`
            : props.dark
              ? tw`text-gray-200 group-hover:text-green-200`
              : tw`text-gray-600 group-hover:text-green-700`),
        }}
        color={{
          ...(props.isReposted
            ? props.dark
              ? tw`bg-green-900`
              : tw`bg-green-100`
            : props.dark
              ? tw`group-hover:bg-green-900`
              : tw`group-hover:bg-green-100`),
        }}
      >
        <ArrowPathRoundedSquareIcon />
      </PostsActionBarItem>
      <PostsActionBarItem
        label={t("buttons.like")}
        count={props.likeCount}
        onClick={() => checkAuth() && props.toggleLike()}
        textColor={{
          ...(props.isLiked
            ? props.dark
              ? tw`text-pink-200`
              : tw`text-red-600`
            : props.dark
              ? tw`text-gray-200 group-hover:text-pink-200`
              : tw`text-gray-600 group-hover:text-red-600`),
        }}
        color={{
          ...(props.isLiked
            ? props.dark
              ? tw`bg-pink-900`
              : tw`bg-pink-100`
            : props.dark
              ? tw`group-hover:bg-pink-900`
              : tw`group-hover:bg-pink-100`),
        }}
      >
        {props.isLiked ? (
          <HeartIconSolid className={`${likeEffect && "mb-1 animate-ping-once"}`} />
        ) : (
          <HeartIcon
            onClick={() => {
              setLikeEffect(true);
            }}
            onAnimationEnd={() => {
              setLikeEffect(false);
            }}
          />
        )}
      </PostsActionBarItem>
      <PostsActionBarItem
        label="Views"
        count={props.viewCount}
        textColor={{
          ...(props.dark
            ? tw`text-gray-200 group-hover:text-blue-200`
            : tw`text-gray-600 group-hover:text-blue-700`),
        }}
        color={{
          ...(props.dark ? tw`group-hover:bg-blue-900` : tw`group-hover:bg-blue-100`),
        }}
      >
        <EyeIcon />
      </PostsActionBarItem>
      <PostBookmarkButton
        postId={props.postId}
        bookmarked={props.bookmarked}
        bookmarkCount={props.bookmarkCount}
        textColor={{
          ...(props.dark
            ? tw`text-gray-200 group-hover:text-slate-200`
            : tw`text-gray-600 group-hover:text-slate-700`),
        }}
        color={{
          ...(props.dark ? tw`group-hover:bg-slate-900` : tw`group-hover:bg-slate-200`),
        }}
      />
      {sharePopupOpen && (
        <SharePopup
          postId={props.postId}
          content={{
            title: "News",
            body: "https://www.tradefoox.com/s/p/" + props.postId,
          }}
          onClose={() => setSharePopupOpen(false)}
        />
      )}
    </div>
  );
};

export default PostsActionBar;
