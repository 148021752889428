import { Browser } from "@capacitor/browser";
import isApp from "@src/utils/isApp";
import { PropsWithChildren } from "react";

type Props = {
  to: string;
};
const ExternalLink = (props: PropsWithChildren<Props>) => {
  if (isApp) {
    return (
      <a
        className="cursor-pointer text-lightblue"
        onClick={() => {
          Browser.open({ url: props.to });
        }}
      >
        {props.children}
      </a>
    );
  } else {
    return (
      <a href={props.to} target="_blank" rel="noopener noreferrer" className="text-lightblue">
        {props.children}
      </a>
    );
  }
};

export default ExternalLink;
