import { AppContext } from "@src/App";
import UserDetailsWidget from "@src/components/elements/UserDetailsWidget";
import SideBarCart from "@src/components/elements/sidebar/widgets/SideBarCart";
import SideBarMenu from "@src/components/elements/sidebar/widgets/SideBarMenu";
import CreateFormButton from "@src/components/nav/CreateFormButton";
import NavBar from "@src/components/nav/NavBar";
import NavBarBottom from "@src/components/nav/NavBarBottom";
import usePopupModal from "@src/state/modal/usePopupModal";
import useUserBubbleModal from "@src/state/modal/useUserBubbleModal";
import isMobile from "@src/utils/isMobile";
import { useContext, useState } from "react";
import { Outlet } from "react-router-dom";
import tw from "twin.macro";

type Props = {
  darkHeader?: boolean;
  darkFooter?: boolean;
  showCart?: boolean;
  sidebarButtonVisible?: boolean;
  smallSideBar?: boolean;
  showBG?: boolean;
};

const FullscreenMainWrapper = ({ ...props }: Props) => {
  const appContext = useContext(AppContext);

  const popupModal = usePopupModal();
  const userBubbleModal = useUserBubbleModal();

  const [sidebarVisible, setSidebarVisible] = useState(false);
  const sideBarOnClick = props.sidebarButtonVisible
    ? () => {
        setSidebarVisible(!sidebarVisible);
      }
    : undefined;

  return (
    <div
      css={[
        tw`grid grid-rows-[50px,calc(calc(100dvh-90px)-calc(env(safe-area-inset-top)+env(safe-area-inset-bottom))),40px] xl:grid-rows-[50px,calc(calc(100dvh-50px)-calc(env(safe-area-inset-top)+env(safe-area-inset-bottom)))] mt-[env(safe-area-inset-top)] mb-[env(safe-area-inset-bottom)]`,
        (appContext?.fullscreen || appContext?.topNavHidden) &&
          tw`grid grid-rows-[calc(100dvh-calc(env(safe-area-inset-top)+env(safe-area-inset-bottom)))] md:grid-rows-[50px,calc(calc(100dvh-50px)-calc(env(safe-area-inset-top)+env(safe-area-inset-bottom)))]`,
        appContext?.darkTheme && tw`max-md:bg-neutral-900 max-md:text-white`,
        !appContext?.darkTheme && tw`bg-white text-black`,
      ]}
    >
      <div css={[(appContext?.fullscreen || appContext?.topNavHidden) && tw`max-md:hidden`]}>
        <NavBar dark={props.darkHeader} sideBarOnClick={sideBarOnClick} />
      </div>

      <div className="relative" css={[props.showBG && tw`bg-[#E3E6E6]`]}>
        <main
          css={[
            tw`flex justify-center w-full`,
            tw`h-full max-h-[calc(calc(100dvh-90px)-calc(env(safe-area-inset-top)+env(safe-area-inset-bottom)))] xl:max-h-[calc(calc(100dvh-50px)-calc(env(safe-area-inset-top)+env(safe-area-inset-bottom)))] scrollbar-thin overflow-y-scroll`,
          ]}
        >
          {!isMobile && (
            <aside
              css={[
                tw`fixed left-0 text-black h-full delay-1000 max-xl:hidden`,
                props.smallSideBar
                  ? tw`bg-transparent w-[220px]`
                  : tw`bg-white w-[365px] border-gray-500 border-r`,
              ]}
            >
              <SideBarMenu small={props.smallSideBar} />
              <div className="px-2">
                <CreateFormButton />
              </div>
            </aside>
          )}
          <div
            css={[
              tw`grid grid-flow-row auto-rows-max grid-cols-1 gap-3 md:py-4 max-md:p-4 w-full overflow-x-clip px-4 lg:pr-[calc(128px+32px)] xl:pl-[calc(365px+32px)] xl:pl-[calc(220px+32px)]`,
              (appContext?.borderless || appContext?.fullscreen) && tw`p-0`,
            ]}
          >
            <Outlet />
          </div>
        </main>
        {props.showCart && (
          <aside className="fixed right-0 top-0 h-screen w-[128px] border-l border-gray-300 bg-white text-black max-lg:hidden">
            <SideBarCart />
          </aside>
        )}
      </div>
      <div css={[(appContext?.fullscreen || appContext?.bottomNavHidden) && tw`max-md:hidden`]}>
        <NavBarBottom dark={props.darkFooter} />
      </div>
      {popupModal.stack.length > 0 && popupModal.stack[0]}
      {userBubbleModal.userBubble.isOpen && userBubbleModal.userBubble.user != undefined && (
        <div
          className="pointer-events-none fixed z-[100] mt-[45px] w-[300px] pt-[5px]"
          style={{
            left:
              userBubbleModal.userBubble.position.x + (userBubbleModal.userBubble?.offset?.x ?? 0),
            top:
              userBubbleModal.userBubble.position.y + (userBubbleModal.userBubble?.offset?.y ?? 0),
          }}
          onMouseLeave={() => {
            userBubbleModal.close();
          }}
        >
          <div
            className="pointer-events-auto h-fit rounded-full shadow-[0_8px_30px_rgb(0,0,0,0.12)]"
            onClick={e => {
              e.stopPropagation();
            }}
            onMouseEnter={() => {
              userBubbleModal.setFocused();
            }}
            onMouseLeave={() => {
              userBubbleModal.close(true);
            }}
          >
            <UserDetailsWidget user={userBubbleModal.userBubble.user} />
          </div>
        </div>
      )}
    </div>
  );
};

export default FullscreenMainWrapper;
