import api from "@src/api/api";
import { User } from "@src/utils/user";

export type SuggestionsResponse = {
  data: User[];
};

export default async function suggestions(): Promise<SuggestionsResponse> {
  const { data } = await api.get("/api/v1/users/suggestions");
  return { data };
}
