import logout from "@src/api/auth/logout";
import { signOut } from "@src/state/user/userSlice";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const LogoutContainer = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const logoutMutation = useMutation({
    mutationKey: ["logout"],
    mutationFn: logout,
    onSuccess: () => {
      dispatch(signOut());
      queryClient.invalidateQueries({ queryKey: ["me"] });
      setTimeout(() => navigate("/news/für_dich"), 200);
    },
    onError: () => {
      alert("Failed");
    },
  });

  useEffect(() => {
    if (logoutMutation.isIdle) {
      logoutMutation.mutate();
    }
  }, [logoutMutation]);

  return <div>Logout ...</div>;
};

export default LogoutContainer;
