import BackButton from "@src/components/nav/BackButton";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

type OrgType = {
  link: string;
  text: string;
  thumbnail: string;
};

const Donations = () => {
  const { t } = useTranslation();
  const orgs: Array<OrgType> = [
    {
      link: "https://www.ekk-chemnitz.de/",
      text: t("pages.donation.text1"),
      thumbnail: "/images/info/orgs/ekk-chemnitz.png",
    },
    {
      link: "https://www.hospiz-chemnitz.de/spenden-finanzierung/spendenmanagement.php",
      text: t("pages.donation.text2"),
      thumbnail: "/images/info/orgs/hospiz-chemnitz.png",
    },
    {
      link: "https://www.freundeskreis-kinderheim.de/",
      text: t("pages.donation.text3"),
      thumbnail: "/images/info/orgs/kinderheim-chemnitz.png",
    },
    {
      link: "https://www.stadtmission-chemnitz.de/helfen-spenden",
      text: t("pages.donation.text4"),
      thumbnail: "/images/info/orgs/stadtmission-chemnitz.jpg",
    },
    {
      link: "https://www.chemnitzer-tafel.de/",
      text: t("pages.donation.text5"),
      thumbnail: "/images/info/orgs/logo-chemnitzer-tafel.png",
    },
    {
      link: "https://tierschutz-chemnitz.de/",
      text: t("pages.donation.text6"),
      thumbnail: "/images/info/orgs/tierschutzverein-chemnitz-logo.jpg",
    },
  ];

  return (
    <>
      <div className="pl-4 pt-4">
        <BackButton stepback desktopVisible />
      </div>
      <div className="grid grid-flow-row gap-6 pb-4 text-gray-700">
        <section className="relative">
          <img src="/images/info/spendenaktion_2_tradefoox.jpg" className="w-full object-fill" />
        </section>

        <article className="grid grid-flow-row gap-3 pb-4 text-xs max-xl:px-4">
          <h1 className="text-left text-smbase font-bold">{t("pages.donation.text7")}</h1>
          <div>
            <h2 className="font-semibold">{t("pages.donation.text8")}</h2>
            <div className="text-justify">{t("pages.donation.text9")}</div>
          </div>
          {orgs.map((org: OrgType) => {
            return (
              <article key={org.link} className="flex flex-col gap-2 text-xs">
                <img src={org.thumbnail} className="mx-auto h-[100px]" />
                <div className="text-justify max-xl:px-4">
                  {org.text}{" "}
                  <NavLink to={org.link} target="_blank">
                    <div className="text-darkcyan">{t("pages.donation.text10")} &gt;&gt;</div>
                  </NavLink>
                </div>
              </article>
            );
          })}
        </article>
      </div>
    </>
  );
};

export default Donations;
