import StarsRating from "@src/components/elements/input/StarsRating";

interface Product {
  title: string;
  imgUrl: string;
  price: string;
  MRP: string;
  ID: string;
  category: string;
  subCategory: string;
  description?: string;
}

interface ProductProps {
  product: Product;
}

const SimilarProduct: React.FC<ProductProps> = ({ product }: ProductProps) => {
  return (
    <div className="mb-5 overflow-hidden rounded-lg border border-gray-300 bg-white p-4 shadow-sm dark:border-gray-700 dark:bg-gray-800">
      <a href="#" className="overflow-hidden rounded-lg">
        <img className="mx-auto max-h-32 rounded-lg" src={product.imgUrl} alt={product.title} />
      </a>
      <div className="h-[75px] overflow-hidden leading-none">
        <a href="#" className="text-xs font-semibold text-gray-900 hover:underline dark:text-white">
          {product.title}
        </a>
      </div>
      <StarsRating rating={1} editable />
      <div className="flex flex-row">
        <p className="mr-1.5 text-lg font-bold leading-tight text-red-600 dark:text-red-500">
          {product.price}
        </p>
        <p className="text-xs font-bold text-gray-900 dark:text-white">
          <span className="line-through">{product.MRP}</span>
        </p>
      </div>
    </div>
  );
};

export default SimilarProduct;
