import api, { RequestWithId } from "@src/api/api";

export type AddRequest = RequestWithId;

export type AddResponse = {
  message: string;
};

export default async function add(request: AddRequest): Promise<AddResponse> {
  const { data } = await api.post(`/api/v1/carts/${request.id}`);
  return data;
}
