import { NavLink } from "react-router-dom";

type Props = {
  text: string;
  action: string;
  link?: string;
};
const SuggestionsHeader = ({ text, action, link = "" }: Props) => {
  return (
    <div className="mb-4 mt-2 grid grid-cols-2">
      <div>
        <div className="w-min border-b text-sm font-semibold">{text}</div>
      </div>
      <div className="grid justify-items-end">
        <NavLink
          to={link}
          className="h-full rounded-full bg-gray-300 px-2 text-sm hover:bg-gray-400"
        >
          {action}
        </NavLink>
      </div>
    </div>
  );
};

export default SuggestionsHeader;
