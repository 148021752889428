import Button from "@src/components/elements/input/Button";
import StarsRating from "@src/components/elements/input/StarsRating";
import ProductReviewButton from "@src/pages/marketplace/form/ProductReviewButton";
import React from "react";
import { useTranslation } from "react-i18next";
interface ProductRatingHistogramProps {
  product_title: string;
}

const ProductRatingHistogram: React.FC<ProductRatingHistogramProps> = ({ product_title }) => {
  const { t } = useTranslation();
  return (
    <div className="w-auto">
      <div className="mb-2 text-lg font-semibold text-darkred">{t("main.shop.details.review")}</div>
      <div className="inline-flex items-center gap-2 text-base">
        <StarsRating rating={5} />
        {t("main.shop.details.countOutOf", { count: 5 })} 5
      </div>
      <div className="mb-4 text-gray-600">{t("main.shop.details.reviews", { count: 840 })}</div>
      <div className="flex flex-col gap-3">
        {Array(5)
          .fill(0)
          .reverse()
          .map((_, i) => (
            <div className="grid grid-cols-[65px,100px,55px]" key={i}>
              <div className="mr-3 w-max">{5 - i} Sterne</div>
              <div className="inline-flex bg-gray-300">
                <div className="bg-yellow-500" />
              </div>
              <div className="text-right">100 %</div>
            </div>
          ))}
      </div>
      <div className="mt-4 flex flex-col gap-2">
        <div className="text-sm font-normal">{t("main.navLinks.jobsPublished.rateJob")}</div>
        <div className="-mt-2 ml-2 w-full md:w-[200px]">
          <Button variant={Button.Variants.Transparent} size={Button.Sizes.Small}>
            <ProductReviewButton product_title={product_title} />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ProductRatingHistogram;
