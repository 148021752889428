import api, { RequestWithData } from "@src/api/api";
import { TemporaryAuthTokenType } from "@src/utils/types/TemporaryAuthTokenType";

export type TemporaryAuthTokenData = {
  token: string;
};

export type TemporaryAuthTokenRequest = RequestWithData<TemporaryAuthTokenData>;

export type TemporaryAuthTokenResponse = {
  data: TemporaryAuthTokenType;
};

export default async function temporaryAuthToken(
  request: TemporaryAuthTokenRequest,
): Promise<TemporaryAuthTokenResponse> {
  const { data } = await api.post("/api/v1/auth/social/validate_access", request.data);
  return {
    data: {
      user: data.user,
      token: data.jwt,
      refreshToken: data.refresh_token,
    },
  };
}
