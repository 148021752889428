import UserDataForm from "@src/components/elements/form/UserDataForm";
import { User } from "@src/utils/user";

type Props = {
  user: User;
};

const PrivateProfileContainer = ({ user }: Props) => {
  return (
    <div
      className="flex flex-col items-start gap-4 xl:flex-row"
      style={{ minWidth: "max-content" }}
    >
      <div className="flex flex-col gap-7 max-xl:w-[calc(100vw-35px)]">
        <UserDataForm user={user} formType="basic" />
        <UserDataForm user={user} formType="additional" />
      </div>
      <div className="w-full max-xl:w-[calc(100vw-35px)] xl:w-auto">
        <UserDataForm user={user} formType="offers" />
      </div>
    </div>
  );
};

export default PrivateProfileContainer;
