import { EyeIcon, PlayIcon } from "@heroicons/react/24/outline";
import ContactContextMenu from "@src/pages/contacts/context/ContactContextMenu";
import { NavLink } from "react-router-dom";

type Props = {
  image?: string;
  type?: string;
  tag: string;
  viewCount: number;
  likeCount: number;
  key?: string;
  tab?: string;
  index: number;
  authorName?: string;
  authorAvatar?: string;
};

const Trendsitem = ({ ...props }: Props) => (
  <div className="flex flex-row justify-between">
    <div>
      <NavLink to={`/tags/${props.tag}/${props.type === "video" ? "watch" : "feed"}`}>
        <div className="text-sm font-semibold text-cyan-700">
          {props.index + 1}. #{props.tag}
        </div>
      </NavLink>
      <div className="ml-4 flex flex-row items-center gap-1.5">
        {props.tab === "feed" && <EyeIcon className="size-3" />}
        {props.tab === "watch" && <PlayIcon className="size-3" />}
        <div className="text-mini text-gray-500">{props.viewCount}</div>
      </div>
    </div>
    <div className="pt-1 font-bold text-cyan-700">
      <ContactContextMenu userId={"sas"} type={"sds"} />
    </div>
  </div>
);

export default Trendsitem;
