import {
  AtSymbolIcon,
  CalendarDaysIcon,
  HashtagIcon,
  PhotoIcon,
} from "@heroicons/react/24/outline";
import { PaperAirplaneIcon } from "@heroicons/react/24/solid";
import create from "@src/api/posts/create";
import Alert from "@src/components/elements/Alert";
import LegacyLinkPreview from "@src/components/elements/LegacyLinkPreview";
import Button from "@src/components/elements/input/Button";
import PostMedia from "@src/components/elements/posts/form/shared/PostMedia";
import PostTextEdit from "@src/components/elements/posts/form/shared/PostTextEdit";
import PostUserSelect from "@src/components/elements/posts/form/shared/PostUserSelect";
import Schedule from "@src/components/elements/shared/Schedule";
import ScheduleButton from "@src/components/elements/shared/buttons/ScheduleButton";
import usePopupModal from "@src/state/modal/usePopupModal";
import { formatDate } from "@src/utils/formatDate";
import regex from "@src/utils/regex";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import EmojiPicker from "emoji-picker-react";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import tw from "twin.macro";

type Props = {
  onSuccess: () => void;
  messageText?: string;
  mediaList?: Array<{
    id: string;
    key: string;
    file_name: string;
    type: string;
    thumbnail: string;
  }>;
};

const SharePost = ({ onSuccess, messageText = "", mediaList = [] }: Props) => {
  const { t } = useTranslation();
  const [link, setLink] = useState<string | undefined>();
  const [openSchedule, setOpenSchedule] = useState(false);
  const [adult, setAdult] = useState(false);
  const [text, setText] = useState(messageText);
  const [scheduleDate, setScheduleDate] = useState<number | undefined>();
  const [emojiOpen, _setEmojiOpen] = useState(false);
  const [linkPreviewId, setLinkPreviewId] = useState<string>();
  const [media, setMedia] = useState<
    Array<{
      id: string;
      key: string;
      file_name: string;
      type: string;
      thumbnail: string;
    }>
  >(mediaList);
  const queryClient = useQueryClient();
  const popupModal = usePopupModal();
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [atClick, setAtClick] = useState<() => void>();
  const [hashtagClick, setHashtagClick] = useState<() => void>();
  const [addMediaClick, setAddMediaClick] = useState<() => void>();

  const links = regex.url.exec(text);
  if (links && links[0] && !link) {
    setLink(links[0]);
    setText(value => value.replace(links[0], ""));
  }

  const createMutation = useMutation({
    mutationKey: ["post"],
    mutationFn: create,
    onSuccess: () => {
      setText("");
      setAdult(false);
      queryClient.invalidateQueries({ queryKey: ["posts"] });
      onSuccess();
    },
    onError: () => {
      popupModal.open(
        <Alert
          buttons={Alert.ButtonVariants.OK}
          onClose={popupModal.close}
          message="Dein Beitrag konnte nicht erstellt werden."
          title="Feed"
        />,
      );
    },
  });

  return (
    <div>
      <div css={[tw`grid grid-flow-row gap-2 text-left `, openSchedule && tw`hidden`]}>
        <div className="text-center font-semibold">{t("buttons.shareInFeed")}</div>
        {scheduleDate && (
          <button
            className="flex flex-row items-center gap-2 text-xs hover:underline"
            onClick={() => {
              setOpenSchedule(true);
            }}
          >
            <CalendarDaysIcon className="size-4" />
            {t("main.watch.create.publishAt")} {formatDate(new Date(scheduleDate).toISOString())}.
          </button>
        )}
        <div className="flex flex-row items-center gap-5 font-semibold">
          <PostUserSelect />
        </div>
        <PostMedia
          onChange={data => setMedia(value => [...value, data])}
          media={media}
          onDelete={id => setMedia(data => data.filter(item => item.id !== id))}
          tooltip=""
          addClick={{
            getFunction: addMediaClick,
            setFunction: setAddMediaClick,
          }}
        />
        {link && (
          <LegacyLinkPreview
            link={link}
            onChange={setLinkPreviewId}
            onDelete={() => {
              setLink(undefined);
              setLinkPreviewId(undefined);
            }}
          />
        )}
        <PostTextEdit
          value={text}
          onChange={setText}
          textRef={textareaRef}
          atClick={{ getFunction: atClick, setFunction: setAtClick }}
          hashtagClick={{
            getFunction: hashtagClick,
            setFunction: setHashtagClick,
          }}
        />
        <div className="flex w-[calc(100%-30px)] flex-row items-baseline justify-between max-lg:fixed max-lg:bottom-4">
          <ScheduleButton onClick={() => setOpenSchedule(value => !value)} />
          <button onClick={atClick}>
            <AtSymbolIcon className="size-6 text-darkblue" />
          </button>
          <button onClick={hashtagClick}>
            <HashtagIcon className="size-6 text-darkblue" />
          </button>
          <button onClick={addMediaClick}>
            <PhotoIcon className="size-6 text-darkblue" />
          </button>
          <div className="max-sm:hidden"></div>

          <div className="w-fit">
            <Button
              onClick={() =>
                createMutation.mutate({
                  data: {
                    text,
                    adult,
                    media: media.map(m => m.id),
                    link,
                    set_active_at: scheduleDate,
                    link_preview_id: linkPreviewId,
                  },
                })
              }
            >
              <PaperAirplaneIcon className="size-5" />
            </Button>
          </div>
        </div>
        {emojiOpen && (
          <EmojiPicker
            onEmojiClick={emoji =>
              setText(data => data + String.fromCodePoint(parseInt(emoji.unified, 16)))
            }
          />
        )}
      </div>
      {openSchedule && (
        <Schedule
          onClose={() => setOpenSchedule(false)}
          onChange={date => setScheduleDate(date)}
          selectedDate={scheduleDate}
        />
      )}
    </div>
  );
};

export default SharePost;
