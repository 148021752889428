import { ChevronRightIcon } from "@heroicons/react/24/outline";
import React from "react";
import { useNavigate } from "react-router-dom";

type Props = {
  link?: string;
  value?: string;
  label?: string;
  onClick?: () => void;
  subLabel?: string;
  icon?: React.ReactNode;
  showArrow?: boolean;
};

const InfoButton = ({ label, subLabel, value, link, onClick, icon, showArrow = true }: Props) => {
  const navigate = useNavigate();

  return (
    <button
      onClick={() => {
        onClick && onClick();
        if (link) navigate(link);
      }}
      className="border border-transparent border-x-gray-300 px-2 py-1 leading-4 first:rounded-t-xl first:border-gray-300 first:border-b-transparent last:rounded-b-xl last:border-gray-300 last:border-t-transparent only:border-gray-300"
    >
      <div className="grid grid-cols-[auto,1fr,auto] gap-3">
        {icon}
        <div className="text-left">
          {label}
          <div className="text-mini leading-3 text-gray-500">{subLabel}</div>
        </div>
        <div className="my-auto flex flex-row items-center gap-1 text-gray-500">
          {value}
          {showArrow && <ChevronRightIcon className="size-4" />}
        </div>
      </div>
    </button>
  );
};

export default InfoButton;
