import { Menu } from "@headlessui/react";
import {
  ArrowDownTrayIcon,
  BookmarkIcon,
  EllipsisHorizontalIcon,
  FlagIcon,
  PencilIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import { BookmarkIcon as BookmarkIconSolid } from "@heroicons/react/24/solid";
import bookmark from "@src/api/posts/bookmark";
import removeBookmark from "@src/api/posts/bookmark/remove";
import removePost from "@src/api/posts/remove";
import EditWatchPost from "@src/components/elements/posts/form/EditWatchPost";
import BlockUserDropdownButton from "@src/components/elements/shared/buttons/BlockUserDropdownButton";
import { useAppSelector } from "@src/state/hooks";
import useLoginModal from "@src/state/modal/useLoginModal";
import { showReportModal } from "@src/state/reportModal/actions";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { saveAs } from "file-saver";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import tw from "twin.macro";

type Props = {
  postId: string;
  authorId: string;
  videoUrl: string;
  isRepost?: boolean;
  bookmarked: boolean;
};
const WatchDropdown = ({ postId, authorId, videoUrl, bookmarked, isRepost }: Props) => {
  const [openEdit, setOpenEdit] = useState(false);
  const { user } = useAppSelector(state => state.user);
  const queryClient = useQueryClient();
  const loginModal = useLoginModal();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const removePostMutation = useMutation({
    mutationKey: ["delete-post"],
    mutationFn: removePost,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["posts"] });
    },
    onError: () => {
      alert("Failed");
    },
  });

  const bookmarkMutation = useMutation({
    mutationKey: ["bookmark"],
    mutationFn: bookmark,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["posts"] });
    },
    onError: () => {
      alert("Failed");
    },
  });

  const removeBookmarkMutation = useMutation({
    mutationKey: ["bookmark"],
    mutationFn: removeBookmark,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["posts"] });
    },
    onError: () => {
      alert("Failed");
    },
  });

  if (!user) return null;

  const downloadVideo = async () => {
    try {
      const response = await axios.get(videoUrl + "/play_480p.mp4", {
        responseType: "blob",
      });
      const blob = new Blob([response.data], { type: response.data.type });
      saveAs(blob, "default.mp4");
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  return (
    <>
      {openEdit && (
        <EditWatchPost
          onSuccess={() => setOpenEdit(false)}
          postId={postId}
          onClose={() => setOpenEdit(false)}
        />
      )}
      <Menu as="div" className="inline-block text-left">
        <div>
          <Menu.Button className="flex items-center rounded-full text-white">
            <span className="sr-only">{t("advertisement.addWidged.openOptions")}</span>
            <EllipsisHorizontalIcon className="size-8" aria-hidden="true" />
          </Menu.Button>
        </div>

        <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right -translate-y-[calc(100%+40px)] rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            {user?.id === authorId && !isRepost && (
              <Menu.Item>
                {({ active }) => (
                  <button
                    type="submit"
                    css={[
                      active ? tw`bg-gray-100 text-gray-900` : tw`text-gray-700`,
                      tw`block w-full px-4 py-2 text-left text-sm inline-flex items-center justify-between`,
                    ]}
                    onClick={() => setOpenEdit(true)}
                  >
                    {t("main.profileViewTabs.aboutMe.content.sidebarButtons.edit")}
                    <PencilIcon className="size-4" />
                  </button>
                )}
              </Menu.Item>
            )}
            <Menu.Item>
              {({ active }) => (
                <button
                  css={[
                    active ? tw`bg-gray-100 text-gray-900` : tw`text-gray-700`,
                    tw`block w-full px-4 py-2 text-left text-sm inline-flex items-center justify-between`,
                  ]}
                  onClick={() => downloadVideo()}
                >
                  {t("pages.post.download")}
                  <ArrowDownTrayIcon className="size-4" />
                </button>
              )}
            </Menu.Item>
            {user.id === authorId && (
              <Menu.Item>
                {({ active }) => (
                  <button
                    type="submit"
                    css={[
                      active ? tw`bg-gray-100 text-gray-900` : tw`text-gray-700`,
                      tw`block w-full px-4 py-2 text-left text-sm inline-flex items-center justify-between`,
                    ]}
                    onClick={() => removePostMutation.mutate({ id: postId })}
                  >
                    {t("components.shared.delete")}
                    <TrashIcon className="size-4" />
                  </button>
                )}
              </Menu.Item>
            )}
            <Menu.Item>
              {({ active }) => (
                <button
                  type="submit"
                  css={[
                    active ? tw`bg-gray-100 text-gray-900` : tw`text-gray-700`,
                    tw`block w-full px-4 py-2 text-left text-sm inline-flex items-center justify-between`,
                  ]}
                  onClick={() => dispatch(showReportModal(postId, "post"))}
                >
                  {t("buttons.report")}
                  <FlagIcon className="size-4" />
                </button>
              )}
            </Menu.Item>
            <BlockUserDropdownButton userId={authorId} blocked={false} keys={["posts"]} />
            <Menu.Item>
              {({ active }) => (
                <button
                  onClick={() => {
                    if (user) {
                      bookmarked
                        ? removeBookmarkMutation.mutate({ id: postId })
                        : bookmarkMutation.mutate({ id: postId });
                    } else {
                      loginModal.open();
                    }
                  }}
                  css={[
                    active ? tw`bg-gray-100 text-gray-900` : tw`text-gray-700`,
                    tw`block w-full px-4 py-2 text-left text-sm inline-flex items-center justify-between`,
                  ]}
                >
                  {t("main.groupsInfoForm.buttons.save")}
                  {bookmarked ? (
                    <BookmarkIconSolid className="size-4" />
                  ) : (
                    <BookmarkIcon className="size-4" />
                  )}
                </button>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Menu>
    </>
  );
};

export default WatchDropdown;
