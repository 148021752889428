import { closeModal, focusModal, openModal } from "@src/state/modal/userBubbleSlice";
import { AppDispatch, RootState } from "@src/state/store";
import { User } from "@src/utils/user";
import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

const useUserBubbleModal = () => {
  const dispatch = useDispatch<AppDispatch>();
  const userBubble = useSelector((state: RootState) => state.userBubble);
  const location = useLocation();
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    dispatch(closeModal({ force: true }));
  }, [dispatch, location.pathname]);

  useEffect(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }
  }, [userBubble.position]);

  const open = (modal: {
    position: { x: number; y: number };
    offset?: { x: number; y: number };
    user: User;
  }) => {
    dispatch(openModal(modal));
  };

  const close = (force?: boolean) => {
    if (force) {
      dispatch(closeModal({ force: force ?? false }));
    } else {
      timeoutRef.current = setTimeout(() => {
        dispatch(closeModal({ force: force ?? false }));
        timeoutRef.current = null;
      }, 100);
    }
  };

  const setFocused = () => {
    dispatch(focusModal());
  };

  return { userBubble, open, close, setFocused };
};

export default useUserBubbleModal;
