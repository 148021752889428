import DemoProducts from "@src/mocks/MarketplaceProduct.json";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

const ArrowButton = styled.div`
  &.swiper-button-next::after,
  &.swiper-button-prev::after {
    color: gray;
    font-size: 15px;
  }

  @media (max-width: 768px) {
    &.swiper-button-next::after,
    &.swiper-button-prev::after {
      font-size: 15px;
    }
  }

  @media (min-width: 768px) {
    &.swiper-button-next::after,
    &.swiper-button-prev::after {
      font-size: 20px;
    }
  }
`;

const SmallTile = () => {
  const demoProducts = DemoProducts.demoProducts;
  const { t } = useTranslation();

  return (
    <>
      <Swiper
        modules={[Navigation, Pagination, Autoplay]}
        spaceBetween={16}
        slidesPerView={2}
        navigation={{
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        }}
        loop={true}
        className="mySwiper"
        breakpoints={{
          768: {
            slidesPerView: 3,
          },
          1024: {
            slidesPerView: 7,
          },
        }}
        onInit={swiper => {
          swiper.navigation.nextEl.classList.add("swiper-button-next");
          swiper.navigation.prevEl.classList.add("swiper-button-prev");
        }}
      >
        {demoProducts.map(product => (
          // eslint-disable-next-line react/jsx-key
          <SwiperSlide>
            <div className="rounded-lg border border-gray-300 bg-white px-1 py-3">
              <a href="#">
                <div>
                  <img
                    className="size-full max-h-24 object-contain p-2"
                    src={product.image}
                    alt={product.title}
                  />
                </div>
                <div className="m-1 flex flex-col">
                  <span className="bg-red-300 text-center text-xs">
                    {t("main.marketplace.tabs.discount", { count: 30 })}
                  </span>
                  <span className="truncate p-1 text-sm">{product.title}</span>
                </div>
              </a>
            </div>
          </SwiperSlide>
        ))}
        <div className="hidden md:block">
          <ArrowButton className="swiper-button-next"></ArrowButton>
          <ArrowButton className="swiper-button-prev"></ArrowButton>
        </div>
      </Swiper>
    </>
  );
};

export default SmallTile;
