import { HeartIcon } from "@heroicons/react/24/outline";
import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

interface Job {
  company: string;
  state: string;
  created_at: string;
  title: string;
  id: string;
  user: {
    avatar?: string;
  };
}

interface JobProps {
  job: Job;
}

const JobCard: React.FC<JobProps> = ({ job }) => {
  const { t } = useTranslation();

  const calculateDaysAgo = (date: string) => {
    const now = new Date();
    const past = new Date(date);

    if (isNaN(past.getTime())) {
      return t("main.jobsTabs.myJobs.invalidDate");
    }

    const diffTime = Math.abs(now.getTime() - past.getTime());
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    return `${diffDays}`;
  };

  return (
    <NavLink to={`/jobs/${job.id}`}>
      <div className="rounded-lg border border-gray-300 bg-white p-2 shadow duration-300 ease-in-out md:hover:scale-95 dark:border-gray-700 dark:bg-gray-800">
        <div className="mb-3 flex items-center justify-between">
          <div className="mr-1 h-[50px] flex-1 overflow-hidden">
            <div className="text-xs font-medium leading-tight text-gray-900">{job.title}</div>
          </div>
          <div className="size-[50px] shrink-0">
            <img
              className="size-full rounded-lg border border-gray-300 object-cover"
              src={job.user.avatar}
              alt="avatar"
            />
          </div>
        </div>

        <div className="mb-2 flex flex-col">
          <div className="m-0.5 flex flex-row">
            <img
              src={"/images/icons/building-office-2.svg"}
              className="mr-1 flex size-[12px]"
              alt="icon"
            />
            <span className="truncate text-mini text-gray-700 dark:text-gray-400">
              {job.company}
            </span>
          </div>
          <div className="m-0.5 flex flex-row">
            <img src={"/images/icons/map-pin.svg"} className="mr-1 flex size-[12px]" alt="icon" />
            <span className="text-mini text-gray-700 dark:text-gray-400">{job.state}</span>
          </div>
          <div className="m-0.5 flex flex-row">
            <img src={"/images/icons/home.svg"} className="mr-1 flex size-[12px]" alt="icon" />
            <span className="text-mini text-gray-700 dark:text-gray-400">
              {t("main.jobsTabs.homeOffice")}
            </span>
          </div>
        </div>

        <div className="flex flex-row items-center">
          <span className="text-xs text-gray-500">
            {t("main.jobsTabs.ago")} {calculateDaysAgo(job.created_at)} {t("main.jobsTabs.days")}
          </span>
          <HeartIcon className="ml-auto mt-1 h-5 text-gray-500" />
        </div>
      </div>
    </NavLink>
  );
};

export default JobCard;
