import DemoProducts from "@src/mocks/MarketplaceProduct.json";
import { useTranslation } from "react-i18next";

const BigTileMitFourImg = () => {
  const { t } = useTranslation();
  const demoProducts = DemoProducts.demoProducts;
  return (
    <div className="w-full rounded-lg border border-gray-300 bg-white px-4 py-3">
      <span className="truncate text-base font-semibold">
        {t("main.marketplace.tabs.buyAndSave")}
      </span>
      <div className="mt-2 grid grid-cols-2 gap-2">
        {demoProducts.slice(-4).map((product, index) => (
          <a key={index} className="flex flex-col items-center">
            <div className="w-full">
              <img className="size-full max-h-28 object-cover p-2" src={product.image} />
            </div>
            <span className="text-xs">{product.title}</span>
          </a>
        ))}
      </div>
      <a href="">
        <span className="text-xs">{t("main.marketplace.tabs.discoverMore")}</span>
      </a>
    </div>
  );
};

export default BigTileMitFourImg;
