import products from "@src/api/marketplace/products/products";
import ShowMoreButton from "@src/components/elements/input/ShowMoreButton";
import MarketplaceShopAllItem from "@src/pages/marketplace/shop/MarketplaceShopAllItem";
import { useInfiniteQuery } from "@tanstack/react-query";

type Props = {
  searchQuery?: string;
  showPlaceholder?: boolean;
};

const Ads = ({ searchQuery, showPlaceholder }: Props) => {
  const productsResult = useInfiniteQuery({
    queryKey: ["marketplace-shop_all", searchQuery],
    queryFn: ({ pageParam }) => products({ params: { page: pageParam, q: searchQuery } }),
    initialPageParam: 0,
    getNextPageParam: lastPage => {
      const nextPage = lastPage.current_page + 1;
      return nextPage <= lastPage.total_pages ? nextPage : null;
    },
  });

  return (
    <>
      {showPlaceholder && <div className="h-[35px]" />}
      <div className="grid grid-cols-2 gap-1 md:grid-cols-3 xl:grid-cols-5 xl:gap-3">
        {productsResult.data?.pages.map(
          page =>
            page.data &&
            page.data
              .slice(0, 5)
              .map(item => <MarketplaceShopAllItem key={item.id} product={item} />),
        )}
      </div>
      {productsResult.hasNextPage && !productsResult.isFetchingNextPage && (
        <ShowMoreButton onClick={() => productsResult.fetchNextPage()} />
      )}
    </>
  );
};

export default Ads;
