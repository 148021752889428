import BackButton from "@src/components/nav/BackButton";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

const ConditionsContainer = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="pl-4 pt-4">
        <BackButton stepback desktopVisible />
      </div>
      <div className="flex flex-col gap-3 pb-4 text-justify text-xs text-gray-700">
        <div className="text-left text-smbase font-bold">
          {t("pages.conditionContainer.header")}
        </div>
        <div className="font-semibold">{t("pages.conditionContainer.title1")}</div>
        <div>{t("pages.conditionContainer.text1")}</div>
        <div className="font-semibold">{t("pages.conditionContainer.title2")}</div>
        <div>{t("pages.conditionContainer.text2")}</div>
        <div className="font-semibold">{t("pages.conditionContainer.title3")}</div>
        <div>{t("pages.conditionContainer.text3")}</div>
        <div className="font-semibold">{t("pages.conditionContainer.title4")}</div>
        <div>{t("pages.conditionContainer.text4")}</div>
        <div className="font-semibold">{t("pages.conditionContainer.title5")}</div>
        <div>{t("pages.conditionContainer.text5")}</div>
        <div className="font-semibold">{t("pages.conditionContainer.title6")}</div>
        <div>{t("pages.conditionContainer.text6")}</div>
        <div className="font-semibold">{t("pages.conditionContainer.title7")}</div>
        <div>{t("pages.conditionContainer.text7")}</div>
        <div className="font-semibold">{t("pages.conditionContainer.title8")}</div>
        <div>{t("pages.conditionContainer.text8")}</div>
        <div className="font-semibold">{t("pages.conditionContainer.title9")}</div>
        <div>{t("pages.conditionContainer.text9")}</div>
        <div className="font-semibold">{t("pages.conditionContainer.title10")}</div>
        <div>{t("pages.conditionContainer.text10")}</div>
        <div>{t("pages.conditionContainer.text11")}</div>
        <div className="font-semibold">day one mediagroup GmbH & Co. KG</div>
        <div className="flex flex-col ">
          <div>August-Bebel-Straße 11/13</div>
          <div>09113 Chemnitz</div>
          <div>Deutschland</div>
        </div>
        <div className="flex flex-col ">
          <div>Telefon: +49 (0) 371 52 49 99 19 00</div>
          <div>Telefax: +49 (0) 371 91 89 56 11</div>
          <div>E-Mail: info@tradefoox.com</div>
        </div>
        <div className="inline-flex gap-1 ">
          <div className="font-semibold">USt-IdNr.:</div>
          DE 304342652
        </div>
        <div className="flex flex-col ">
          <div className="inline-flex gap-1">
            <div className="font-semibold">{t("pages.conditionContainer.text12")}</div>
            Ronny Streit, Nico Seifert
          </div>
        </div>
        <div>{t("pages.conditionContainer.header")}</div>
        <div>Stand: 21.September 2021</div>
        <div>
          <div className="font-semibold">{t("pages.conditionContainer.text13")}</div>
          <div className="flex flex-col gap-0.5">
            <NavLink to="/datenschutz" className="text-darkcyan">
              {t("pages.conditionContainer.text14")}
            </NavLink>
            <NavLink to="/urheberrecht" className="text-darkcyan">
              {t("pages.conditionContainer.text15")}
            </NavLink>
            <NavLink to="/cookies" className="text-darkcyan">
              {t("pages.conditionContainer.text16")}
            </NavLink>
            <NavLink to="/community" className="text-darkcyan">
              {t("pages.comRulesContainer.text1")}
            </NavLink>
            <NavLink to="/nutzungsbedingungen" className="text-darkcyan">
              {t("pages.conditionContainer.text17")}
            </NavLink>
          </div>
        </div>
      </div>
    </>
  );
};

export default ConditionsContainer;
