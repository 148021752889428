import { ArrowUpOnSquareIcon, HeartIcon, TrashIcon } from "@heroicons/react/24/outline";
import { t } from "i18next";

interface Product {
  title: string;
  imgUrl: string;
  price: string;
  MRP: string;
  ID: string;
  category: string;
  subCategory: string;
  description?: string;
}

interface ProductProps {
  product: Product;
}

const CartProducts: React.FC<ProductProps> = ({ product }: ProductProps) => {
  return (
    <div className="space-y-4 md:flex md:flex-col md:items-center md:justify-between md:gap-6 md:space-y-0 lg:flex-row">
      <div className="flex flex-row">
        <div className="flex flex-col md:justify-center">
          <input
            type="checkbox"
            className="size-4 rounded border-gray-300 text-darkblue focus:ring-darkblue focus:ring-offset-0 invalid:focus:ring-tf-red-600"
          />
        </div>
        <div className="mr-1">
          <img className="size-[80px] rounded-lg object-contain" src={product.imgUrl} />
        </div>
        <div className="w-full min-w-0 flex-1 space-y-3 md:order-2 md:max-w-md">
          <div className="h-12 overflow-hidden py-0">
            <a href="/marketplace/01fc5aed-d880-460b-bd87-a1cecec3502f" className="hover:underline">
              <h3 className="mb-2 h-12 overflow-hidden text-xs font-medium">{product.title}</h3>
            </a>
          </div>

          <div className="flex items-center gap-3">
            <button
              type="button"
              className="inline-flex items-center text-xs font-medium text-gray-500 hover:text-gray-900 hover:underline dark:text-gray-400 dark:hover:text-white"
            >
              <HeartIcon className="size-4" />
              <div className="hidden lg:block">{t("main.shop.buttons.addToFavourites")}</div>
            </button>
            <button
              type="button"
              className="inline-flex items-center text-xs font-medium text-gray-500 hover:text-gray-900 hover:underline dark:text-gray-400 dark:hover:text-white"
            >
              <ArrowUpOnSquareIcon className="size-4" />
              <div className="hidden lg:block"> {t("buttons.share")}</div>
            </button>

            <button
              type="button"
              className="inline-flex items-center text-xs font-medium text-red-600 hover:underline dark:text-red-500"
            >
              <TrashIcon className="size-4" />
              <div className="hidden lg:block"> {t("main.shop.buttons.remove")}</div>
            </button>
          </div>
        </div>
      </div>

      <div className="flex items-center justify-between md:order-3 md:justify-end">
        <div className="flex items-center">
          <button
            type="button"
            id="decrement-button"
            data-input-counter-decrement="counter-input"
            className="inline-flex size-5 shrink-0 items-center justify-center rounded-md border border-gray-300 bg-gray-100 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-100 dark:border-gray-600 dark:bg-gray-700 dark:hover:bg-gray-600 dark:focus:ring-gray-700"
          >
            <svg
              className="size-2.5 text-gray-900 dark:text-white"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 18 2"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M1 1h16"
              />
            </svg>
          </button>
          <input
            type="text"
            id="counter-input"
            data-input-counter
            className="w-10 shrink-0 border-0 bg-transparent text-center text-sm font-medium text-gray-900 focus:outline-none focus:ring-0 dark:text-white"
            placeholder=""
            value="2"
            required
          />
          <button
            type="button"
            id="increment-button"
            data-input-counter-increment="counter-input"
            className="inline-flex size-5 shrink-0 items-center justify-center rounded-md border border-gray-300 bg-gray-100 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-100 dark:border-gray-600 dark:bg-gray-700 dark:hover:bg-gray-600 dark:focus:ring-gray-700"
          >
            <svg
              className="size-2.5 text-gray-900 dark:text-white"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 18 18"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M9 1v16M1 9h16"
              />
            </svg>
          </button>
        </div>
        <div className="text-end md:order-4 md:w-32">
          <p className="text-base font-bold text-gray-900 dark:text-white">€{product.MRP}</p>
        </div>
      </div>
    </div>
  );
};

export default CartProducts;
