import { PropsWithChildren } from "react";

const SquareTiles = (props: PropsWithChildren) => {
  return (
    <div className="grid aspect-square w-[350px] grid-cols-2 gap-2 rounded-xl bg-white p-3">
      {props.children}
    </div>
  );
};

export default SquareTiles;
