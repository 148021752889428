import { closeModal, openModal } from "@src/state/modal/paymentModalSlice";
import { AppDispatch, RootState } from "@src/state/store";
import { useDispatch, useSelector } from "react-redux";

const usePaymentModal = () => {
  const dispatch = useDispatch<AppDispatch>();
  const isOpen = useSelector((state: RootState) => state.paymentModal.isOpen);

  const open = () => {
    dispatch(openModal());
  };

  const close = () => {
    dispatch(closeModal());
  };

  return { isOpen, open, close };
};

export default usePaymentModal;
