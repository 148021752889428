import { XMarkIcon } from "@heroicons/react/24/outline";
import { Fragment, ReactNode } from "react";
import tw, { TwStyle } from "twin.macro";

type Props = {
  title?: string;
  children: ReactNode;
  padding?: boolean;
  onClose?: () => void;
  windowStyle?: TwStyle;
  mobileFullscreen?: boolean;
};
const PopupWrapper = ({
  title,
  children,
  padding = true,
  onClose,
  windowStyle,
  mobileFullscreen,
}: Props) => {
  return (
    <Fragment>
      <div className="fixed inset-0 z-40 bg-black bg-opacity-40 " onClick={onClose} />

      <div
        css={[
          tw`relative`,
          !windowStyle &&
            tw`border border-gray-300 bg-white shadow-2xl rounded-2xl fixed inset-0 top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 z-50 min-w-[300px] w-[90vw] md:w-fit h-fit max-h-full overflow-y-auto h-fit max-h-[calc(100vh-130px)] min-h-[200px] bg-gray-50`,
          mobileFullscreen &&
            tw`max-lg:top-0 max-lg:left-0 max-lg:translate-y-0 max-lg:translate-x-0 max-lg:w-full max-lg:h-full max-lg:rounded-none`,
          windowStyle,
        ]}
      >
        <div css={[padding && tw`p-3`]}>
          {title && (
            <div className="mb-4 flex w-full flex-row items-center gap-2 border-b pb-1 text-base font-semibold">
              {title}
            </div>
          )}
          {!title && <div className="mb-7"></div>}
          <button onClick={onClose} className="absolute right-3 top-3 text-black">
            <XMarkIcon className="size-6" />
          </button>
          {children}
        </div>
      </div>
    </Fragment>
  );
};
export default PopupWrapper;
