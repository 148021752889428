import BigTile from "@src/pages/marketplace/fullpage/tiles/BigTile";
import BigTileMitFourImg from "@src/pages/marketplace/fullpage/tiles/BigTileWithFourImg";
import BigTileMitTopAngebot from "@src/pages/marketplace/fullpage/tiles/BigTileWithTopOffer";
import HalfCircleTitle from "@src/pages/marketplace/fullpage/tiles/HalfCircleTitle";
import MarketplaceBanner from "@src/pages/marketplace/fullpage/tiles/MarketplaceBanner";
import ScrollListH from "@src/pages/marketplace/fullpage/tiles/ScrollListH";
import SmallTile from "@src/pages/marketplace/fullpage/tiles/SmallTile";

const FullPageContainer = () => {
  const bannerImages = [
    "/images/marketplace/banner/1.jpg",
    "/images/marketplace/banner/2.jpg",
    "/images/marketplace/banner/4.jpg",
  ];

  return (
    <div className="relative">
      <div className="relative z-0 h-2/3">
        <MarketplaceBanner bannerImages={bannerImages} />
      </div>
      <div className="absolute bottom-0 left-0 h-1/3 w-full bg-gradient-to-b from-transparent to-slate-50"></div>
      <div className="absolute inset-x-0 top-2/3 z-10 mb-5 flex flex-col gap-4 p-4">
        <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-4">
          <BigTileMitTopAngebot />
          <BigTileMitFourImg />
          <BigTileMitTopAngebot />
          <BigTileMitFourImg />
        </div>
        <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-4">
          <BigTile />
          <BigTile />
          <BigTile />
          <BigTile />
        </div>
        <ScrollListH>
          <SmallTile />
        </ScrollListH>
        <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-4">
          <BigTileMitFourImg />
          <BigTileMitFourImg />
          <BigTileMitFourImg />
          <BigTileMitFourImg />
        </div>
        <ScrollListH>
          <SmallTile />
        </ScrollListH>
        <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-4">
          <BigTileMitTopAngebot />
          <BigTileMitTopAngebot />
          <BigTileMitTopAngebot />
          <BigTileMitTopAngebot />
        </div>
        <ScrollListH>
          <HalfCircleTitle />
        </ScrollListH>
      </div>
    </div>
  );
};

export default FullPageContainer;
