import React from "react";

interface TabProps {
  children?: React.ReactNode;
  id: string;
}

const TabBody: React.FC<TabProps> = props => {
  return <>{props.children}</>;
};

export default TabBody;
