import hashtags from "@src/api/hashtags/hashtags";
import CompactPost from "@src/components/elements/CompactPost";
import { useInfiniteQueryOnScroll } from "@src/hooks/useInfiniteQueryOnScroll";

type Props = {
  userId?: string;
  filter: string;
};

const HashtagList = ({ userId, filter }: Props) => {
  const queryParams = {
    user_id: userId,
    watch: filter === "watch",
  };

  const { result: hashtagsResult } = useInfiniteQueryOnScroll({
    queryKey: ["hashtags", filter, "no-trending"],
    queryFn: ({ pageParam }) => hashtags({ params: { ...queryParams, page: pageParam } }),
    initialPageParam: 0,
    getNextPageParam: lastPage => {
      const nextPage = lastPage.current_page + 1;
      return nextPage <= lastPage.total_pages ? nextPage : null;
    },
  });

  if (!hashtagsResult.isSuccess) return <></>;

  return (
    <div className="grid grid-cols-2 gap-1 md:grid-cols-3 xl:grid-cols-5 xl:gap-3">
      {hashtagsResult.data.pages
        .flatMap(page => page.data)
        .filter(hashtag => hashtag !== null)
        .map(hashtag => (
          <CompactPost
            key={`hashtag:${hashtag.hashtag}`}
            link={`/tags/${hashtag.hashtag}/${hashtag.media_type === "video" ? "watch" : "feed"}`}
            type={hashtag.media_type === "video" ? "video" : "image"}
            url={hashtag.media_data_url ?? "/images/default_header_profile.png"}
            viewCount={hashtag.total_views}
            likeCount={hashtag.total_like_count}
            authorName={hashtag.author_name}
            authorAvatar={hashtag.author_avatar}
          />
        ))}
    </div>
  );
};

export default HashtagList;
