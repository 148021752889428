import api, { requestParams, RequestWithId, RequestWithParams } from "@src/api/api";
import { MessageType } from "@src/utils/types/MessageType";

export type MessagesParams = {
  q?: string;
};

export type MessagesRequest = RequestWithId & RequestWithParams<MessagesParams>;

export type MessagesResponse = {
  data: MessageType[];
};

export default async function messages(request: MessagesRequest): Promise<MessagesResponse> {
  const { data } = await api.get(
    `/api/v1/chats/${request.id}/messages${requestParams(request.params)}`,
  );
  return { data };
}
