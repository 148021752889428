import { NavLink } from "react-router-dom";
import tw from "twin.macro";

type Props = {
  name: string;
  link: string;
  picture: string;
  key?: string;
};
const SuggestionsItem = (props: Props) => (
  <div className="no-scrollbar mt-2 flex gap-4 overflow-x-auto">
    <NavLink to={props.link} className="relative">
      <div className="relative grid cursor-pointer grid-cols-[65px,auto]">
        <div className="relative size-[50px] rounded-full border border-pink-400">
          <img className="overflow-hidden rounded-full" src={props.picture} alt="Inserat" />
        </div>
        <div
          css={[
            tw`text-xs text-center whitespace-nowrap text-gray-600 text-sm truncate leading-[50px]`,
          ]}
        >
          {props.name}
        </div>
      </div>
    </NavLink>
  </div>
);

export default SuggestionsItem;
