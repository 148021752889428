import api from "@src/api/api";
import { UserStatisticsFollowType } from "@src/utils/types/UserStatisticsFollowType";

export type FollowResponse = {
  data: UserStatisticsFollowType;
};

export default async function follow(): Promise<FollowResponse> {
  const { data } = await api.get("/api/v1/users/statistics/follow");
  return data;
}
