import GoogleMapReact from "google-map-react";
import { useState } from "react";
import { useTranslation } from "react-i18next";

type Props = { location?: { lat: number; lng: number }; visible?: boolean };

const GoogleMapsWidget = ({
  location = { lat: 50.82874494097534, lng: 12.932323424965592 },
  visible = true,
}: Props) => {
  const [isVisible, setIsVisible] = useState(visible);
  const { t } = useTranslation();

  return (
    <div className="mt-2 flex flex-row items-start gap-2">
      {isVisible && (
        <div className="h-[200px] w-full">
          <GoogleMapReact
            bootstrapURLKeys={{
              key: "AIzaSyDqA84FzZeZ3GlMYmE9XMohml8bMTRM47M",
            }}
            defaultCenter={location}
            defaultZoom={14}
          />
        </div>
      )}
      <button onClick={() => setIsVisible(!isVisible)}>
        {isVisible ? (
          <div className="font-mono">[—]</div>
        ) : (
          <div className="flex flex-row items-center gap-2 text-sm">
            <div>{t("components.shared.showMap")}</div>
            <div className="font-mono">[+]</div>
          </div>
        )}
      </button>
    </div>
  );
};

export default GoogleMapsWidget;
