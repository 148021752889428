import {
  DefaultError,
  InfiniteData,
  QueryKey,
  UndefinedInitialDataInfiniteOptions,
  useInfiniteQuery,
} from "@tanstack/react-query";
import { useCallback, useEffect } from "react";

type ScrollOptions = {
  selector?: string | null;
  offset?: number;
};

export function useInfiniteQueryOnScroll<
  TQueryFnData,
  TError = DefaultError,
  TData = InfiniteData<TQueryFnData>,
  TQueryKey extends QueryKey = QueryKey,
  TPageParam = unknown,
>(
  queryOptions: UndefinedInitialDataInfiniteOptions<
    TQueryFnData,
    TError,
    TData,
    TQueryKey,
    TPageParam
  >,
  scrollOptions: ScrollOptions = {},
) {
  const { selector = "main", offset = 1000 } = scrollOptions;

  const result = useInfiniteQuery(queryOptions);

  const handleScroll = useCallback(
    (event: Event) => {
      if (
        !(event.currentTarget instanceof Element) ||
        result.isFetchingNextPage ||
        !result.hasNextPage
      )
        return;

      const bottomOffset = Math.abs(
        event.currentTarget.scrollHeight -
          event.currentTarget.scrollTop -
          event.currentTarget.clientHeight,
      );

      if (bottomOffset < offset) {
        result.fetchNextPage();
      }
    },
    [offset, result],
  );

  useEffect(() => {
    if (selector === null) return;
    const element = document.querySelector(selector);
    if (element === null) return;
    element.addEventListener("scroll", handleScroll);
    return () => element.removeEventListener("scroll", handleScroll);
  }, [selector, handleScroll]);

  return { result, handleScroll };
}
