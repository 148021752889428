import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

type Props = {
  value?: number;
  maxValue?: number;
};

const ProgressCircle = ({ value = 0, maxValue = 1 }: Props) => {
  return (
    <div className="size-7">
      <CircularProgressbar
        className="custom-progress-bar"
        value={value}
        maxValue={maxValue}
        text={""}
        styles={buildStyles({
          textColor: "transparent",
          pathColor: value > maxValue ? "red" : "#3e98c7",
          trailColor: "#d6d6d6",
        })}
      />
    </div>
  );
};

export default ProgressCircle;
