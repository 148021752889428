import AllProducts from "@src/mocks/AllProducts.json";
import CartSimilarProducts from "@src/pages/marketplace/components/CartSimilarProducts";
import Product from "@src/pages/marketplace/components/Product";
import ProductsCarousel from "@src/pages/marketplace/components/ProductsCarousel";
import TrendingProducts from "@src/pages/marketplace/components/TrendingProducts";
import { t } from "i18next";
import { SwiperSlide } from "swiper/react";

const RebuyContainer = () => {
  const allProductsFromFile = AllProducts.allProducts;
  return (
    <div className="bg-white">
      <div className="mb-5 grid grid-cols-2 gap-4 md:grid-cols-3 lg:grid-cols-5">
        {allProductsFromFile.map(product => (
          <div key={product.ID} className="flex justify-center">
            <Product product={product} />
          </div>
        ))}
      </div>
      {/* carousel start */}
      <div className="mt-3 xl:block">
        <span className="text-xl font-semibold">{t("main.shop.details.similarProducts")}</span>
        <ProductsCarousel spaceBetween={20} slidesPerView={1}>
          {allProductsFromFile.map(product => (
            <div key={product.ID}>
              <SwiperSlide>
                <CartSimilarProducts product={product} />
              </SwiperSlide>
            </div>
          ))}
        </ProductsCarousel>
      </div>
      <div className="mb-5 mt-3 xl:block">
        <span className="text-xl font-semibold">{t("main.contacts.tabs.trending")}</span>
        <ProductsCarousel spaceBetween={20} slidesPerView={1}>
          {allProductsFromFile.map(product => (
            <div key={product.ID}>
              <SwiperSlide>
                <TrendingProducts product={product} />
              </SwiperSlide>
            </div>
          ))}
        </ProductsCarousel>
      </div>
      {/* carousel end */}
    </div>
  );
};

export default RebuyContainer;
