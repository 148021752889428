import { Menu } from "@headlessui/react";
import { Bars3Icon } from "@heroicons/react/24/outline";
import { Children, cloneElement, ReactElement, ReactNode } from "react";

type Props = {
  children?: ReactNode;
  icon?: ReactNode;
  align?: string;
};

const Dropdown = ({ children, icon = <Bars3Icon className="size-6" />, align = "" }: Props) => {
  return (
    <Menu as="div" className="relative">
      <Menu.Button>{icon}</Menu.Button>
      <Menu.Items
        className={`absolute right-0 z-10 mt-2 grid w-56 origin-top-right grid-flow-row rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none ${
          align === "left" && ""
        } ${align === "right" && "translate-x-full"}`}
      >
        {Children.map(children, (child, index) => {
          return (
            <>
              {child && (
                <Menu.Item>
                  {({ active }) => (
                    <button key={index} className="group">
                      {cloneElement(child as ReactElement, {
                        activeStyle: active ? " bg-gray-100 text-gray-900" : " text-gray-700",
                      })}
                    </button>
                  )}
                </Menu.Item>
              )}
            </>
          );
        })}
      </Menu.Items>
    </Menu>
  );
};

export default Dropdown;
