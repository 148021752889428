import UserWidget from "@src/components/elements/UserWidget";
import EditModal from "@src/pages/messenger/EditGroupModal";
import { useAppSelector } from "@src/state/hooks";
import useLoginModal from "@src/state/modal/useLoginModal";
import getName from "@src/utils/getName";
import { ChatType } from "@src/utils/types/ChatType";
import { User } from "@src/utils/user";
import React from "react";
import tw from "twin.macro";

type Props = {
  chat: ChatType;
  onClick: () => void;
  onChange: (checked: boolean) => void;
  showCheckbox?: boolean;
  key?: string;
  checked?: boolean;
  isSelected?: boolean;
};

export const getChatPartner = (members: Array<{ user: User }>, self: User) => {
  for (const member of members) {
    if (self.id !== member.user.id) {
      return member.user;
    }
  }
  return members[0].user;
};
const Messenger = ({ chat, onClick, onChange, showCheckbox, checked, isSelected }: Props) => {
  const { user } = useAppSelector(state => state.user);
  const [showEditModal, setShowEditModal] = React.useState(false);
  const loginModal = useLoginModal();
  if (!user) {
    loginModal.open();
    return <></>;
  }

  const partner = getChatPartner(chat.members, user);
  const placeholder = "/images/placeholder/club.png";

  if (!partner) return <></>;

  return (
    <button
      css={[isSelected && tw`bg-gray-200`, tw`relative flex flex-row items-start p-2 gap-2`]}
      className="group"
      onClick={() => onClick()}
    >
      {chat.group ? (
        <div
          css={[tw`rounded-full border border-gray-300 relative bg-white shrink-0`]}
          style={{
            width: "45px",
            height: "45px",
          }}
        >
          <div
            css={[tw`flex justify-center items-center rounded-full overflow-hidden h-full w-full`]}
          >
            {chat.image ? (
              <img className="w-full" src={`${chat.image.data_url}`} />
            ) : (
              <img className="w-full" src={placeholder} />
            )}
          </div>
        </div>
      ) : (
        <UserWidget user={partner} follow={false} online={false} size={45} />
      )}
      <div className="mr-auto flex flex-col truncate">
        <div className="truncate text-left text-sm">
          {chat.group ? chat.name : getName(partner)}
        </div>
        <div className="line-clamp-1 text-left text-xs">
          {chat.last_message_text.startsWith("chat/media/") ? "Media" : chat.last_message_text}
        </div>
        <div className="text-left text-xs">{chat.created_at}</div>
      </div>
      <EditModal
        chatId={chat.id}
        name={chat.name}
        avatar={chat.image}
        members={chat.members.map(item => item.user)}
        visible={showEditModal}
        closeModal={() => setShowEditModal(false)}
      />
      {showCheckbox && (
        <button className="max-md:pr-2">
          <input
            id="chat-checkbox"
            name="chat-checkbox"
            type="checkbox"
            checked={checked}
            onClick={e => {
              e.stopPropagation();
            }}
            onChange={e => {
              onChange(e.target.checked);
            }}
            className="my-auto size-4 cursor-pointer rounded-full border-gray-300 text-blue-500 focus:ring-offset-0"
          />
        </button>
      )}
    </button>
  );
};

export default Messenger;
