import BigTile from "@src/pages/assignments/fullpage/tiles/BigTile";
import HalfCircleTitle from "@src/pages/assignments/fullpage/tiles/HalfCircleTitle";
import ScrollListH from "@src/pages/assignments/fullpage/tiles/ScrollListH";
import SmallTile from "@src/pages/assignments/fullpage/tiles/SmallTile";
import SquareTiles from "@src/pages/assignments/fullpage/tiles/SquareTiles";
import TinyTile from "@src/pages/assignments/fullpage/tiles/TinyTile";
import tw from "twin.macro";

const FullPageContainer = () => {
  return (
    <div className="flex flex-col gap-3 ">
      <div className="">
        <BigTile />
      </div>

      <ScrollListH>
        <SmallTile />
        <SmallTile />
        <SmallTile />
        <SmallTile />
        <SmallTile />
        <SmallTile />
        <SmallTile />
        <SmallTile />
        <SmallTile />
        <SmallTile />
        <SmallTile />
        <SmallTile />
        <SmallTile />
      </ScrollListH>

      <ScrollListH>
        <SmallTile />
        <SmallTile />
        <SmallTile />
        <SmallTile />
        <SmallTile />
        <SmallTile />
        <SmallTile />
        <SmallTile />
        <SmallTile />
        <SmallTile />
        <SmallTile />
        <SmallTile />
        <SmallTile />
      </ScrollListH>

      <ScrollListH height={tw`h-fit`} showBackground={false}>
        <SquareTiles>
          <TinyTile />
          <TinyTile />
          <TinyTile />
          <TinyTile />
        </SquareTiles>
        <SquareTiles>
          <TinyTile />
          <TinyTile />
          <TinyTile />
          <TinyTile />
        </SquareTiles>
        <SquareTiles>
          <TinyTile />
          <TinyTile />
          <TinyTile />
          <TinyTile />
        </SquareTiles>
        <SquareTiles>
          <TinyTile />
          <TinyTile />
          <TinyTile />
          <TinyTile />
        </SquareTiles>
      </ScrollListH>

      <ScrollListH>
        <SmallTile />
        <SmallTile />
        <SmallTile />
        <SmallTile />
        <SmallTile />
        <SmallTile />
        <SmallTile />
        <SmallTile />
        <SmallTile />
        <SmallTile />
        <SmallTile />
        <SmallTile />
        <SmallTile />
      </ScrollListH>

      <ScrollListH>
        <SmallTile />
        <SmallTile />
        <SmallTile />
        <SmallTile />
        <SmallTile />
        <SmallTile />
        <SmallTile />
        <SmallTile />
        <SmallTile />
        <SmallTile />
        <SmallTile />
        <SmallTile />
        <SmallTile />
      </ScrollListH>

      <ScrollListH height={tw`h-fit`} showBackground={false}>
        <SquareTiles>
          <TinyTile />
          <TinyTile />
          <TinyTile />
          <TinyTile />
        </SquareTiles>
        <SquareTiles>
          <TinyTile />
          <TinyTile />
          <TinyTile />
          <TinyTile />
        </SquareTiles>
        <SquareTiles>
          <TinyTile />
          <TinyTile />
          <TinyTile />
          <TinyTile />
        </SquareTiles>
        <SquareTiles>
          <TinyTile />
          <TinyTile />
          <TinyTile />
          <TinyTile />
        </SquareTiles>
      </ScrollListH>

      <ScrollListH>
        <HalfCircleTitle />
        <HalfCircleTitle />
        <HalfCircleTitle />
        <HalfCircleTitle />
        <HalfCircleTitle />
        <HalfCircleTitle />
      </ScrollListH>
    </div>
  );
};

export default FullPageContainer;
