import {
  BlockUserModalActions,
  HIDE_BLOCK_USER_MODAL,
  SHOW_BLOCK_USER_MODAL,
} from "@src/state/blockUserModal/types";

export type BlockUserModalState = {
  userId: string | null;
  isVisible: boolean;
};

const initialState: BlockUserModalState = {
  userId: null,
  isVisible: false,
};

const blockUserModalReducer = (
  state = initialState,
  action: BlockUserModalActions,
): BlockUserModalState => {
  switch (action.type) {
    case SHOW_BLOCK_USER_MODAL:
      return {
        ...state,
        userId: action.payload.userId,
        isVisible: true,
      };
    case HIDE_BLOCK_USER_MODAL:
      return {
        ...state,
        userId: null,
        isVisible: false,
      };
    default:
      return state;
  }
};

export default blockUserModalReducer;
