import { closeModal, openModal } from "@src/state/modal/modalSlice";
import { AppDispatch, RootState } from "@src/state/store";
import { useDispatch, useSelector } from "react-redux";

const useLoginModal = () => {
  const dispatch = useDispatch<AppDispatch>();
  const isOpen = useSelector((state: RootState) => state.login.isOpen);

  const open = () => {
    dispatch(openModal());
  };

  const close = () => {
    dispatch(closeModal());
  };

  return { isOpen, open, close };
};

export default useLoginModal;
