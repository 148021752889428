import { XMarkIcon } from "@heroicons/react/24/outline";
import ExternalLink from "@src/components/elements/shared/ExternalLink";
import { LinkPreviewType } from "@src/utils/types/LinkPreviewType";
import tw from "twin.macro";

type Props = {
  link: LinkPreviewType;
  small?: boolean;
  onDelete?: () => void;
  hideBorder?: boolean;
  hideEdge?: boolean;
};

const LinkPreview = ({ link, small, onDelete, hideBorder, hideEdge }: Props) => {
  const domainFromUrl = (url: string) => {
    // eslint-disable-next-line no-useless-escape
    let match = url.match(/^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:\/\n\?\=]+)/im);
    let result;
    if (match) {
      result = match[1];
      // eslint-disable-next-line no-useless-escape
      match = result.match(/^[^\.]+\.(.+\..+)$/);
      if (match) {
        result = match[1];
      }
    }
    return result;
  };

  return (
    <div
      css={[
        tw`w-full relative border border-gray-400 rounded-2xl overflow-hidden`,
        hideBorder && tw`border-none`,
        hideEdge && tw`rounded-none`,
      ]}
      style={{ wordBreak: "break-word" }}
    >
      {onDelete && (
        <button onClick={onDelete} className="absolute right-3 top-3 z-50">
          <XMarkIcon className="size-6" />
        </button>
      )}
      <ExternalLink to={link.url}>
        {link.image && (
          <div css={[tw`relative aspect-video`]}>
            <img
              className="absolute left-0 top-0 size-full object-cover"
              src={link.image}
              alt={link.title}
            />
          </div>
        )}
        <div css={[tw`p-3 text-black`, small && tw`text-xssm leading-3`]}>
          {<div className="text-sm uppercase">{domainFromUrl(link.url)}</div>}
          {<div className="line-clamp-1 font-bold">{link.title}</div>}
          {link.description && <span css={[small && tw`line-clamp-1`]}>{link.description}</span>}
        </div>
      </ExternalLink>
    </div>
  );
};

export default LinkPreview;
