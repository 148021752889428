import Panel from "@src/components/box/Panel";
import UserWidget from "@src/components/elements/UserWidget";
import getUserInformation from "@src/utils/getUserInformation";
import { User } from "@src/utils/user";
import { useTranslation } from "react-i18next";

type Props = { user: User; minimized?: boolean };

const ClubContainer = ({ user, minimized = true }: Props) => {
  const { t } = useTranslation();

  return (
    <div className="flex justify-between">
      <Panel>
        <div>
          <div className="flex flex-col text-sm">
            <UserWidget user={user} showName={true} follow={false} verified={true} />

            {!minimized && (
              <div className="flex flex-col gap-1">
                <div className="flex w-full flex-col justify-between text-xs">
                  <div className="flex flex-row gap-x-1">
                    <div
                      className="truncate"
                      dangerouslySetInnerHTML={getUserInformation(user, t, {
                        zipCode: true,
                      })}
                    />
                  </div>
                  <div className="flex flex-row gap-x-1">
                    <div
                      className="truncate"
                      dangerouslySetInnerHTML={getUserInformation(user, t, {
                        street: true,
                      })}
                    />
                  </div>
                  <div className="flex flex-row gap-x-1">
                    <div
                      className="truncate"
                      dangerouslySetInnerHTML={getUserInformation(user, t, {
                        phone_number: true,
                      })}
                    />
                  </div>
                  <div className="flex flex-row gap-x-1">
                    <div
                      className="truncate"
                      dangerouslySetInnerHTML={getUserInformation(user, t, {
                        website: true,
                      })}
                    />
                  </div>
                  <div className="flex flex-row gap-x-1">
                    <div
                      className="truncate"
                      dangerouslySetInnerHTML={getUserInformation(user, t, {
                        email: true,
                      })}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </Panel>
    </div>
  );
};

export default ClubContainer;
