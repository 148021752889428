import api, { requestParams, RequestWithParams } from "@src/api/api";
import { User } from "@src/utils/user";

export type MentionsParams = {
  ids: string;
};

export type MentionsRequest = RequestWithParams<MentionsParams>;

export type MentionsResponse = {
  data: User[];
};

export default async function mentions(request: MentionsRequest): Promise<MentionsResponse> {
  const { data } = await api.get(`/api/v1/users/mentions${requestParams(request.params)}`);
  return data;
}
