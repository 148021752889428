import Input from "@src/components/elements/input/Input";

type Props = {
  value: string;
  name: string;
  placeholder: string;
  onChange: (name: string, value: string) => void;
};

const FormInput = ({ value, onChange, ...props }: Props) => (
  <Input
    name={props.name}
    placeholder={props.placeholder}
    value={value || ""}
    onChange={e => onChange(props.name, e)}
  />
);

export default FormInput;
