import feed from "@src/api/feed/feed";
import Panel from "@src/components/box/Panel";
import ScrollIcons from "@src/components/elements/shared/ScrollIcons";
import SuggestionsCenterItem from "@src/components/elements/sidebar/suggestions/SuggestionsCenterItem";
import { useQuery } from "@tanstack/react-query";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import tw from "twin.macro";

type Props = { id?: string; key?: string };

const NewsfeedSuggestions = ({ id }: Props) => {
  const { t } = useTranslation();
  const galleryRef = useRef<HTMLDivElement>(null);

  const feedResult = useQuery({
    queryKey: ["posts", "feed"],
    queryFn: () => feed({ params: {} }),
  });

  if (!feedResult.isSuccess) return <></>;

  return (
    <div className="px-3 lg:px-0">
      <Panel title={t("components.panels.popularNews")} mobileBorder={false} key={id}>
        <div className="relative">
          <div className="no-scrollbar flex gap-4 overflow-x-auto" ref={galleryRef}>
            {feedResult.data.data
              .filter(item => item.media.length > 0)
              .map(item => {
                return (
                  <SuggestionsCenterItem
                    key={item.id}
                    name={""}
                    link={"/news/für_dich?featured=" + item.id}
                    picture={
                      item.media[0].type === "image"
                        ? `${item.media[0].data_url}`
                        : `${item.media[0].data_url}/thumbnail.jpg?height=600&width=600&fit=crop`
                    }
                    imageStyle={tw`rounded-xl w-[150px] h-[150px]`}
                    nameStyle={tw`w-[150px]`}
                  />
                );
              })}
            <NavLink to="/news/">
              <div className="flex h-[150px] w-[100px] flex-col justify-center text-center">
                {t("buttons.showMore")}
              </div>
            </NavLink>
            <ScrollIcons galleryRef={galleryRef} />
          </div>
        </div>
      </Panel>
    </div>
  );
};
export default NewsfeedSuggestions;
