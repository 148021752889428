import { ReactElement, useEffect, useRef, useState } from "react";

type Props = {
  children: ReactElement;
};

const ProfileDetailsDescriptionContainer = ({ children }: Props) => {
  const [_largeText, setLargeText] = useState(false);
  const body = useRef<any>(null);
  useEffect(() => {
    body.current && body.current.clientHeight > 120 && setLargeText(true);
  }, [body]);

  return (
    <>
      <div
        className="flex flex-col items-start gap-4 lg:flex-row"
        style={{ minWidth: "max-content" }}
      >
        <div className="flex flex-col gap-7">
          <div
            className="flex flex-col gap-7"
            tw="bg-gray-100 p-4 rounded-xl w-full lg:w-auto border border-gray-300"
          >
            {children}
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfileDetailsDescriptionContainer;
