import { XMarkIcon } from "@heroicons/react/24/outline";
import Button from "@src/components/elements/input/Button";
import Input from "@src/components/elements/input/Input";
import Select from "@src/components/elements/input/Select";
import moment from "moment";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { v4 as uuid } from "uuid";

const CalendarPlanner = () => {
  type ScheduleType = {
    preset: string;
    startTime: number;
    endTime: number;
    id: string;
  };
  const { t } = useTranslation();

  const presetList = [
    { name: t("main.weekDays.everyDay"), value: "everyday" },
    { name: t("main.weekDays.mondayToFriday"), value: "weekday" },
    { name: t("main.weekDays.saturdayToSunday"), value: "weekend" },
    { name: t("main.weekDays.monday"), value: "monday" },
    { name: t("main.weekDays.tuesday"), value: "tuesday" },
    { name: t("main.weekDays.wednesday"), value: "wednesday" },
    { name: t("main.weekDays.thursday"), value: "Thursday" },
    { name: t("main.weekDays.friday"), value: "friday" },
    { name: t("main.weekDays.saturday"), value: "saturday" },
    { name: t("main.weekDays.sunday"), value: "sunday" },
  ];

  const [schedule, setSchedule] = useState<Array<ScheduleType>>([
    { preset: "everyday", startTime: 0, endTime: 0, id: uuid() },
  ]);
  const [hasChanged, setHasChanged] = useState(false);

  return (
    <div>
      <div>
        {schedule.map((item: ScheduleType, index: number) => {
          return (
            <div
              className="grid grid-flow-row items-center lg:grid-cols-[1fr,auto] lg:gap-2"
              key={item.id}
            >
              <Select
                name="schedule"
                items={presetList}
                defaultValue={item.preset}
                onChange={e => {
                  setHasChanged(true);
                  const newSchedule = [...schedule];
                  newSchedule[index].preset = e.target.value;
                  setSchedule(newSchedule);
                }}
              />
              <div className="grid grid-flow-row items-center gap-2 max-lg:grid-cols-[75px,30px,75px,20px] lg:grid-cols-[75px,auto,75px,20px]">
                <Input
                  name="start_time"
                  onChange={e => {
                    setHasChanged(true);
                    const newTime = moment(e, "kk:mm");
                    const newSchedule = [...schedule];
                    newSchedule[index].startTime = newTime.hours() * 3600 + newTime.minutes() * 60;
                    setSchedule(newSchedule);
                  }}
                  type="time"
                  value={item.startTime ? moment(item.startTime).format().substring(0, 16) : ""}
                />
                <div className="pt-3">{t("main.weekDays.to")}</div>
                <Input
                  name="end_time"
                  onChange={e => {
                    setHasChanged(true);
                    const newTime = moment(e, "kk:mm");
                    const newSchedule = [...schedule];
                    newSchedule[index].endTime = newTime.hours() * 3600 + newTime.minutes() * 60;
                    setSchedule(newSchedule);
                  }}
                  type="time"
                  value={item.endTime ? moment(item.endTime).format().substring(0, 16) : ""}
                />

                <div className="mt-3 h-5">
                  {(index > 0 || hasChanged) && (
                    <button
                      onClick={() => {
                        const newSchedule = [...schedule];
                        if (schedule.length > 1) {
                          newSchedule.splice(index, 1);
                        } else {
                          setHasChanged(false);
                          newSchedule[0] = {
                            preset: "everyday",
                            startTime: 0,
                            endTime: 0,
                            id: uuid(),
                          };
                        }
                        setSchedule(newSchedule);
                      }}
                    >
                      <XMarkIcon className="size-5" />
                    </button>
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>

      <Button
        onClick={() => {
          setHasChanged(true);
          const newSchedule = [...schedule];
          newSchedule.push({
            preset: "everyday",
            startTime: 0,
            endTime: 0,
            id: uuid(),
          });
          setSchedule(newSchedule);
        }}
        variant={Button.Variants.Transparent}
      >
        {t("main.weekDays.add")}
      </Button>
    </div>
  );
};

export default CalendarPlanner;
