import React from "react";
import tw from "twin.macro";

type Props = {
  items?: Array<{
    name?: string;
    value?: string;
  }>;
  name: string;
  label?: string;
  value?: string;
  onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  disabled?: boolean;
  defaultValue?: string;
  textSize?: string;
  mobileBorder?: boolean;
  dark?: boolean;
};

const Select = ({
  items,
  label,
  name,
  onChange,
  value,
  disabled,
  defaultValue,
  textSize = "sm:text-sm",
  mobileBorder = true,
  dark = false,
}: Props) => (
  <div className="relative mt-3">
    {label && (
      <label
        htmlFor={name}
        className="absolute -top-2.5 left-2 bg-white px-1 text-xs font-medium text-gray-700"
        css={[dark && tw`text-white`]}
      >
        {label}
      </label>
    )}
    <select
      id={name}
      name={name}
      disabled={disabled}
      className={`block w-full rounded-2xl border border-gray-300 px-3 py-2 pr-8 shadow-sm outline-darkblue focus:border-darkblue focus:ring-darkblue ${
        !mobileBorder &&
        "max-lg:border-transparent max-lg:py-2 max-lg:pl-0 max-lg:focus:border-transparent max-lg:focus:ring-0"
      } ${textSize}`}
      css={[dark && tw`bg-white bg-opacity-10 border-gray-800`]}
      onChange={onChange}
      value={value}
      defaultValue={defaultValue}
    >
      {items?.map(item => (
        <option key={item.value} value={item.value}>
          {item.name}
        </option>
      ))}
    </select>
  </div>
);

export default Select;
