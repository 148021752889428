import {
  AtSymbolIcon,
  CalendarDaysIcon,
  HashtagIcon,
  PhotoIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { PaperAirplaneIcon } from "@heroicons/react/24/solid";
import { AppContext } from "@src/App";
import create from "@src/api/posts/create";
import Panel from "@src/components/box/Panel";
import Alert from "@src/components/elements/Alert";
import LegacyLinkPreview from "@src/components/elements/LegacyLinkPreview";
import ProgressCircle from "@src/components/elements/ProgressCircle";
import Button from "@src/components/elements/input/Button";
import PostMedia from "@src/components/elements/posts/form/shared/PostMedia";
import PostTextEdit from "@src/components/elements/posts/form/shared/PostTextEdit";
import PostUserSelect from "@src/components/elements/posts/form/shared/PostUserSelect";
import Schedule from "@src/components/elements/shared/Schedule";
import ScheduleButton from "@src/components/elements/shared/buttons/ScheduleButton";
import Tab from "@src/components/menu/Tab";
import usePopupModal from "@src/state/modal/usePopupModal";
import { formatDate } from "@src/utils/formatDate";
import isMobile from "@src/utils/isMobile";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useContext, useRef, useState } from "react";
import "react-circular-progressbar/dist/styles.css";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export const findAutolink = (node: any): string | false => {
  // Base case: If the current node is of type 'autolink', return its URL
  if (node.type === "autolink" || node.type === "link") {
    return node.url;
  }

  // If the current node has children, iterate through them recursively
  if (node.children) {
    for (const child of node.children) {
      const result = findAutolink(child);
      if (result) {
        return result; // Return the URL if found
      }
    }
  }

  // If the function reaches here, it means the 'autolink' type was not found in this branch
  return false;
};

const CreatePost = () => {
  const { t } = useTranslation();
  const appContext = useContext(AppContext);

  if (window.innerWidth < 1280) {
    if (appContext?.setFullscreen) appContext.setFullscreen(true);
  }

  const navigate = useNavigate();

  const [adult, setAdult] = useState(false);
  const [text, setText] = useState("");
  const [scheduleDate, setScheduleDate] = useState<number | undefined>();
  const [disabledLink, setDisabledLink] = useState(false);
  const [linkPreviewId, setLinkPreviewId] = useState<string>();
  const [media, setMedia] = useState<
    Array<{
      id: string;
      key: string;
      file_name: string;
      type: string;
      thumbnail: string;
    }>
  >([]);
  const queryClient = useQueryClient();
  const popupModal = usePopupModal();
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const [atClick, setAtClick] = useState<() => void>();
  const [hashtagClick, setHashtagClick] = useState<() => void>();
  const [addMediaClick, setAddMediaClick] = useState<() => void>();
  const [bottomPadding, setBottomPadding] = useState(0);
  const [initHeight, setinitHeight] = useState(window.visualViewport?.height ?? 0);
  const viewPort = window.visualViewport;
  window.visualViewport?.addEventListener("scroll", blurHandler);
  window.visualViewport?.addEventListener("resize", resizeHandler);
  const [tab, setTab] = useState("basics");

  function resizeHandler() {
    if (window.visualViewport && viewPort) {
      let iosHeight = 0;
      if (!/iPhone|iPad|iPod/.test(window.navigator.userAgent)) {
        iosHeight = viewPort.height;
        setinitHeight(viewPort.height);
      }
      setBottomPadding(Math.abs(iosHeight - viewPort.height));
    }
  }

  function blurHandler() {
    setBottomPadding(0);
  }

  let parsed = { root: undefined };

  if (!disabledLink) {
    try {
      parsed = JSON.parse(text);
    } catch {
      /* empty */
    }
  }

  const link = !disabledLink && parsed.root && findAutolink(parsed.root);

  const createMutation = useMutation({
    mutationKey: ["post"],
    mutationFn: create,
    onSuccess: () => {
      setText("");
      setAdult(false);
      queryClient.invalidateQueries({ queryKey: ["posts"] });
      navigate("/news/für_dich");
    },
    onError: () => {
      popupModal.open(
        <Alert
          buttons={Alert.ButtonVariants.OK}
          onClose={popupModal.close}
          message="Dein Beitrag konnte nicht erstellt werden."
          title="Feed"
        />,
      );
    },
  });

  return (
    <div className="fixed left-0 top-0 size-full overflow-y-auto pt-[env(safe-area-inset-top)] lg:relative">
      <Tab isActive={tab === "basics"}>
        <Panel mobileBorder={false} padding={!isMobile}>
          <div className="flex flex-col gap-2 max-lg:h-screen max-lg:max-h-[calc(100dvh-calc(env(safe-area-inset-top)+env(safe-area-inset-bottom)))] max-lg:p-3">
            <div className="flex flex-row items-center gap-5 font-semibold">
              <button onClick={() => navigate("/news/für_dich")}>
                <XMarkIcon className="size-8 rounded-full p-1.5 hover:bg-gray-200" />
              </button>
              <PostUserSelect />
              <ProgressCircle value={text.length} maxValue={10000} />
            </div>
            {scheduleDate && (
              <button
                className="flex flex-row items-center gap-2 text-xs hover:underline"
                onClick={() => {
                  setTab("schedule");
                }}
              >
                <CalendarDaysIcon className="size-4" />
                {t("main.watch.create.publishAt")}{" "}
                {formatDate(new Date(scheduleDate).toISOString())}.
              </button>
            )}

            {link && (
              <LegacyLinkPreview
                link={link}
                onChange={setLinkPreviewId}
                onDelete={() => {
                  setLinkPreviewId(undefined);
                  setDisabledLink(true);
                }}
              />
            )}
            <div
              className="max-lg:grow max-lg:overflow-auto"
              css={{
                maxHeight:
                  "calc(100% - " +
                  (bottomPadding > 0 && bottomPadding < initHeight ? bottomPadding : 0) +
                  "px)",
              }}
            >
              <PostTextEdit
                value={text}
                onChange={setText}
                textRef={textareaRef}
                atClick={{ getFunction: atClick, setFunction: setAtClick }}
                hashtagClick={{
                  getFunction: hashtagClick,
                  setFunction: setHashtagClick,
                }}
                handleBlur={() => {
                  blurHandler();
                }}
              />
            </div>
            <PostMedia
              onChange={data => setMedia(value => [...value, data])}
              media={media}
              onDelete={id => setMedia(data => data.filter(item => item.id !== id))}
              addClick={{
                getFunction: addMediaClick,
                setFunction: setAddMediaClick,
              }}
            />
            <div className="flex flex-row items-baseline justify-between">
              <ScheduleButton onClick={() => setTab(tab === "schedule" ? "basics" : "schedule")} />
              <button onClick={atClick}>
                <AtSymbolIcon className="size-6 text-darkblue" />
              </button>
              <button onClick={hashtagClick}>
                <HashtagIcon className="size-6 text-darkblue" />
              </button>
              <button onClick={addMediaClick}>
                <PhotoIcon className="size-6 text-darkblue" />
              </button>
              <div className="max-sm:hidden"></div>

              <div className="w-fit">
                <Button
                  onClick={() =>
                    createMutation.mutate({
                      data: {
                        text,
                        adult,
                        media: media.map(m => m.id),
                        link: disabledLink ? undefined : link || "",
                        set_active_at: scheduleDate,
                        link_preview_id: linkPreviewId,
                      },
                    })
                  }
                >
                  <PaperAirplaneIcon className="size-5" />
                </Button>
              </div>
            </div>
          </div>
        </Panel>
      </Tab>
      <Tab isActive={tab === "schedule"}>
        <Schedule
          onClose={() => setTab("basics")}
          onChange={date => setScheduleDate(date)}
          selectedDate={scheduleDate}
        />
      </Tab>
    </div>
  );
};

export default CreatePost;
