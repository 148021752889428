import { ReactNode } from "react";

type Props = {
  children?: ReactNode;
  onClick?: () => void;
  className?: string;
  activeStyle?: string;
  key?: string;
};

const DropdownItem = ({ children, onClick, className = "", activeStyle = "" }: Props) => {
  return (
    <div className={`${className} ${activeStyle}`} onClick={onClick}>
      {children}
    </div>
  );
};

export default DropdownItem;
