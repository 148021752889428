import api, { requestParams, RequestWithParams } from "@src/api/api";
import { User } from "@src/utils/user";

export type IFollowParams = {
  page?: number;
  q?: string;
};

export type IFollowRequest = RequestWithParams<IFollowParams>;

export type IFollowResponse = {
  data: User[];
  current_page: number;
  total_pages: number;
};

export default async function iFollow(request: IFollowRequest): Promise<IFollowResponse> {
  const { data } = await api.get(
    `/api/v1/contacts/i_follow${requestParams({ page: 0, ...request.params })}`,
  );
  return data;
}
