import { EyeIcon } from "@heroicons/react/24/outline";
import PostsActionBarItem from "@src/pages/news/posts/PostsActionBarItem";

type Props = {
  viewCount: number;
  label?: string;
};

const ViewButton = ({ viewCount, label = "" }: Props) => {
  return (
    <PostsActionBarItem label={label} count={viewCount}>
      <EyeIcon />
    </PostsActionBarItem>
  );
};

export default ViewButton;
