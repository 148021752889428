import Products from "@src/mocks/AllProducts.json";
import CartSimilarProducts from "@src/pages/marketplace/components/CartSimilarProducts";
import Product from "@src/pages/marketplace/components/Product";
import ProductsCarousel from "@src/pages/marketplace/components/ProductsCarousel";
import TrendingProducts from "@src/pages/marketplace/components/TrendingProducts";
import { t } from "i18next";
import { Bar, Doughnut, Line, Pie } from "react-chartjs-2";
import { SwiperSlide } from "swiper/react";

const StatisticsContainer = () => {
  const salesTrendData = {
    labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul"],
    datasets: [
      {
        label: t("main.shop.details.salesTrend") + "(€)",
        fill: false,
        lineTension: 0.1,
        backgroundColor: "#4dc9f6",
        borderColor: "#4dc9f6",
        pointBorderColor: "#4dc9f6",
        pointBackgroundColor: "#fff",
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "#4dc9f6",
        pointHoverBorderColor: "#4dc9f6",
        pointHoverBorderWidth: 2,
        data: [5000, 7000, 5500, 9000, 6000, 8000, 7500],
      },
    ],
  };

  const categoryData = {
    labels: ["Electronics", "Clothing", "Home & Kitchen", "Books"],
    datasets: [
      {
        label: t("main.shop.details.productCategories"),
        data: [25, 30, 20, 25],
        backgroundColor: ["#3490dc", "#38c172", "#e3342f", "#6cb2eb"],
      },
    ],
  };

  const revenueDistributionData = {
    labels: [
      t("main.shop.details.directSales"),
      t("main.shop.details.onlineMarketplace"),
      t("main.shop.details.holesale"),
    ],
    datasets: [
      {
        data: [30000, 45000, 20000],
        backgroundColor: ["#38c172", "#3490dc", "#e3342f"],
        hoverBackgroundColor: ["#4fd888", "#55c9ff", "#ef4444"],
      },
    ],
  };

  const orderStatusData = {
    labels: [
      t("main.shop.details.pending"),
      t("main.shop.details.shipped"),
      t("main.shop.details.delivered"),
      t("main.shop.details.cancelled"),
    ],
    datasets: [
      {
        data: [5, 20, 50, 3],
        backgroundColor: ["#fcc419", "#38c172", "#3490dc", "#e3342f"],
        hoverBackgroundColor: ["#f6e05e", "#4fd888", "#55c9ff", "#ef4444"],
      },
    ],
  };

  const customerDemographicsData = {
    labels: ["18-24", "25-34", "35-44", "45-54", "55-64", "65+"],
    datasets: [
      {
        label: t("main.shop.details.ageGroups"),
        data: [350, 450, 300, 200, 150, 100],
        backgroundColor: "#3490dc",
        borderWidth: 1,
      },
      {
        label: t("main.shop.details.genderDistribution"),
        data: [600, 400],
        backgroundColor: "#38c172",
        borderWidth: 1,
      },
      {
        label: t("main.shop.details.geographicLocation"),
        data: [250, 150, 200, 300],
        backgroundColor: "#e3342f",
        borderWidth: 1,
      },
    ],
  };

  const customerDemographicsOptions = {
    scales: {
      y: {
        beginAtZero: true,
        stacked: true,
      },
      x: {
        stacked: true,
      },
    },
  };

  const salesChannelData = {
    labels: ["Website Sales", "Mobile App Sales", "Marketplace Sales"],
    datasets: [
      {
        label: t("main.shop.details.salesChannels"),
        data: [40000, 30000, 25000],
        backgroundColor: ["#3490dc", "#38c172", "#e3342f"],
        borderWidth: 1,
      },
    ],
  };

  const salesChannelOptions = {
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };
  const inventoryLevelData = {
    labels: ["Category A", "Category B", "Category C", "Category D"],
    datasets: [
      {
        label: t("main.shop.details.inventoryLevel"),
        data: [200, 150, 300, 250],
        backgroundColor: ["#3490dc", "#38c172", "#e3342f", "#6cb2eb"],
        borderWidth: 1,
      },
    ],
  };

  const inventoryLevelOptions = {
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };
  const revenueGrowthData = {
    labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
    datasets: [
      {
        label: t("main.shop.details.revenueGrowth") + "(€)",
        data: [5000, 6000, 5500, 7000, 6500, 8000],
        backgroundColor: "#38c172",
        borderWidth: 1,
      },
    ],
  };
  const topSellingProductsData = [
    { name: "Product A", salesVolume: 300 },
    { name: "Product B", salesVolume: 250 },
    { name: "Product C", salesVolume: 200 },
    { name: "Product D", salesVolume: 180 },
    { name: "Product E", salesVolume: 150 },
  ];
  const topSellingData = {
    labels: topSellingProductsData.map(product => product.name),
    datasets: [
      {
        label: t("main.shop.details.salesVolume"),
        data: topSellingProductsData.map(product => product.salesVolume),
        backgroundColor: "#3490dc",
        borderWidth: 1,
      },
    ],
  };

  const topSellingOptions = {
    scales: {
      x: {
        beginAtZero: true,
      },
      y: {
        ticks: {
          autoSkip: false,
          maxRotation: 0,
          padding: 10,
        },
      },
    },
  };

  const revenueGrowthOptions = {
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  return (
    <div className="container ">
      <div className="mb-4 grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-4">
        <div className="rounded-lg border border-blue-300 bg-blue-100 p-4 text-center">
          <h3 className="text-lg font-medium text-blue-700">
            {t("main.marketplace.tabs.totalExpenditure")}
          </h3>
          <p className="mt-2 text-2xl font-semibold text-blue-900">€{"2000"}</p>
        </div>

        <div className="rounded-lg border border-green-300 bg-green-100 p-4 text-center">
          <h3 className="text-lg font-medium text-green-700">
            {t("main.marketplace.tabs.totalOders")}
          </h3>
          <p className="mt-2 text-2xl font-semibold text-green-900">{"3"}</p>
        </div>

        <div className="rounded-lg border border-yellow-300 bg-yellow-100 p-4 text-center">
          <h3 className="text-lg font-medium text-yellow-700 ">
            {t("main.marketplace.tabs.avarageOderValue")}
          </h3>
          <p className="mt-2 text-2xl font-semibold text-yellow-900">€{"200"}</p>
        </div>

        <div className="rounded-lg border border-purple-300 bg-purple-100  p-4 text-center">
          <h3 className="text-lg font-medium text-purple-700">
            {t("main.marketplace.tabs.recentPurchase")}
          </h3>
          <p className="mt-2 text-2xl font-semibold text-purple-900">4</p>
        </div>
      </div>
      <div className="mb-5 grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-4">
        <div className="rounded-lg border border-gray-300 bg-white p-6">
          <h2 className="mb-4 text-xl font-semibold">{t("main.shop.details.salesTrend")}</h2>
          <Line data={salesTrendData} />
        </div>
        <div className="rounded-lg border border-gray-300 bg-white p-6">
          <h2 className="mb-4 text-xl font-semibold">{t("main.shop.details.productCategories")}</h2>
          <Bar data={categoryData} />
        </div>
        <div className="rounded-lg border border-gray-300 bg-white p-6">
          <h2 className="mb-4 text-xl font-semibold">
            {t("main.shop.details.revenueDistribution")}
          </h2>
          <Doughnut data={revenueDistributionData} />
        </div>
        <div className="rounded-lg border border-gray-300 bg-white p-6">
          <h2 className="mb-4 text-xl font-semibold">{t("main.shop.details.orderStatus")}</h2>
          <Pie data={orderStatusData} />
        </div>
        <div className="rounded-lg border border-gray-300 bg-white p-6">
          <h2 className="mb-4 text-xl font-semibold">
            {t("main.shop.details.customerDemographics")}
          </h2>
          <Bar data={customerDemographicsData} options={customerDemographicsOptions} />
        </div>
        <div className="rounded-lg border border-gray-300 bg-white p-6">
          <h2 className="mb-4 text-xl font-semibold">{t("main.shop.details.salesChannels")}</h2>
          <Bar data={salesChannelData} options={salesChannelOptions} />
        </div>
        <div className="rounded-lg border border-gray-300 bg-white p-6">
          <h2 className="mb-4 text-xl font-semibold">{t("main.shop.details.inventoryLevel")}</h2>
          <Bar data={inventoryLevelData} options={inventoryLevelOptions} />
        </div>
        <div className="rounded-lg border border-gray-300 bg-white p-6">
          <h2 className="mb-4 text-xl font-semibold">{t("main.shop.details.revenueGrowth")}</h2>
          <Bar data={revenueGrowthData} options={revenueGrowthOptions} />
        </div>
        <div className="rounded-lg border border-gray-300 bg-white p-6">
          <h2 className="mb-4 text-xl font-semibold">{t("main.shop.details.topSellingProduct")}</h2>
          <Bar data={topSellingData} options={topSellingOptions} />
        </div>
      </div>
      {/* carousel start */}
      <div className="mt-3 xl:block">
        <span className="text-xl font-semibold">{t("main.shop.details.similarProducts")}</span>
        <ProductsCarousel spaceBetween={20} slidesPerView={1}>
          {Products.allProducts.map(product => (
            <div key={product.ID}>
              <SwiperSlide>
                <CartSimilarProducts product={product} />
              </SwiperSlide>
            </div>
          ))}
        </ProductsCarousel>
      </div>
      <div className="mb-5 mt-3 xl:block">
        <span className="text-xl font-semibold">{t("main.contacts.tabs.trending")}</span>
        <ProductsCarousel spaceBetween={20} slidesPerView={1}>
          {Products.allProducts.map(product => (
            <div key={product.ID}>
              <SwiperSlide>
                <TrendingProducts product={product} />
              </SwiperSlide>
            </div>
          ))}
        </ProductsCarousel>
      </div>
      {/* carousel end */}
      <span className="text-xl font-semibold">{t("main.contacts.tabs.trending")}</span>
      <div className="mb-5 grid grid-cols-2 gap-4 md:grid-cols-3 lg:grid-cols-5">
        {Products.allProducts.map(product => (
          <div key={product.ID} className="flex justify-center">
            <Product product={product} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default StatisticsContainer;
