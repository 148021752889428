import CustomerProductReviews from "@src/mocks/CustomerProductReviews.json";
import ProductReview from "@src/pages/marketplace/components/ProductReview";
import { t } from "i18next";

const ProductReviews = () => {
  const reviews = CustomerProductReviews.reviews;
  return (
    <>
      <div className="w-full">
        <div className="text-base font-bold">{t("main.shop.details.customerReviews")}:</div>
        <div className="flex flex-col gap-4 pl-4">
          <div className="flow-root">
            <ul className="list-none divide-y divide-gray-200 dark:divide-gray-700">
              {reviews.map(review => (
                <div key={review.review_id}>
                  <ProductReview review={review} />
                </div>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductReviews;
