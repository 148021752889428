import { CheckIcon, XMarkIcon } from "@heroicons/react/24/outline";
import Button from "@src/components/elements/input/Button";
import { useState } from "react";
import TextareaAutosize from "react-textarea-autosize";
import tw from "twin.macro";

type Props = {
  value: string;
  name?: string;
  placeholder?: string;
  onChange: (newValue: string) => void;
};

const FormTextarea = ({ value, onChange, name, placeholder }: Props) => {
  const [oldValue, setOldValue] = useState("");

  return (
    <div className="flex flex-col gap-2">
      <div>
        <TextareaAutosize
          minRows={3}
          name={name}
          value={value}
          onChange={e => onChange(e.target.value)}
          placeholder={placeholder}
          maxLength={250}
          css={[tw`block w-full rounded-xl py-3 px-4 bg-gray-200 border-0`]}
        />
      </div>
      {value.localeCompare(oldValue) != 0 && (
        <div className="flex flex-row gap-2">
          <Button
            onClick={() => {
              onChange(oldValue);
            }}
            variant={Button.Variants.Transparent}
          >
            <XMarkIcon className="size-4 text-red-700" />
          </Button>
          <Button
            onClick={() => {
              setOldValue(value);
            }}
            variant={Button.Variants.Transparent}
          >
            <CheckIcon className="size-4 text-green-700" />
          </Button>
        </div>
      )}
    </div>
  );
};

export default FormTextarea;
