import api, { requestParams, RequestWithParams } from "@src/api/api";
import { JobType } from "@src/utils/types/JobType";

export type JobsParams = {
  page?: number;
  q?: string;
  filter?: string;
  user_id?: string;
};

export type JobsRequest = RequestWithParams<JobsParams>;

export type JobsResponse = {
  data: JobType[];
  current_page: number;
  total_pages: number;
};

export default async function jobs(request: JobsRequest): Promise<JobsResponse> {
  const { data } = await api.get(`/api/v1/jobs${requestParams({ page: 0, ...request.params })}`);
  return data;
}
