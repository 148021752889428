import BackButton from "@src/components/nav/BackButton";
import { useTranslation } from "react-i18next";

const ActGlobal = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className="pl-4 pt-4">
        <BackButton stepback desktopVisible />
      </div>
      <div className="grid grid-flow-row gap-6 pb-4 text-justify text-xs text-gray-700">
        <section className="relative">
          <img src="/images/info/global_header.png" className="h-[300px] w-full object-cover" />
          <div className="absolute left-6 top-1/2 flex -translate-y-1/2 flex-col font-semibold italic">
            <div className="w-fit bg-white/80 px-1 text-2xl text-darkcyan">
              {t("pages.actGlobal.global")}
            </div>
            <div className="ml-4 w-fit bg-darkblue/80 px-1 text-white">
              {t("pages.actGlobal.countryboarder")}
            </div>
          </div>
        </section>

        <article className="grid grid-flow-row gap-3 text-xs max-xl:px-4">
          <h1 className="text-left text-smbase font-bold">{t("pages.actGlobal.text1")}</h1>
          <div className="grid grid-flow-row gap-1">
            <h2 className="font-semibold">{t("pages.actGlobal.text2")}</h2>
            <div>{t("pages.actGlobal.text3")}</div>
          </div>
          <div className="grid grid-flow-row gap-1">
            <h2 className="font-semibold">{t("pages.actGlobal.text4")}</h2>
            <ul className="list-inside list-disc">
              <li>{t("pages.actGlobal.list1")}</li>
              <li>{t("pages.actGlobal.list2")}</li>
              <li>{t("pages.actGlobal.list3")}</li>
              <li>{t("pages.actGlobal.list4")}</li>
            </ul>
          </div>
          <div className="grid grid-flow-row gap-1">
            <h2 className="font-semibold">{t("pages.actGlobal.text5")}</h2>
            <ul className="list-inside list-disc">
              <li>{t("pages.actGlobal.list5")}</li>
              <li>{t("pages.actGlobal.list6")}</li>
              <li>{t("pages.actGlobal.list7")}</li>
              <li>{t("pages.actGlobal.list8")}</li>
            </ul>
          </div>
          <div className="grid grid-flow-row gap-1">
            <h2 className="font-semibold">{t("pages.actGlobal.text6")}</h2>
            <div>{t("pages.actGlobal.text7")}</div>
          </div>
          <div className="grid grid-flow-row gap-1">
            <h2 className="font-semibold">{t("pages.actGlobal.text7")}</h2>
            <div>{t("pages.actGlobal.text9")}</div>
          </div>
          <div className="grid grid-flow-row gap-1">
            <h2 className="font-semibold">{t("pages.actGlobal.text10")}</h2>
          </div>
          <h1 className="text-left text-xs font-bold">{t("pages.actGlobal.text11")}</h1>
        </article>
      </div>
    </>
  );
};

export default ActGlobal;
