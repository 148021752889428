import BackButton from "@src/components/nav/BackButton";
import { useTranslation } from "react-i18next";

const CompanyFAQ = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className="pl-4 pt-4">
        <BackButton stepback desktopVisible />
      </div>
      <div className="grid grid-flow-row gap-6 text-gray-700">
        <section className="relative">
          <img
            src="/images/info/projekt-auftragsvergabe-header-5.jpg"
            className="h-[300px] w-full object-cover"
          />
          <div className="absolute left-6 top-1/2 flex -translate-y-1/2 flex-col font-semibold italic">
            <div className="w-fit bg-white/80 px-1 text-2xl text-darkcyan">
              {t("pages.companyFAQ.title")}
            </div>
            <div className="ml-4 w-fit bg-darkblue/80 px-1 text-white">
              {t("pages.companyFAQ.tradefoox")}
            </div>
          </div>
        </section>

        <article className="pb-4 text-justify text-xs max-xl:px-4">
          <h1 className="text-left text-smbase font-bold">{t("pages.companyFAQ.text1")}</h1>
          <div className="grid grid-flow-row gap-3">
            <div className="grid grid-flow-row gap-3">
              <h2 className="font-semibold">{t("pages.companyFAQ.text2")}</h2>
              {t("pages.companyFAQ.text3")}
            </div>
            <div className="grid grid-flow-row gap-1">
              <h2 className="font-semibold">{t("pages.companyFAQ.text4")}</h2>
              {t("pages.companyFAQ.text5")}
            </div>
            <div className="grid grid-flow-row gap-1">
              <h2 className="font-semibold">{t("pages.companyFAQ.text6")}</h2>
              {t("pages.companyFAQ.text7")}
              <br />
              {t("pages.companyFAQ.text8")}
            </div>
            <div className="grid grid-flow-row gap-1">
              <h2 className="font-semibold">{t("pages.companyFAQ.text9")}</h2>
              {t("pages.companyFAQ.text10")}
            </div>
            <div className="grid grid-flow-row gap-1">
              <h2 className="font-semibold">{t("pages.companyFAQ.text11")}</h2>
              {t("pages.companyFAQ.text12")}
              <br />
              {t("pages.companyFAQ.text13")}
            </div>
            <div className="grid grid-flow-row gap-1">
              <h2 className="font-semibold">{t("pages.companyFAQ.text14")}</h2>
              {t("pages.companyFAQ.text15")}
            </div>
            <div className="grid grid-flow-row gap-1">
              <h2 className="font-semibold">{t("pages.companyFAQ.text16")}</h2>
              {t("pages.companyFAQ.text17")}
              <br />
              {t("pages.companyFAQ.text18")}
              <br />
              {t("pages.companyFAQ.text19")}
              <br />
              {t("pages.companyFAQ.text20")}
              <br />
              {t("pages.companyFAQ.text21")}
              <br />
              {t("pages.companyFAQ.text22")}
            </div>
            <div className="grid grid-flow-row gap-1">
              <h2 className="font-semibold">{t("pages.companyFAQ.text23")}</h2>
              {t("pages.companyFAQ.text24")}
            </div>
            <div className="grid grid-flow-row gap-1">
              <h2 className="font-semibold">{t("pages.companyFAQ.text25")}</h2>
              {t("pages.companyFAQ.text26")}
            </div>
            <div className="grid grid-flow-row gap-1">
              <h2 className="font-semibold">{t("pages.companyFAQ.text27")}</h2>
              {t("pages.companyFAQ.text28")}
            </div>
            <div className="grid grid-flow-row gap-1">
              <h2 className="font-semibold">{t("pages.companyFAQ.text29")}</h2>
              {t("pages.companyFAQ.text30")}
            </div>
            <div className="grid grid-flow-row gap-1">
              <h2 className="font-semibold">{t("pages.companyFAQ.text31")}</h2>
              {t("pages.companyFAQ.text32")}
            </div>
          </div>
        </article>
      </div>
    </>
  );
};

export default CompanyFAQ;
