import {
  ArrowPathRoundedSquareIcon,
  PauseIcon,
  PlayIcon,
  SpeakerWaveIcon,
  SpeakerXMarkIcon,
} from "@heroicons/react/24/outline";
import {
  ChatBubbleOvalLeftIcon,
  HeartIcon,
  MusicalNoteIcon,
  PlayIcon as PlayIconFull,
  PlusIcon,
} from "@heroicons/react/24/solid";
import like from "@src/api/posts/like";
import unlike from "@src/api/posts/unlike";
import view from "@src/api/posts/view";
import ProfileAvatar from "@src/components/elements/shared/ProfileAvatar";
import TextView from "@src/components/elements/textedit/TextView";
import CommentsPopup from "@src/components/popup/CommentsPopup";
import SharePopup from "@src/components/popup/SharePopup";
import WatchDropdown from "@src/pages/watch/WatchDropdown";
import WatchBookmarkButton from "@src/pages/watch/actions/WatchBookmarkButton";
import { useAppSelector } from "@src/state/hooks";
import useLoginModal from "@src/state/modal/useLoginModal";
import { WatchType } from "@src/utils/types/WatchType";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import ReactPlayer from "react-player";
import { NavLink, useNavigate } from "react-router-dom";
import tw from "twin.macro";

type Props = {
  data: WatchType;
  active: boolean;
  preload: boolean;
  autoplay?: boolean;
  key?: string;
  muted?: boolean;
  setMuted?: (muted: boolean) => void;
  queryKey?: (string | null)[];
};

const Watch = ({
  data,
  active,
  autoplay,
  muted = true,
  setMuted,
  preload,
  queryKey = ["watch", "posts"],
}: Props) => {
  const queryClient = useQueryClient();
  const [viewed, setViewed] = useState(false);
  const ref = useRef<ReactPlayer | null>(null);
  const [playing, setPlaying] = useState(autoplay);
  const [preventPause, setPreventPause] = useState(false);
  const [timeRatio, setTimeRatio] = useState(0);
  const { user } = useAppSelector(state => state.user);
  const loginModal = useLoginModal();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const likeMutation = useMutation({
    mutationKey: [`like-${data.post_id}`],
    mutationFn: async () => {
      const liked = data.liked;

      await queryClient.cancelQueries({ queryKey });
      const previousData = queryClient.getQueryData<{
        pages: Array<{ data: WatchType[] }>;
      }>(queryKey);

      if (previousData) {
        for (const page of previousData.pages) {
          for (const post of page.data) {
            if (post.post_id !== data.post_id) continue;

            if (data.liked) {
              post.like_count -= 1;
            } else {
              post.like_count += 1;
            }
            post.liked = !liked;
          }
        }
        queryClient.setQueryData(queryKey, previousData);
      }

      if (liked) {
        return await unlike({ id: data.post_id });
      }

      return await like({ id: data.post_id });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["posts", "watch"] });
    },
    onError: () => {
      alert("Failed");
    },
  });

  useEffect(() => {
    if (active && !viewed) {
      setViewed(true);
      view({ id: data.post_id });
    }
  }, [active, data.post_id, viewed]);
  const [sharePopupOpen, setSharePopupOpen] = useState(false);
  const [commentsPopupOpen, setCommentsPopupOpen] = useState(false);
  const [sharePopupMobile, setSharePopupMobile] = useState(false);
  const [likeEffect, setLikeEffect] = useState(false);

  useEffect(() => {
    if (ref.current && active) {
      ref.current?.getInternalPlayer()?.play();
    }
    if (ref.current && !active) {
      ref.current?.getInternalPlayer()?.pause();
    }
  }, [active]);

  const [videoHeight, setVideoHeight] = useState(0);
  const [videoWidth, setVideoWidth] = useState(0);

  useEffect(() => {
    if (ref.current) {
      setVideoHeight(ref.current.getInternalPlayer()?.videoHeight || 0);
      setVideoWidth(ref.current.getInternalPlayer()?.videoWidth || 0);
    }
  }, [videoHeight, videoWidth]);

  const checkAuth = () => {
    if (!user) {
      loginModal.open();
      return false;
    }
    return true;
  };

  return (
    <div className="group relative snap-start">
      {/* Overlay */}
      <div className="absolute inset-0 cursor-pointer">
        {/* Footer */}
        <div className="pointer-events-none absolute bottom-[70px] left-0 right-[70px] z-10 block md:bottom-[70px] lg:left-3">
          <div className="pointer-events-auto flex flex-row items-center gap-2 overflow-x-hidden rounded-xl py-1 ps-2 text-sm text-white scrollbar-none">
            <div className="pointer-events-auto mb-4 scale-[80%]">
              <ProfileAvatar
                user={{
                  id: data.author_id,
                  name: data.author_name,
                  type: data.author_type,
                  avatar: data.author_avatar,
                }}
                nameStyle={tw`hidden`}
              />
            </div>

            <div className="flex w-full flex-col">
              <NavLink
                to={`/profile/${data.author_id}/watch`}
                className="pointer-events-auto w-fit hover:font-semibold"
              >
                @{data.author_name}
              </NavLink>
              <div className="line-clamp-2 overflow-x-scroll leading-4 scrollbar-none">
                <TextView value={data.post_text} hashtagType="watch" />
              </div>
              <div className="flex flex-row">
                <MusicalNoteIcon className="size-5 shrink-0" />
                <div className="w-full overflow-hidden">
                  <div className="animate-marquee w-full"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Sidebar */}
        <div className="pointer-events-none absolute bottom-[40px] right-[10px] z-20 w-[50px] rounded-xl text-xs text-gray-200 md:bottom-[30px]">
          <div className="flex flex-col items-center gap-3">
            <button
              css={[tw`flex flex-col items-center pointer-events-auto`]}
              onClick={() => checkAuth() && likeMutation.mutate()}
            >
              {data.liked ? (
                <HeartIcon className={`size-8 text-red-600 ${likeEffect && "animate-ping-once"}`} />
              ) : (
                <HeartIcon
                  className="size-8"
                  onClick={() => {
                    setLikeEffect(true);
                  }}
                  onAnimationEnd={() => {
                    setLikeEffect(false);
                  }}
                />
              )}
              <div>{data.like_count}</div>
            </button>
            <div className="pointer-events-auto flex flex-col items-center">
              <button
                className="flex flex-col items-center gap-0"
                onClick={e => {
                  e.stopPropagation();
                }}
              >
                <ChatBubbleOvalLeftIcon
                  className="size-8"
                  onClick={() => {
                    setCommentsPopupOpen(!commentsPopupOpen);
                  }}
                />
              </button>
              <div>{data.comment_count}</div>
            </div>
            <div className="pointer-events-auto flex flex-col items-center">
              <WatchBookmarkButton postId={data.post_id} bookmarked={data.bookmarked} />
              <div>{data.bookmark_count}</div>
            </div>
            <div
              className="pointer-events-auto flex flex-col items-center"
              onClick={e => {
                e.stopPropagation();
              }}
            >
              <PlayIconFull className="size-8" />
              <div>{data.view_count}</div>
            </div>
            <button
              className="pointer-events-auto relative flex cursor-pointer flex-col items-center"
              onClick={e => {
                e.stopPropagation();
                checkAuth() && setSharePopupOpen(true);
              }}
            >
              <ArrowPathRoundedSquareIcon className="size-8" />
              <div>0</div>
              <button
                className="absolute left-0 top-0 size-full lg:hidden"
                onClick={() => {
                  setSharePopupMobile(true);
                }}
              />
            </button>

            <div className="pointer-events-auto text-black">
              <WatchDropdown
                isRepost={data.is_reposted}
                postId={data.post_id}
                videoUrl={data.media_url}
                authorId={data.author_id}
                bookmarked={data.bookmarked}
              />
            </div>
            <button
              className="pointer-events-auto mr-12 mt-10 flex items-center gap-2.5 rounded-xl border bg-gray-200 px-2.5 py-1 text-sm text-black"
              onClick={() => (user ? navigate("/watch/create") : loginModal.open())}
            >
              <PlusIcon className="size-4 text-black" />{" "}
              {t("main.watchTabs.create.buttons.createButton")}
            </button>
          </div>
        </div>
        <div className="absolute bottom-[10px] z-10 mx-[10px] w-[calc(100%-20px)]">
          <input
            type="range"
            min={0}
            max={100}
            value={timeRatio}
            onChange={e => {
              setPreventPause(true);
              setTimeout(() => {
                setPreventPause(false);
              }, 400);
              if (!ref.current) return;
              ref.current.seekTo(
                (ref.current?.getDuration() * parseFloat(e.currentTarget.value)) / 100,
              );
              setTimeRatio(parseInt(e.currentTarget.value));
            }}
            defaultValue={0}
            className="pointer-events-auto h-0.5 w-full cursor-pointer accent-black opacity-0 brightness-[65%] invert duration-200 group-hover:opacity-100"
          />
        </div>
        <div className="absolute bottom-[30px] z-10 flex w-full flex-row-reverse justify-between rounded-xl px-3 pb-2 text-gray-200 lg:px-6 lg:pb-4">
          <button
            className="pointer-events-auto invisible opacity-0 duration-200 group-hover:opacity-100"
            onClick={() => {
              if (!ref.current) return;
              ref.current?.getInternalPlayer().paused
                ? ref.current?.getInternalPlayer().play()
                : ref.current?.getInternalPlayer().pause();
            }}
          >
            {playing ? <PauseIcon className="size-8 " /> : <PlayIcon className="size-8 " />}
          </button>
          <button
            className="py-0.3 pointer-events-auto rounded-full bg-slate-500/60 px-5 duration-200"
            onClick={e => {
              setMuted && setMuted(!muted);
              e.stopPropagation();
            }}
          >
            {muted ? (
              <SpeakerXMarkIcon className="h-7 w-6 " />
            ) : (
              <SpeakerWaveIcon className="h-7 w-6 " />
            )}
          </button>
        </div>
      </div>
      <div
        css={[
          tw`bg-neutral-900 h-[calc(100dvh-40px)] md:h-[calc(100vh-200px)] xl:h-[calc(100vh-180px)]`,
          tw`md:rounded-lg overflow-clip`,
        ]}
      >
        <div
          className="absolute top-1/2 size-full -translate-y-1/2 cursor-pointer"
          onClick={() => {
            if (!ref.current || preventPause) return;
            ref.current?.getInternalPlayer().paused
              ? ref.current?.getInternalPlayer().play()
              : ref.current?.getInternalPlayer().pause();
          }}
        >
          <div
            className={`h-full md:rounded-lg lg:overflow-hidden ${
              videoHeight > videoWidth ? "video-cover" : ""
            }`}
          >
            {preload && (
              <ReactPlayer
                url={`${data.media_url}/playlist.m3u8`}
                height="100%"
                width="100%"
                controls={false}
                playing={active}
                muted={muted}
                autoplay={true}
                loop={true}
                preload={preload}
                responsive
                ref={ref}
                onPlaying={() => setPlaying(true)}
                onPlay={() => setPlaying(true)}
                onPause={() => setPlaying(false)}
                onReady={() => {
                  setVideoHeight(ref.current?.getInternalPlayer().videoHeight);
                  setVideoWidth(ref.current?.getInternalPlayer().videoWidth);
                }}
                onTimeUpdate={() => {
                  if (!ref.current) return;
                  setTimeRatio(
                    (ref.current.getInternalPlayer().currentTime /
                      ref.current.getInternalPlayer().duration) *
                      100,
                  );
                }}
              />
            )}
          </div>
        </div>
      </div>
      {sharePopupOpen && (
        <SharePopup
          postId={data.post_id}
          isMobile={sharePopupMobile}
          content={{
            title: "Watch",
            body: "https://www.tradefoox.com/watch/" + data.post_id,
          }}
          onClose={() => setSharePopupOpen(false)}
        />
      )}
      {commentsPopupOpen && (
        <CommentsPopup
          onClose={() => setCommentsPopupOpen(false)}
          comment_count={data.comment_count}
          postId={data.post_id}
        />
      )}
    </div>
  );
};

export default Watch;
