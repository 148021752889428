import {
  BriefcaseIcon,
  BuildingStorefrontIcon,
  EnvelopeIcon,
  TagIcon,
  UserGroupIcon,
  UsersIcon,
  VideoCameraIcon,
} from "@heroicons/react/24/outline";
import VerifyBadge from "@src/components/elements/VerifyBadge";
import { TFunction } from "i18next";
import tw from "twin.macro";

type ProfileEditConfigType = {
  [key in "company" | "person" | "club"]: any;
};

const ProfileEditConfig = (t: TFunction): ProfileEditConfigType => ({
  person: {
    common: [
      {
        name: "firstname",
        label: t("main.profileViewTabs.editProfile.form.firstName"),
        type: "input",
      },
      {
        name: "lastname",
        label: t("main.profileViewTabs.editProfile.form.lastName"),
        type: "input",
      },
      {
        name: "website",
        label: t("main.profileViewTabs.editProfile.form.website"),
        type: "input",
      },
      {
        name: "country",
        label: t("main.profileViewTabs.editProfile.form.country"),
        type: "input",
      },
      {
        name: "zip_code",
        label: t("main.profileViewTabs.editProfile.form.zipCode"),
        type: "input",
      },
      {
        name: "relationship",
        label: t("main.profileViewTabs.editProfile.form.relationship"),
        type: "select",
        items: [
          {
            name: t("main.profileViewTabs.editProfile.form.genders.not_specified"),
            value: "not_specified",
          },
          {
            name: t("main.profileViewTabs.editProfile.form.relationshipstatus.single"),
            value: "single",
          },
          {
            name: t("main.profileViewTabs.editProfile.form.relationshipstatus.engaged"),
            value: "engaged",
          },
          {
            name: t("main.profileViewTabs.editProfile.form.relationshipstatus.married"),
            value: "married",
          },
          {
            name: t("main.profileViewTabs.editProfile.form.relationshipstatus.widow"),
            value: "widow",
          },
          {
            name: t("main.profileViewTabs.editProfile.form.relationshipstatus.custom"),
            value: "custom",
          },
        ],
      },
      {
        label: t("main.profileViewTabs.editProfile.form.userName"),
        type: "username",
        name: "normalised_account_name",
        disabled: false,
        pattern: "^[A-Za-z0-9_]{0,15}$",
        replacement: [{ regex: / /g, newString: "_" }],
        maxLength: 15,
        title:
          "Only letters (A-Z), numbers (0-9), and underscores are allowed. Maximum 15 characters. No spaces.",
      },
      {
        name: "gender",
        label: t("main.profileViewTabs.editProfile.form.gender"),
        type: "select",
        items: [
          {
            name: t("main.profileViewTabs.editProfile.form.genders.not_specified"),
            value: "not_specified",
          },
          {
            name: t("main.profileViewTabs.editProfile.form.genders.male"),
            value: "male",
          },
          {
            name: t("main.profileViewTabs.editProfile.form.genders.female"),
            value: "female",
          },
          {
            name: t("main.profileViewTabs.editProfile.form.genders.diverse"),
            value: "diverse",
          },
        ],
      },
      {
        name: "birthday",
        label: t("main.profileViewTabs.editProfile.form.birthday"),
        type: "date",
      },
      {
        name: "public_birthday",
        label: t("main.profileViewTabs.editProfile.form.public_birthday"),
        type: "checkbox",
      },
      {
        name: "phone_number",
        label: t("main.profileViewTabs.editProfile.form.phone"),
        type: "input",
      },
      {
        name: "public_phone",
        label: t("main.profileViewTabs.editProfile.form.public_phone"),
        type: "checkbox",
      },
      {
        name: "email",
        label: t("main.profileViewTabs.editProfile.form.email"),
        type: "input",
        disabled: true,
      },
      {
        name: "public_email",
        label: t("main.profileViewTabs.editProfile.form.public_email"),
        type: "checkbox",
      },
      {
        name: "current_employer",
        label: t("main.profileViewTabs.editProfile.form.currentEmployer"),
        type: "usersearch",
        filter: ["company", "club"],
      },
      {
        name: "job_position",
        label: t("main.profileViewTabs.editProfile.form.position"),
        type: "input",
      },
      {
        name: "type",
        label: "Kontotyp",
        type: "customselect",
        disabled: false,
        items: [
          {
            name: t("main.profileViewTabs.editProfile.form.accountTypes.company"),
            value: "company",
            icon: <VerifyBadge customStyle={[tw`my-0.5`]} h={tw`h-4`} w={tw`w-4`} type="company" />,
          },
          {
            name: t("main.profileViewTabs.editProfile.form.accountTypes.club"),
            value: "club",
            icon: <VerifyBadge customStyle={[tw`my-0.5`]} h={tw`h-4`} w={tw`w-4`} type="club" />,
          },
          {
            name: t("main.profileViewTabs.editProfile.form.accountTypes.person"),
            value: "person",
            icon: <VerifyBadge customStyle={[tw`my-0.5`]} h={tw`h-4`} w={tw`w-4`} type="person" />,
          },
        ],
      },
      {
        name: "start_page",
        label: "Startseite",
        type: "customselect",
        items: [
          {
            name: t("main.profileViewTabs.editProfile.form.startPage.news"),
            value: "news",
            icon: <BriefcaseIcon className="size-4" />,
          },
          {
            name: t("main.profileViewTabs.editProfile.form.startPage.watch"),
            value: "watch",
            icon: <VideoCameraIcon className="size-4" />,
          },
          {
            name: t("main.profileViewTabs.editProfile.form.startPage.inserate"),
            value: "inserate",
            icon: <TagIcon className="size-4" />,
          },
          {
            name: t("main.profileViewTabs.editProfile.form.startPage.messenger"),
            value: "messenger",
            icon: <EnvelopeIcon className="size-4" />,
          },
          {
            name: t("main.profileViewTabs.editProfile.form.startPage.kontakte"),
            value: "contacts",
            icon: <UsersIcon className="size-4" />,
          },
          {
            name: t("main.profileViewTabs.editProfile.form.startPage.gruppen"),
            value: "groups/contacts",
            icon: <UserGroupIcon className="size-4" />,
          },
          {
            name: t("main.profileViewTabs.editProfile.form.startPage.jobs"),
            value: "jobs",
            icon: <BriefcaseIcon className="size-4" />,
          },
          {
            name: t("main.profileViewTabs.editProfile.form.startPage.marketplace"),
            value: "marketplace",
            icon: <BuildingStorefrontIcon className="size-4" />,
          },
        ],
      },
      {
        name: "startsite",
        label: t("main.profileViewTabs.editProfile.form.homeSite"),
        type: "select",
      },
      {
        name: "profileimage",
        label: t("main.profileViewTabs.editProfile.form.uploadProfilePicture"),
        type: "image",
      },
      {
        name: "headerimage",
        label: t("main.profileViewTabs.editProfile.form.uploadHeaderImage"),
        type: "image",
      },
    ],
    more_information: [
      {
        name: "description",
        label: t("main.profileViewTabs.editProfile.form.moreInformation.description", {
          maximum: "1000",
        }),
        type: "textarea",
        maxLength: 1000,
        cols: 2,
      },
      {
        name: "short_description",
        label: t("main.profileViewTabs.editProfile.form.moreInformation.shortDescription", {
          maximum: "150",
        }),
        maxLength: 150,
        type: "input",
        cols: 2,
      },
      {
        name: "offer",
        label: t("main.profileViewTabs.editProfile.form.moreInformation.iOffer"),
        type: "input",
        cols: 2,
      },
      {
        name: "clubs",
        label: t("main.profileViewTabs.editProfile.form.moreInformation.organizationsAssociations"),
        type: "iteminput",
        cols: 2,
        filter: ["company", "club"],
      },
      {
        name: "looking_for",
        label: t("main.profileViewTabs.editProfile.form.moreInformation.search"),
        type: "input",
        cols: 2,
      },
      {
        name: "interests",
        label: t("main.profileViewTabs.editProfile.form.moreInformation.interests"),
        type: "input",
        cols: 2,
      },
      {
        name: "languages",
        label: t("main.profileViewTabs.editProfile.form.moreInformation.languages"),
        type: "input",
        cols: 2,
      },
      {
        name: "personal",
        label: t("main.profileViewTabs.editProfile.form.moreInformation.personal"),
        type: "input",
        cols: 2,
      },
      {
        name: "qualifications",
        label: t("main.profileViewTabs.editProfile.form.moreInformation.qualifications"),
        type: "input",
        cols: 2,
      },
      {
        name: "awards",
        label: t("main.profileViewTabs.editProfile.form.moreInformation.awards"),
        type: "input",
        cols: 2,
      },
    ],
    cv: [
      {
        name: "werdegang",
        label: t("main.profileViewTabs.editProfile.form.careerInfo.career"),
        type: "cv",
      },
      {
        name: "ausbildung",
        label: t("main.profileViewTabs.editProfile.form.careerInfo.training"),
        type: "cv",
      },
      {
        name: "publish_career",
        label: t("main.profileViewTabs.editProfile.form.careerInfo.publishCareer"),
        type: "checkbox",
      },
    ],
  },
  club: {
    common: [
      {
        name: "name",
        label: t("main.profileViewTabs.editProfile.form.clubname"),
        type: "input",
      },
      {
        label: t("main.profileViewTabs.editProfile.form.username"),
        type: "username",
        name: "normalised_account_name",
        disabled: false,
        pattern: "^[A-Za-z0-9_]{0,15}$",
        replacement: [{ regex: / /g, newString: "_" }],
        maxLength: 15,
        title:
          "Only letters (A-Z), numbers (0-9), and underscores are allowed. Maximum 15 characters. No spaces.",
      },
      {
        name: "legal_type",
        label: t("main.profileViewTabs.editProfile.form.legalForm"),
        type: "input",
      },
      {
        name: "members",
        label: t("main.profileViewTabs.editProfile.form.employees"),
        type: "number",
      },
      {
        name: "tax_nr",
        label: t("main.profileViewTabs.editProfile.form.vatID"),
        type: "input",
      },
      {
        name: "club_register_nr",
        label: t("main.profileViewTabs.editProfile.form.clubID"),
        type: "input",
      },
      {
        name: "country",
        label: t("main.profileViewTabs.editProfile.form.country"),
        type: "input",
      },
      {
        name: "zip_code",
        label: t("main.profileViewTabs.editProfile.form.zipCode"),
        type: "input",
      },
      {
        name: "street",
        label: t("main.profileViewTabs.editProfile.form.street"),
        type: "input",
      },
      {
        name: "phone_number",
        label: t("main.profileViewTabs.editProfile.form.phone"),
        type: "input",
      },
      {
        name: "public_phone",
        label: t("main.profileViewTabs.editProfile.form.public_phone"),
        type: "checkbox",
      },
      {
        name: "yearly_revenue",
        label: t("main.profileViewTabs.editProfile.form.annualsales"),
        type: "number",
      },
      {
        name: "website",
        label: t("main.profileViewTabs.editProfile.form.website"),
        type: "input",
      },
      {
        name: "email",
        label: t("main.profileViewTabs.editProfile.form.email"),
        type: "input",
      },
      {
        name: "public_email",
        label: t("main.profileViewTabs.editProfile.form.public_email"),
        type: "checkbox",
      },
      {
        name: "type",
        label: "Kontotyp",
        type: "customselect",
        disabled: false,
        items: [
          {
            name: t("main.profileViewTabs.editProfile.form.accountTypes.company"),
            value: "company",
            icon: <VerifyBadge customStyle={[tw`my-0.5`]} h={tw`h-4`} w={tw`w-4`} type="company" />,
          },
          {
            name: t("main.profileViewTabs.editProfile.form.accountTypes.club"),
            value: "club",
            icon: <VerifyBadge customStyle={[tw`my-0.5`]} h={tw`h-4`} w={tw`w-4`} type="club" />,
          },
          {
            name: t("main.profileViewTabs.editProfile.form.accountTypes.person"),
            value: "person",
            icon: <VerifyBadge customStyle={[tw`my-0.5`]} h={tw`h-4`} w={tw`w-4`} type="person" />,
          },
        ],
      },
      {
        name: "start_page",
        label: "Startseite",
        type: "select",
        items: [
          {
            name: t("main.profileViewTabs.editProfile.form.startPage.news"),
            value: "news",
          },
          {
            name: t("main.profileViewTabs.editProfile.form.startPage.watch"),
            value: "watch",
          },
          {
            name: t("main.profileViewTabs.editProfile.form.startPage.inserate"),
            value: "inserate",
          },
          {
            name: t("main.profileViewTabs.editProfile.form.startPage.messenger"),
            value: "messenger",
          },
          {
            name: t("main.profileViewTabs.editProfile.form.startPage.kontakte"),
            value: "contacts",
          },
          {
            name: t("main.profileViewTabs.editProfile.form.startPage.gruppen"),
            value: "groups/contacts",
          },
          {
            name: t("main.profileViewTabs.editProfile.form.startPage.jobs"),
            value: "jobs",
          },
          {
            name: t("main.profileViewTabs.editProfile.form.startPage.marketplace"),
            value: "marketplace",
          },
        ],
      },
      {
        name: "profileimage",
        label: t("main.profileViewTabs.editProfile.form.uploadProfilePicture"),
        type: "image",
      },
      {
        name: "headerimage",
        label: t("main.profileViewTabs.editProfile.form.uploadHeaderImage"),
        type: "image",
      },
    ],
    description: [
      {
        name: "description",
        label: t("main.profileViewTabs.editProfile.form.moreInformation.description", {
          maximum: "1000",
        }),
        type: "textarea",
        maxLength: 1000,
        cols: 2,
      },
      {
        name: "short_description",
        label: t("main.profileViewTabs.editProfile.form.moreInformation.shortDescription", {
          maximum: "150",
        }),
        type: "input",
        cols: 2,
      },
      {
        name: "specialization",
        label: t("main.profileViewTabs.editProfile.form.moreInformation.speciality", {
          maximum: "200",
        }),
        type: "input",
        cols: 2,
      },
      {
        name: "industries",
        label: t("main.profileViewTabs.editProfile.form.moreInformation.industry", {
          maximum: "150",
        }),
        type: "input",
        cols: 2,
      },
      {
        name: "keywords",
        label: t("main.profileViewTabs.editProfile.form.moreInformation.keywords", {
          maximum: "150",
        }),
        type: "input",
        cols: 2,
      },
      {
        name: "legal_notice",
        label: t("main.profileViewTabs.editProfile.form.moreInformation.imprint"),
        type: "legal",
        cols: 2,
        maxLength: 1000,
      },
      {
        name: "terms",
        label: t("main.profileViewTabs.editProfile.form.moreInformation.termsCondition"),
        type: "legal",
        cols: 2,
        maxLength: 2000,
      },
    ],
    contact_person: [
      {
        name: "contactseditor",
        label: t("main.profileViewTabs.editProfile.form.contact.person"),
        type: "contacts",
      },
      {
        name: "contactseditor",
        label: t("main.profileViewTabs.editProfile.form.contact.admin"),
        type: "contacts",
      },
    ],
    cv: [
      {
        name: "werdegang",
        label: t("main.profileViewTabs.editProfile.form.careerInfo.career"),
        type: "cv",
      },
      {
        name: "ausbildung",
        label: t("main.profileViewTabs.editProfile.form.careerInfo.training"),
        type: "cv",
      },
    ],
  },
  company: {
    common: [
      {
        name: "name",
        label: t("main.profileViewTabs.editProfile.form.companyName"),
        type: "input",
      },
      {
        label: t("main.profileViewTabs.editProfile.form.username"),
        type: "username",
        name: "normalised_account_name",
        disabled: false,
        pattern: "^[A-Za-z0-9_]{0,15}$",
        replacement: [{ regex: / /g, newString: "_" }],
        maxLength: 15,
        title:
          "Only letters (A-Z), numbers (0-9), and underscores are allowed. Maximum 15 characters. No spaces.",
      },
      {
        name: "legal_type",
        label: t("main.profileViewTabs.editProfile.form.legalForm"),
        type: "input",
      },
      {
        name: "employees",
        label: t("main.profileViewTabs.editProfile.form.employees"),
        type: "number",
      },
      {
        name: "company_registration_number",
        label: t("main.profileViewTabs.editProfile.form.companyRegistration"),
        type: "input",
      },
      {
        name: "tax_id",
        label: t("main.profileViewTabs.editProfile.form.vatID"),
        type: "input",
      },
      {
        name: "country",
        label: t("main.profileViewTabs.editProfile.form.country"),
        type: "input",
      },
      {
        name: "zip_code",
        label: t("main.profileViewTabs.editProfile.form.zipCode"),
        type: "input",
      },
      {
        name: "street",
        label: t("main.profileViewTabs.editProfile.form.street"),
        type: "input",
      },
      {
        name: "phone_number",
        label: t("main.profileViewTabs.editProfile.form.phone"),
        type: "input",
      },
      {
        name: "public_phone",
        label: t("main.profileViewTabs.editProfile.form.public_phone"),
        type: "checkbox",
      },
      {
        name: "annual_sales",
        label: t("main.profileViewTabs.editProfile.form.annualsales"),
        type: "input",
      },
      {
        name: "website",
        label: t("main.profileViewTabs.editProfile.form.website"),
        type: "input",
      },
      {
        name: "email",
        label: t("main.profileViewTabs.editProfile.form.email"),
        type: "input",
        disabled: true,
      },
      {
        name: "public_email",
        label: t("main.profileViewTabs.editProfile.form.public_email"),
        type: "checkbox",
      },
      {
        name: "duns_number",
        label: t("main.profileViewTabs.editProfile.form.dunsNumber"),
        type: "input",
      },
      {
        name: "duns_text",
        label: t("main.profileViewTabs.editProfile.form.dunsHelp"),
        link: "http://www.dnb.com/de-de/produkte-services/dun-bradstreet/dnb-duns-nummer/",
        type: "link",
        cols: 2,
      },
      {
        name: "type",
        label: "Kontotyp",
        type: "customselect",
        disabled: false,
        items: [
          {
            name: t("main.profileViewTabs.editProfile.form.accountTypes.company"),
            value: "company",
            icon: <VerifyBadge customStyle={[tw`my-0.5`]} h={tw`h-4`} w={tw`w-4`} type="company" />,
          },
          {
            name: t("main.profileViewTabs.editProfile.form.accountTypes.club"),
            value: "club",
            icon: <VerifyBadge customStyle={[tw`my-0.5`]} h={tw`h-4`} w={tw`w-4`} type="club" />,
          },
          {
            name: t("main.profileViewTabs.editProfile.form.accountTypes.person"),
            value: "person",
            icon: <VerifyBadge customStyle={[tw`my-0.5`]} h={tw`h-4`} w={tw`w-4`} type="person" />,
          },
        ],
      },
      {
        name: "start_page",
        label: "Startseite",
        type: "select",
        items: [
          {
            name: t("main.profileViewTabs.editProfile.form.startPage.news"),
            value: "news",
          },
          {
            name: t("main.profileViewTabs.editProfile.form.startPage.watch"),
            value: "watch",
          },
          {
            name: t("main.profileViewTabs.editProfile.form.startPage.inserate"),
            value: "inserate",
          },
          {
            name: t("main.profileViewTabs.editProfile.form.startPage.messenger"),
            value: "messenger",
          },
          {
            name: t("main.profileViewTabs.editProfile.form.startPage.kontakte"),
            value: "contacts",
          },
          {
            name: t("main.profileViewTabs.editProfile.form.startPage.gruppen"),
            value: "groups/contacts",
          },
          {
            name: t("main.profileViewTabs.editProfile.form.startPage.jobs"),
            value: "jobs",
          },
          {
            name: t("main.profileViewTabs.editProfile.form.startPage.marketplace"),
            value: "marketplace",
          },
        ],
      },
      {
        name: "profileimage",
        label: t("main.profileViewTabs.editProfile.form.uploadProfilePicture"),
        type: "image",
      },
      {
        name: "headerimage",
        label: t("main.profileViewTabs.editProfile.form.uploadHeaderImage"),
        type: "image",
      },
    ],
    description: [
      {
        name: "description",
        label: t("main.profileViewTabs.editProfile.form.moreInformation.description", {
          maximum: "1000",
        }),
        type: "textarea",
        maxLength: 1000,
        cols: 2,
      },
      {
        name: "topic",
        label: t("main.profileViewTabs.editProfile.form.moreInformation.speciality", {
          maximum: "200",
        }),
        type: "input",
        cols: 2,
      },
      {
        name: "industry",
        label: t("main.groupsInfoForm.industry", { maximum: "150" }),
        type: "input",
        cols: 2,
      },
      {
        name: "short_description",
        label: t("main.profileViewTabs.editProfile.form.moreInformation.shortDescription", {
          maximum: "150",
        }),
        type: "input",
        cols: 2,
      },
      {
        name: "keywords",
        label: t("main.profileViewTabs.editProfile.form.moreInformation.keywords", {
          maximum: "150",
        }),
        type: "input",
        cols: 2,
      },
      {
        name: "imprint",
        label: t("main.profileViewTabs.editProfile.form.moreInformation.imprint"),
        type: "legal",
        cols: 2,
        maxLength: 1000,
      },
      {
        name: "terms",
        label: t("main.profileViewTabs.editProfile.form.moreInformation.termsCondition"),
        type: "legal",
        cols: 2,
        maxLength: 2000,
      },
    ],
    contact_person: [
      {
        name: "administrators",
        label: t("main.profileViewTabs.editProfile.form.contact.person"),
        type: "contacts",
      },
      {
        name: "administrators",
        label: t("main.profileViewTabs.editProfile.form.contact.admin"),
        type: "contacts",
      },
    ],
    cv: [
      {
        name: "werdegang",
        label: t("main.profileViewTabs.editProfile.form.cv.career"),
        type: "cv",
      },
      {
        name: "ausbildung",
        label: t("main.profileViewTabs.editProfile.form.cv.training"),
        type: "cv",
      },
    ],
  },
});

export default ProfileEditConfig;
