import api, { requestParams, RequestWithId, RequestWithParams } from "@src/api/api";
import { CommentType } from "@src/utils/types/CommentType";

export type CommentsParams = {
  page?: number;
};

export type CommentsRequest = RequestWithId & RequestWithParams<CommentsParams>;

export type CommentsResponse = {
  data: CommentType[];
  current_page: number;
  total_pages: number;
};

export default async function comments(request: CommentsRequest): Promise<CommentsResponse> {
  const { data } = await api.get(
    `/api/v1/posts/${request.id}/comments${requestParams({ page: 0, ...request.params })}`,
  );
  return data;
}
