import {
  ArrowUturnRightIcon,
  ChatBubbleLeftEllipsisIcon,
  MinusCircleIcon,
} from "@heroicons/react/24/outline";
import createChat from "@src/api/chats/create";
import connect from "@src/api/users/connect";
import ContextMenu from "@src/components/elements/shared/ContextMenu";
import { showBlockUserModal } from "@src/state/blockUserModal/actions";
import { useAppSelector } from "@src/state/hooks";
import useLoginModal from "@src/state/modal/useLoginModal";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

type Props = { userId: string; type?: string };

const ContactContextMenu = ({ userId, type }: Props) => {
  const { user } = useAppSelector(state => state.user);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const loginModal = useLoginModal();
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const createChatMutation = useMutation({
    mutationKey: ["chats", "create"],
    mutationFn: createChat,
    onSuccess: ({ data }) => {
      navigate("/messenger", { state: { chatId: data.id } });
    },
    onError: () => {
      alert("Failed");
    },
  });

  const connectMutation = useMutation({
    mutationKey: [`contacts-request-${userId}`],
    mutationFn: connect,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["contacts-requests", userId],
      });
    },
    onError: () => {
      alert("Failed");
    },
  });

  const checkAuth = () => {
    if (!user) {
      loginModal.open();
      return false;
    }
    return true;
  };

  const checkUserType = (userType: string) => {
    if (user) {
      return userType === user.type;
    } else {
      return false;
    }
  };

  return (
    <ContextMenu
      items={[
        type === "person" &&
          checkUserType("person") && {
            label: t("buttons.connect"),
            onClick: () => checkAuth() && connectMutation.mutate({ id: userId }),
            icon: ArrowUturnRightIcon,
          },
        {
          label: t("buttons.send"),
          onClick: () => checkAuth() && createChatMutation.mutate({ id: userId }),
          icon: ChatBubbleLeftEllipsisIcon,
        },
        {
          label: t("buttons.block"),
          onClick: () => dispatch(showBlockUserModal(userId)),
          icon: MinusCircleIcon,
        },
      ]}
    />
  );
};

export default ContactContextMenu;
