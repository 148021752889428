import api from "@src/api/api";
import { NotificationTokenType } from "@src/utils/types/NotificationTokenType";

export type NotificationTokenResponse = {
  data: NotificationTokenType;
};

export default async function notificationToken(): Promise<NotificationTokenResponse> {
  const { data } = await api.get("/api/v1/auth/notifications/token");
  return { data };
}
