import { PlayIcon } from "@heroicons/react/24/outline";
import watch from "@src/api/watch/watch";
import Panel from "@src/components/box/Panel";
import ScrollIcons from "@src/components/elements/shared/ScrollIcons";
import SuggestionsCenterItem from "@src/components/elements/sidebar/suggestions/SuggestionsCenterItem";
import { useQuery } from "@tanstack/react-query";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import tw from "twin.macro";

type Props = { id?: string; key?: string };

const WatchSuggestions = ({ id }: Props) => {
  const { t } = useTranslation();
  const galleryRef = useRef<HTMLDivElement>(null);

  const watchResult = useQuery({
    queryKey: ["posts", "watch"],
    queryFn: () => watch({ params: {} }),
  });

  if (!watchResult.isSuccess) return <></>;

  return (
    <div className="px-3 lg:px-0">
      <Panel title={t("components.panels.popularVideos")} mobileBorder={false} key={id}>
        <div className="relative">
          <div className="no-scrollbar flex gap-4 overflow-x-auto" ref={galleryRef}>
            {watchResult.isSuccess &&
              watchResult.data.data.map(item => (
                <SuggestionsCenterItem
                  key={item.media_key}
                  name=""
                  link={"/watch/" + item.media_key}
                  picture={`${item.media_url}/thumbnail.jpg?height=600&width=600&fit=crop`}
                  imageStyle={tw`rounded-xl w-[150px] aspect-[9/16] h-full`}
                  nameStyle={tw`hidden`}
                  views={{
                    icon: <PlayIcon className="size-4" />,
                    count: item.view_count,
                    color: tw`text-black`,
                  }}
                />
              ))}
            <NavLink to="/watch/">
              <div className="flex aspect-[9/16] w-[150px] flex-col justify-center text-center">
                {t("buttons.showMore")}
              </div>
            </NavLink>
            <ScrollIcons galleryRef={galleryRef} />
          </div>
        </div>
      </Panel>
    </div>
  );
};
export default WatchSuggestions;
