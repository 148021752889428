import { Switch } from "@headlessui/react";
import {
  AtSymbolIcon,
  BellAlertIcon,
  BriefcaseIcon,
  BuildingStorefrontIcon,
  CakeIcon,
  ChatBubbleLeftIcon,
  EnvelopeIcon,
  NewspaperIcon,
  TagIcon,
  UserGroupIcon,
  UserIcon,
  UserPlusIcon,
  VideoCameraIcon,
} from "@heroicons/react/24/outline";
import Panel from "@src/components/box/Panel";
import { useAppSelector } from "@src/state/hooks";
import cloneDeep from "lodash/cloneDeep";
import { useState } from "react";
import { useTranslation } from "react-i18next";

type serviceType = {
  label: string;
  checked?: boolean;
  whitelist?: Array<string>;
  icon: JSX.Element;
};

const PushMessagesContainer = () => {
  const { t } = useTranslation();
  const [services, setServices] = useState<Array<serviceType>>([
    {
      label: t("components.posts.comments"),
      checked: true,
      icon: <ChatBubbleLeftIcon key="chat-bubble-left" className="size-4" />,
    },
    {
      label: t("components.shared.markings"),
      checked: true,
      icon: <AtSymbolIcon key="at-symbol" className="size-4" />,
    },
    {
      label: t("components.assignments.groupscreate.marketplace"),
      checked: true,
      icon: <BuildingStorefrontIcon key="building-store" className="size-4" />,
    },
    {
      label: t("main.profileViewTabs.advertisements.title"),
      checked: true,
      icon: <TagIcon key="tag" className="size-4 rotate-180 -scale-y-100" />,
    },
    {
      label: t("main.profileViewTabs.feed.title"),
      checked: true,
      icon: <NewspaperIcon key="newspaper" className="size-4" />,
    },
    {
      label: t("pages.marketplace.videos"),
      checked: true,
      icon: <VideoCameraIcon key="video-camera" className="size-4" />,
    },
    {
      label: t("main.panel.messenger"),
      checked: true,
      icon: <EnvelopeIcon key="envelope" className="size-4" />,
    },
    {
      label: t("main.profileViewTabs.jobs.title"),
      checked: true,
      icon: <BriefcaseIcon key="briefcase" className="size-4" />,
    },
    {
      label: t("main.header.links.groups.title"),
      checked: true,
      icon: <UserGroupIcon key="user-group" className="size-4" />,
    },
    {
      label: t("components.shared.events"),
      checked: true,
      icon: <img src="/images/events.svg" key="events" className="size-4 grayscale" />,
    },
    {
      label: t("components.shared.friendrequests"),
      checked: true,
      icon: <UserPlusIcon key="user-plus" className="size-4" />,
      whitelist: ["person"],
    },
    {
      label: t("main.contacts.tabs.birthdays"),
      checked: true,
      icon: <CakeIcon key="cake" className="size-4" />,
      whitelist: ["person"],
    },
    {
      label: t("components.shared.interactionByFriends"),
      checked: true,
      icon: <UserIcon key="user" className="size-4" />,
      whitelist: ["person"],
    },
    {
      label: t("components.shared.otherInteractions"),
      checked: true,
      icon: <BellAlertIcon key="bell-alert" className="size-4" />,
    },
  ]);
  const { user } = useAppSelector(state => state.user);

  if (!user) {
    return <></>;
  }

  return (
    <Panel mobileBorder={false}>
      <div className="grid grid-cols-[auto,1fr,auto] items-center gap-2 max-md:gap-y-3">
        {services
          .filter(item => {
            if (!item.whitelist) return true;
            else {
              return item.whitelist.includes(user.type);
            }
          })
          .map((item, index) => {
            return (
              <>
                <div>{item.icon}</div>
                <div>{item.label}</div>
                <div>
                  <Switch
                    checked={item.checked}
                    onChange={() => {
                      const newServices = cloneDeep(services);
                      newServices[index].checked = !newServices[index].checked;
                      setServices(newServices);
                    }}
                    className={`${
                      item.checked ? "bg-emerald-500" : "bg-gray-400"
                    } relative inline-flex h-6 w-11 items-center rounded-full`}
                  >
                    <span
                      className={`${
                        item.checked ? "translate-x-6" : "translate-x-1"
                      } inline-block size-4 rounded-full bg-white transition`}
                    />
                  </Switch>
                </div>
              </>
            );
          })}
      </div>
    </Panel>
  );
};

export default PushMessagesContainer;
