import api from "@src/api/api";
import { ChatType } from "@src/utils/types/ChatType";

export type ChatsResponse = {
  data: ChatType[];
};

export default async function chats(): Promise<ChatsResponse> {
  const { data } = await api.get("/api/v1/chats");
  return { data };
}
