import api, { requestParams, RequestWithParams } from "@src/api/api";
import { User } from "@src/utils/user";

export type RequestsParams = {
  page?: number;
  q?: string;
};

export type RequestsRequest = RequestWithParams<RequestsParams> & {
  type: "sent" | "received";
};

export type RequestsResponse = {
  data: {
    recipient: User;
    requester: User;
    accepted: boolean;
  }[];
  current_page: number;
  total_pages: number;
};

export default async function requests(request: RequestsRequest): Promise<RequestsResponse> {
  const { data } = await api.get(
    `/api/v1/contacts/requests/${request.type}${requestParams({ page: 0, ...request.params })}`,
  );
  return data;
}
