import BackButton from "@src/components/nav/BackButton";
import { useTranslation } from "react-i18next";

const HelpContainer = () => {
  const { t } = useTranslation();

  return (
    <>
      <BackButton stepback desktopVisible />
      <div className="flex flex-col gap-6 pb-4 text-justify text-xs text-gray-700">
        <div className="text-left text-smbase font-bold">{t("pages.helpContainer.text1")}</div>
        <div>{t("pages.helpContainer.text2")}</div>
        <div className="text-mini font-bold">{t("pages.helpContainer.text3")}</div>

        <div className="flex flex-col gap-12">
          <div className="flex flex-col gap-4">
            <div className="text-left text-smbase font-bold">{t("pages.helpContainer.text4")}</div>
            <div className="grid grid-cols-2 gap-x-8">
              <img src="/images/support/startseite_1.jpg" />
              <div className="flex flex-col gap-4">
                <div className="text-left text-smbase font-bold">
                  {t("pages.helpContainer.text5")}
                </div>
                <div className="text-xs">{t("pages.helpContainer.text6")}</div>
                <ul className="list-disc text-xs">
                  <li>{t("pages.helpContainer.text7")}</li>
                  <li>{t("pages.helpContainer.text8")}</li>
                  <li>{t("pages.helpContainer.text9")}</li>
                  <li>{t("pages.helpContainer.text10")}</li>
                  <li>{t("pages.helpContainer.text11")}</li>
                </ul>
                <div className="text-xs">{t("pages.helpContainer.text12")}</div>
              </div>
            </div>
          </div>

          <div className="flex flex-col gap-4">
            <div className="text-left text-smbase font-bold">{t("pages.helpContainer.text13")}</div>
            <div className="grid grid-cols-2 gap-x-8">
              <img src="/images/support/registrieren.jpg" />
              <div className="flex flex-col gap-4 text-xs">
                <div className="text-left text-smbase font-bold">
                  {t("pages.helpContainer.text14")}
                </div>
                <div>{t("pages.helpContainer.text15")}</div>
                <div>{t("pages.helpContainer.text16")}</div>
                <div className="font-bold">{t("pages.helpContainer.text17")}:</div>
                <div>{t("pages.helpContainer.text18")}</div>
                <div className="font-bold">{t("pages.helpContainer.text19")}:</div>
                <div>{t("pages.helpContainer.text20")}</div>
                <div className="font-bold">{t("pages.helpContainer.text21")}</div>
                <div>{t("pages.helpContainer.text22")}</div>
              </div>
            </div>
          </div>

          <div className="flex flex-col gap-4">
            <div className="text-left text-smbase font-bold">{t("pages.helpContainer.text23")}</div>
            <div className="grid grid-cols-2 gap-x-8">
              <img src="/images/support/anmeldung.jpg" />
              <div className="flex flex-col gap-4 text-xs">
                <div>{t("pages.helpContainer.text24")}</div>
                <div>{t("pages.helpContainer.text25")}</div>
                <div>{t("pages.helpContainer.text26")}</div>
              </div>
            </div>
          </div>

          <div className="flex flex-col gap-4">
            <div className="text-left text-smbase font-bold">{t("pages.helpContainer.text27")}</div>
            <div className="grid grid-cols-2 gap-x-8">
              <img src="/images/support/profil_bearbeiten.jpg" />
              <div className="flex flex-col gap-4 text-xs">
                <div>{t("pages.helpContainer.text28")}</div>
                <div>{t("pages.helpContainer.text29")}</div>
                <div className="font-bold">{t("pages.helpContainer.text30")}</div>
                <ul className="list-disc">
                  <li>{t("pages.helpContainer.text31")}</li>
                  <li>{t("pages.helpContainer.text32")}</li>
                  <li>{t("pages.helpContainer.text33")}</li>
                  <li>{t("pages.helpContainer.text34")}</li>
                  <li>{t("pages.helpContainer.text35")}</li>
                  <li>{t("pages.helpContainer.text36")}</li>
                </ul>
              </div>
            </div>
          </div>

          <div className="flex flex-col gap-4">
            <div className="text-left text-smbase font-bold">{t("pages.helpContainer.text37")}</div>
            <div className="grid grid-cols-2 gap-x-8">
              <img src="/images/support/profilvollstaendigkeit.jpg" />
              <div className="flex flex-col gap-4 text-xs">
                <div>{t("pages.helpContainer.text38")}</div>
                <div>{t("pages.helpContainer.text39")}</div>
                <div>{t("pages.helpContainer.text40")}</div>
              </div>
            </div>
          </div>

          <div className="flex flex-col gap-4">
            <div className="text-left text-smbase font-bold">{t("pages.helpContainer.text42")}</div>
            <div className="grid grid-cols-2 gap-x-8">
              <img src="/images/support/funktionen.jpg" />
              <div className="flex flex-col gap-4 text-xs">
                <div>{t("pages.helpContainer.text43")}</div>
                <ul className="flex flex-col gap-2">
                  <li>
                    <div className="float-left pr-1 font-bold text-darkcyan">
                      {t("components.assignments.groupscreate.messanger")}:
                    </div>
                    {t("pages.helpContainer.text44")}
                  </li>
                  <li>
                    <div className="float-left pr-1 font-bold text-darkcyan">
                      {t("main.header.links.contacts")}:
                    </div>
                    {t("pages.helpContainer.text45")}
                  </li>
                  <li>
                    <div className="float-left pr-1 font-bold text-darkcyan">
                      {t("pages.helpContainer.text46")}:
                    </div>
                    {t("pages.helpContainer.text47")}
                  </li>
                  <li>
                    <div className="float-left pr-1 font-bold text-darkcyan">
                      {t("components.assignments.groupscreate.newsfeed")}:
                    </div>
                    {t("pages.helpContainer.text48")}
                  </li>
                  <li>
                    <div className="float-left pr-1 font-bold text-darkcyan">
                      {t("pages.helpContainer.text49")}:
                    </div>
                    {t("pages.helpContainer.text50")}
                  </li>
                  <li>
                    <div className="float-left pr-1 font-bold text-darkcyan">
                      {t("components.assignments.groupscreate.marketplace")}:
                    </div>
                    {t("pages.helpContainer.text51")}
                  </li>
                  <li>
                    <div className="float-left pr-1 font-bold text-darkcyan">
                      {t("pages.helpContainer.text52")}:
                    </div>
                    {t("pages.helpContainer.text53")}
                  </li>
                  <li>
                    <div className="float-left pr-1 font-bold text-darkcyan">
                      {t("main.navLinks.watch")}:
                    </div>
                    {t("pages.helpContainer.text54")}
                  </li>
                  <li>
                    <div className="float-left pr-1 font-bold text-darkcyan">
                      {t("pages.helpContainer.text55")}:
                    </div>
                    {t("pages.helpContainer.text56")}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HelpContainer;
