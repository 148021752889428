import ExternalLink from "@src/components/elements/shared/ExternalLink";
import BackButton from "@src/components/nav/BackButton";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

const CookiesContainer = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="pl-4 pt-4">
        <BackButton stepback desktopVisible />
      </div>
      <div className="flex flex-col gap-3 pb-4 text-justify text-xs text-gray-700">
        <h1 className="text-sm font-bold">{t("pages.cookiesContainer.header")}</h1>
        <div>{t("pages.cookiesContainer.text1")} 22. März 2024</div>
        <div>
          {t("pages.cookiesContainer.text2.1")}{" "}
          <NavLink to="/datenschutz" className="text-darkcyan">
            {t("pages.cookiesContainer.text2.2")}
          </NavLink>{" "}
          {t("pages.cookiesContainer.text2.3")}
        </div>
        <div>{t("pages.cookiesContainer.text3")}</div>
        <h2 className="font-semibold">{t("pages.cookiesContainer.text4")}</h2>
        <table className="w-full text-left">
          <tr>
            <th className="w-[100px] pr-[30px] font-semibold lg:w-[150px]">
              {t("pages.cookiesContainer.text6")}
            </th>
            <th className="font-semibold">{t("pages.cookiesContainer.text7")}</th>
          </tr>
          <tr>
            <td></td>
            <td className="pb-4 text-justify">{t("pages.cookiesContainer.text8")}</td>
          </tr>
          <tr>
            <td className="w-[100px] pr-[30px] align-top lg:w-[150px]">
              {t("pages.cookiesContainer.text9")}
            </td>
            <td className="pb-4 text-justify">{t("pages.cookiesContainer.text10")}</td>
          </tr>
          <tr>
            <td className="w-[100px] pr-[30px] align-top lg:w-[150px]">
              {t("pages.cookiesContainer.text11")}
            </td>
            <td className="pb-4 text-justify">{t("pages.cookiesContainer.text12")}</td>
          </tr>
          <tr>
            <td className="w-[100px] pr-[30px] align-top lg:w-[150px]">
              {t("pages.cookiesContainer.text13")}
            </td>
            <td className="pb-4 text-justify">{t("pages.cookiesContainer.text14")}</td>
          </tr>
          <tr>
            <td className="w-[100px] pr-[30px] align-top lg:w-[150px]">
              {t("pages.cookiesContainer.text15")}
            </td>
            <td className="pb-4 text-justify">{t("pages.cookiesContainer.text16")}</td>
          </tr>
        </table>
        <div>{t("pages.cookiesContainer.text17")}</div>
        <h2 className="font-semibold">{t("pages.cookiesContainer.text18")}</h2>
        <div>{t("pages.cookiesContainer.text19")}</div>
        <table className="w-full table-auto text-left">
          <tr>
            <th className="w-[100px] pr-[30px] font-semibold lg:w-[150px]">
              {t("pages.cookiesContainer.text20")}
            </th>
            <th className="font-semibold">{t("pages.cookiesContainer.text7")}</th>
          </tr>
          <tr>
            <td></td>
            <td className="pb-4 text-justify">
              {t("pages.cookiesContainer.text21")}
              <br />
              {t("pages.cookiesContainer.text22")}
            </td>
          </tr>
          <tr>
            <td className="w-[100px] pr-[30px] align-top lg:w-[150px]">
              {t("pages.cookiesContainer.text23")}
            </td>
            <td className="pb-4 text-justify">
              {t("pages.cookiesContainer.text24")}
              <br />
              {t("pages.cookiesContainer.text25")}{" "}
              <NavLink to="/datenschutz" className="text-darkcyan">
                {t("pages.cookiesContainer.text2.2")}
              </NavLink>
              .
              <br />
              {t("pages.cookiesContainer.text26")}
            </td>
          </tr>
          <tr>
            <td className="w-[100px] pr-[30px] align-top lg:w-[150px]">
              {t("pages.cookiesContainer.text27")}
            </td>
            <td className="pb-4 text-justify">
              {t("pages.cookiesContainer.text28")}
              <br />
              {t("pages.cookiesContainer.text29")}{" "}
              <NavLink to="/datenschutz" className="text-darkcyan">
                {t("pages.cookiesContainer.text2.2")}
              </NavLink>
              .
              <br />
              <div>
                {t("pages.cookiesContainer.text30.1")}{" "}
                <NavLink to="/datenschutz" className="text-darkcyan">
                  {t("pages.cookiesContainer.text2.2")}
                </NavLink>{" "}
                {t("pages.cookiesContainer.text30.2")}
              </div>
            </td>
          </tr>
          <tr>
            <td className="w-[100px] pr-[30px] align-top lg:w-[150px]">
              {t("pages.cookiesContainer.text31")}
            </td>
            <td className="pb-4 text-justify">
              {t("pages.cookiesContainer.text32")}{" "}
              <NavLink to="/datenschutz" className="text-darkcyan">
                {t("pages.cookiesContainer.text2.2")}
              </NavLink>
              .
              <br />
              {t("pages.cookiesContainer.text33")}
              <br />
              {t("pages.cookiesContainer.text34")}
              <br />
              {t("pages.cookiesContainer.text35")}
              <br />
              {t("pages.cookiesContainer.text36")}
            </td>
          </tr>
        </table>
        <h2 className="font-semibold">{t("pages.cookiesContainer.text37")}</h2>
        <div>{t("pages.cookiesContainer.text38")}</div>
        <div>
          {t("pages.cookiesContainer.text39")}{" "}
          <NavLink to="/help" className="text-darkcyan">
            {t("pages.helpContainer.helpcenter")}
          </NavLink>
        </div>
        <div>{t("pages.cookiesContainer.text40")}</div>
        <h2 className="font-semibold">{t("pages.cookiesContainer.text41")}</h2>
        <div>{t("pages.cookiesContainer.text42")}</div>
        <h2 className="font-semibold">{t("pages.cookiesContainer.text43")}</h2>
        <div>
          {t("pages.cookiesContainer.text44.1")}
          <NavLink to="/datenschutz" className="text-darkcyan">
            {t("pages.cookiesContainer.text2.2")}
          </NavLink>
          {t("pages.cookiesContainer.text44.2")}
        </div>
        <div>{t("pages.cookiesContainer.text47")}</div>
        <h2 className="font-semibold">{t("pages.cookiesContainer.text48")}</h2>
        <div>{t("pages.cookiesContainer.text49")}</div>
        <h2 className="font-semibold">{t("pages.cookiesContainer.text50")}</h2>
        <div>{t("pages.cookiesContainer.text51")}</div>
        <h2 className="font-semibold">{t("pages.cookiesContainer.text52")}</h2>
        <div>{t("pages.cookiesContainer.text53")}</div>
        <ul className="list-inside list-disc">
          <li>
            <ExternalLink to="https://www.iab.com/">
              {t("pages.cookiesContainer.text54")}
            </ExternalLink>
          </li>
          <li>
            <ExternalLink to="https://edaa.eu/">{t("pages.cookiesContainer.text55")}</ExternalLink>
          </li>
          <li>
            <ExternalLink to="https://iabeurope.eu/">
              {t("pages.cookiesContainer.text56")}
            </ExternalLink>
          </li>
        </ul>
      </div>
    </>
  );
};

export default CookiesContainer;
