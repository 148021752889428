import feed from "@src/api/feed/feed";
import CompactPost from "@src/components/elements/CompactPost";
import Input from "@src/components/elements/input/Input";
import { useInfiniteQueryOnScroll } from "@src/hooks/useInfiniteQueryOnScroll";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

type Props = {
  userId?: string;
};

const ProfileDetailsPosts = ({ userId }: Props) => {
  const { t } = useTranslation();
  const [searchQuery, setSearchQuery] = useState("");
  const [searchParams] = useSearchParams();
  const queryParams = {
    q: searchQuery || null,
    featured_post_id: searchParams.get("featured"),
    user_id: userId || null,
    liked_by: null,
  };

  const { result: feedResult } = useInfiniteQueryOnScroll({
    queryKey: ["posts", "feed", queryParams],
    queryFn: ({ pageParam }) =>
      feed({
        params: {
          ...queryParams,
          page: pageParam,
        },
      }),
    initialPageParam: 0,
    getNextPageParam: lastPage => {
      const nextPage = lastPage.current_page + 1;
      return nextPage <= lastPage.total_pages ? nextPage : null;
    },
  });

  if (!feedResult.isSuccess) return <></>;

  return (
    <>
      <Input
        placeholder={t("components.shared.searchFeed")}
        name="feed_keywords"
        clearable
        onChange={e => setSearchQuery(e)}
      />

      <div className="grid grid-flow-row gap-2">
        <div className="grid grid-cols-2 gap-3 lg:grid-cols-5">
          {feedResult.data.pages
            .flatMap(page => page.data)
            .filter(originalPost => originalPost !== null)
            .map(originalPost => {
              const post = originalPost.is_reposted ? originalPost.reposted : originalPost;

              let id = post.id;
              let type: "image" | "video" = "image";
              let url = "/placeholder.png";

              if (post.link_preview?.image) {
                url = `${post.link_preview?.image}`;
              } else if (post.media.length) {
                id = post.media[0].id;
                url = post.media[0].data_url;
                if (post.media[0].type === "video") {
                  type = "video";
                }
              }

              return (
                <CompactPost
                  key={`discover:${post.watch ? id : post.id}`}
                  link={
                    post.watch
                      ? `/watch/für_dich?featured=${id}`
                      : `/news/für_dich?featured=${post.id}`
                  }
                  type={type}
                  url={url}
                  viewCount={post.view_count}
                  likeCount={post.like_count}
                />
              );
            })}
        </div>
      </div>
    </>
  );
};

export default ProfileDetailsPosts;
