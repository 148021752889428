import React from "react";

interface Product {
  title: string;
  imgUrl: string;
  price: string;
  MRP: string;
  ID: string;
  category: string;
  subCategory: string;
  description?: string;
}

interface ProductProps {
  product: Product;
}

const ProductReviewImage: React.FC<ProductProps> = ({ product }) => {
  return (
    <div className="size-32 overflow-hidden rounded-lg border border-gray-300 bg-white shadow-sm dark:border-gray-700 dark:bg-gray-800">
      <img
        className="size-full object-cover"
        style={{ aspectRatio: "1 / 1" }} // Ensure aspect ratio is maintained
        src={product.imgUrl}
        alt={product.title}
      />
    </div>
  );
};

export default ProductReviewImage;
