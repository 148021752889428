import user from "@src/api/users/user";
import getName from "@src/utils/getName";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

type Props = {
  userId: string;
};

const ProfileDetailsUser = ({ userId }: Props) => {
  const { t } = useTranslation();

  const userResult = useQuery({
    queryKey: ["user", `user-${userId}`],
    queryFn: () => user({ id: userId }),
    retry: false,
  });

  if (!userResult.isSuccess) return <></>;

  return (
    <>
      <div className="flex flex-wrap gap-1">
        {t("main.profileViewTabs.editProfile.form.userName")}
        <div className="font-semibold">{getName(userResult.data.data)}</div>
      </div>
    </>
  );
};

export default ProfileDetailsUser;
