import {
  ArrowLeftIcon,
  ArrowRightOnRectangleIcon,
  CogIcon,
  LinkIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/outline";
import {
  ExclamationTriangleIcon,
  LockClosedIcon,
  PhoneIcon,
  PhotoIcon,
  PlusCircleIcon,
  StarIcon,
  UserGroupIcon,
  VideoCameraIcon,
} from "@heroicons/react/24/solid";
import chat from "@src/api/chats/chat";
import FormTextarea from "@src/components/elements/form/FormTextarea";
import GroupComboBox from "@src/components/elements/form/GroupComboBox";
import InfoButton from "@src/pages/messenger/group/InfoButton";
import { useAppSelector } from "@src/state/hooks";
import { showReportModal } from "@src/state/reportModal/actions";
import getName from "@src/utils/getName";
import isMobile from "@src/utils/isMobile";
import { User } from "@src/utils/user";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import tw from "twin.macro";

type Props = {
  groupId: string;
  onClose?: () => void;
};

const GroupInfo = ({ groupId, onClose }: Props) => {
  const { user } = useAppSelector(state => state.user);
  const [tab, setTab] = useState("info");
  const dispatch = useDispatch();
  const [description, setDescription] = useState<string>("");
  const [showDescription, setShowDescription] = useState(false);
  const { t } = useTranslation();

  const [selectedUsers, setSelectedUsers] = useState<Array<User>>([]);
  const [newUsers, setNewUsers] = useState<Array<User>>([]);

  const chatResult = useQuery({
    queryKey: ["chat", groupId],
    queryFn: () => chat({ id: groupId }),
  });

  if (!chatResult.isSuccess) return <></>;
  if (!user) return <></>;

  return (
    <div
      css={[
        tw`flex flex-col overflow-auto h-[calc(calc(100dvh-90px)-calc(env(safe-area-inset-top)+env(safe-area-inset-bottom)))] lg:h-[calc(100dvh-82px)] relative`,
        isMobile
          ? tw`h-[calc(100dvh-calc(env(safe-area-inset-top)+env(safe-area-inset-bottom)))]`
          : tw`border border-gray-300 rounded-2xl relative px-3`,
      ]}
      className="no-scrollbar"
    >
      <div className="px-0.5 pt-3">
        {tab === "info" && (
          <div className="grid grid-flow-row gap-3 text-sm">
            <button
              className="absolute left-0 flex items-center justify-center px-2"
              onClick={onClose}
            >
              <ArrowLeftIcon className="size-8 rounded-full hover:bg-gray-300" />
            </button>
            <div className="flex flex-row items-center gap-4 text-center text-xs">
              <div className="flex w-full cursor-pointer flex-col items-center justify-center gap-2">
                <div className="relative size-[50px] shrink-0 rounded-full border border-gray-300 bg-white">
                  {chatResult.data.image ? (
                    <img
                      src={`${chatResult.data.image.data_url}`}
                      className="rounded-full"
                      alt={chatResult.data.image.file_name}
                    />
                  ) : (
                    <img
                      src="/images/placeholder/club.png"
                      className="rounded-full"
                      alt="club.png"
                    />
                  )}
                </div>
                <div className="my-auto line-clamp-1 break-all text-sm font-bold text-gray-600">
                  {chatResult.data.name}
                </div>
              </div>
            </div>
            <div className="grid grid-flow-col gap-2 text-xs">
              <button className="flex flex-col items-center gap-0.5 rounded-xl border border-gray-300 py-1">
                <PhoneIcon className="size-4" />
                {t("pages.messenger.audio")}
              </button>
              <button className="flex flex-col items-center gap-0.5 rounded-xl border border-gray-300 py-1">
                <VideoCameraIcon className="size-4" />
                {t("buttons.video")}
              </button>
              <button
                className="flex flex-col items-center gap-0.5 rounded-xl border border-gray-300 py-1"
                onClick={() => {
                  onClose && onClose();
                }}
              >
                <MagnifyingGlassIcon className="size-4 stroke-[3px]" />
                {t("main.browsePlaceholder")}
              </button>
            </div>
            {!showDescription && (
              <button
                className="rounded-xl border border-gray-300"
                onClick={() => {
                  setShowDescription(true);
                }}
              >
                {t("pages.messenger.groupInfo")}
              </button>
            )}
            {showDescription && (
              <FormTextarea
                onChange={e => setDescription(e)}
                value={description}
                name="description"
              />
            )}
            <div className="grid grid-flow-row">
              <InfoButton
                label={t("pages.messenger.media")}
                icon={<PhotoIcon className="size-6 rounded-md bg-white p-px text-blue-700" />}
              />
              <InfoButton
                label={t("pages.messenger.starred")}
                icon={<StarIcon className="size-6 rounded-md bg-yellow-500 text-white" />}
              />
            </div>
            <div className="grid grid-flow-row">
              <InfoButton
                label={t("pages.messenger.encrypted")}
                subLabel={t("pages.messenger.encryptedSub")}
                icon={<LockClosedIcon className="size-6 rounded-md bg-blue-700 text-white" />}
              />
              <InfoButton
                label={t("pages.messenger.groupPermission")}
                icon={<CogIcon className="size-6 rounded-md bg-blue-700 text-white" />}
              />
            </div>
            <div className="grid grid-flow-row">
              <InfoButton
                label={t("pages.messenger.newCom")}
                subLabel={t("pages.messenger.newComSub")}
                icon={<UserGroupIcon className="size-6 rounded-md bg-blue-700 text-white" />}
              />
            </div>
            <div className="flex flex-row items-center justify-between px-2">
              <div>
                {chatResult.data.members.length} {t("main.members.title")}
              </div>
              <button>
                <MagnifyingGlassIcon className="size-6 rounded-full bg-gray-200 stroke-[3px] p-[3px] text-gray-600" />
              </button>
            </div>
            <div className="grid grid-flow-row">
              <InfoButton
                label={t("pages.messenger.addMembers")}
                icon={<PlusCircleIcon className="size-6 rounded-md bg-transparent text-darkblue" />}
                showArrow={false}
                onClick={() => {
                  setTab("add_users");
                }}
              />
              <InfoButton
                label={t("pages.messenger.addWithLink")}
                icon={<LinkIcon className="size-6 rounded-md bg-transparent text-darkblue" />}
                showArrow={false}
              />
            </div>
            <div className="grid grid-flow-row">
              <InfoButton
                label={t("pages.messenger.reportGroup")}
                showArrow={false}
                onClick={() => dispatch(showReportModal(groupId, "chat"))}
                icon={
                  <ExclamationTriangleIcon className="size-6 rounded-md bg-white text-yellow-500" />
                }
              />
              <InfoButton
                label={t("pages.messenger.leaveGroup")}
                showArrow={false}
                icon={
                  <ArrowRightOnRectangleIcon className="size-6 rounded-md bg-transparent text-darkblue" />
                }
              />
            </div>
            <div className="mt-2 grid grid-cols-1 gap-2">
              <div className="px-2">{t("main.members.title")}</div>
              {chatResult.data.members.map(member => (
                <button
                  key={member.user.id}
                  className="relative cursor-pointer select-none rounded-2xl border border-gray-300 py-1.5 pl-3 pr-9 text-gray-900 shadow-sm hover:border-darkblue"
                  onClick={() => {
                    if (selectedUsers.find(user => user.id === member.user.id)) {
                      setSelectedUsers(selectedUsers.filter(item => item.id !== member.user.id));
                    } else {
                      setSelectedUsers([...selectedUsers, member.user]);
                    }
                  }}
                >
                  <div className="flex items-center">
                    <img
                      src={
                        (member.user.avatar !== "" && member.user.avatar) ||
                        `/images/placeholder/${member.user.type}.png`
                      }
                      alt=""
                      className="size-7 shrink-0 rounded-full"
                    />
                    <span css={[tw`ml-3 line-clamp-1 text-left`]}>{getName(member.user)}</span>
                  </div>
                  <span css={[tw`absolute inset-y-0 right-0 flex items-center pr-4`]}>
                    <input
                      type="radio"
                      className="text-indigo-600 outline-none focus:ring-indigo-600"
                      checked={!selectedUsers.find(user => user.id === member.user.id)}
                    />
                  </span>
                </button>
              ))}
            </div>
          </div>
        )}
        {tab === "add_users" && (
          <GroupComboBox
            selectedUsers={newUsers}
            setSelectedUsers={setNewUsers}
            onSave={() => setTab("info")}
            placeholder={t("main.header.links.messenger.searchBar")}
          />
        )}
      </div>
    </div>
  );
};

export default GroupInfo;
