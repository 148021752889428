import api, { RequestWithData } from "@src/api/api";

export type RequestData = {
  item_id: string | null;
  title?: string | null;
  type: string | null;
  description: string;
};

export type CreateRequest = RequestWithData<RequestData>;

export default async function create(request: CreateRequest): Promise<void> {
  const { data } = await api.post("/api/v1/report", request.data);
  return data;
}
