import feed from "@src/api/feed/feed";
import PostWidget from "@src/components/elements/PostWidget";
import SuggestionsContainer from "@src/components/elements/sidebar/suggestions/SuggestionsContainer";
import { useInfiniteQueryOnScroll } from "@src/hooks/useInfiniteQueryOnScroll";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";

type Props = {
  searchQuery?: string;
  userId?: string;
};

const PostsContainer = ({ searchQuery, userId }: Props) => {
  const [searchParams] = useSearchParams();
  const [lightboxSelection, setLightboxSelection] = useState("");
  const queryParams = {
    q: searchQuery || null,
    featured_post_id: searchParams.get("featured"),
    user_id: userId || null,
    liked_by: null,
  };

  const { result: feedResult } = useInfiniteQueryOnScroll(
    {
      queryKey: ["posts", "feed", queryParams],
      queryFn: ({ pageParam }) =>
        feed({
          params: {
            ...queryParams,
            page: pageParam,
          },
        }),
      initialPageParam: 0,
      getNextPageParam: lastPage => {
        const nextPage = lastPage.current_page + 1;
        return nextPage <= lastPage.total_pages ? nextPage : null;
      },
    },
    { offset: 10000 },
  );

  if (!feedResult.isSuccess) return <></>;

  return (
    <>
      {feedResult.data.pages.map((page, pageIndex) =>
        page.data.map((post, index: number) => (
          <>
            {[6, 13, 20, 27].includes(index) && (
              <SuggestionsContainer
                id={`post-suggestions:${index}`}
                key={`post-suggestions:${index}`}
              />
            )}
            <div
              className="max-lg:odd:border-y max-lg:odd:border-y-gray-300 max-lg:odd:py-2"
              key={`post:${post.id}`}
            >
              <PostWidget
                key={`post-widget:${post.id}`}
                postId={post.id}
                post={post}
                text={post.text}
                author={post.author}
                repost={post.reposted}
                created_at={post.created_at}
                onSwitch={(id: string) => {
                  setLightboxSelection(id);
                }}
                lightboxSelection={lightboxSelection}
                prev={
                  index == 0 && pageIndex > 0
                    ? feedResult.data.pages[pageIndex - 1].data[
                        feedResult.data.pages[pageIndex - 1].data.length - 1
                      ].id
                    : index > 0
                      ? feedResult.data.pages[pageIndex].data[index - 1].id
                      : ""
                }
                next={
                  pageIndex + 1 < feedResult.data.pages.length &&
                  index + 1 == feedResult.data.pages[pageIndex].data.length
                    ? feedResult.data.pages[pageIndex + 1].data[0].id
                    : index + 1 < feedResult.data.pages[pageIndex].data.length
                      ? feedResult.data.pages[pageIndex].data[index + 1].id
                      : ""
                }
              />
            </div>
          </>
        )),
      )}
    </>
  );
};

export default PostsContainer;
