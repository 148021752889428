import api from "@src/api/api";
import { PaymentType } from "@src/utils/types/PaymentType";

export type PaymentResponse = {
  data: PaymentType;
  current_page: number;
  total_pages: number;
};

export default async function payment(): Promise<PaymentResponse> {
  const { data } = await api.get("/api/v1/payment");
  return data;
}
