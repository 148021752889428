import TrendingJobItem from "@src/pages/jobs/components/TrendingJobItem";
import { JobType } from "@src/utils/types/JobType";
import { useEffect, useRef, useState } from "react";
type Props = {
  jobList: JobType[];
};

const TrendingJobsHomepage = ({ jobList }: Props) => {
  const jobPreview = 30;
  const [next, setNext] = useState(jobPreview);
  const observerRef = useRef<IntersectionObserver | null>(null);
  const sentinelRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const currentSentinel = sentinelRef.current;

    observerRef.current = new IntersectionObserver(
      entries => {
        if (entries[0].isIntersecting) {
          setNext(prevNext => prevNext + jobPreview);
        }
      },
      { threshold: 1.0 },
    );

    if (currentSentinel) {
      observerRef.current.observe(currentSentinel);
    }

    return () => {
      if (observerRef.current && currentSentinel) {
        observerRef.current.unobserve(currentSentinel);
      }
    };
  }, []);
  return (
    <>
      <div className="mb-10 grid gap-4 md:grid-cols-2 lg:grid-cols-5">
        {jobList?.slice(0, next)?.map((job: JobType) => {
          return (
            <>
              <TrendingJobItem job={job} />
            </>
          );
        })}
      </div>
      <div ref={sentinelRef} className="h-1 w-full"></div>
    </>
  );
};

export default TrendingJobsHomepage;
