import toast, { Toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";

declare type BasicTextNotification = {
  toastRef: Toast;
  title: JSX.Element;
  content: JSX.Element;
  imageUrl: string;
};

export default function BasicTextNotification({
  toastRef,
  title,
  content,
  imageUrl,
}: BasicTextNotification) {
  const { t } = useTranslation();

  return (
    <div
      className={
        (toastRef.visible ? "animate-enter" : "animate-leave") +
        " max-w-md w-full bg-white shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5"
      }
    >
      <div className="w-0 flex-1 p-4">
        <div className="flex items-start">
          <div className="shrink-0 pt-0.5">
            <img className="size-10 rounded-full" src={imageUrl} alt="" />
          </div>
          <div className="ml-3 flex-1">
            <p className="text-sm font-medium text-gray-900">{title}</p>
            <p className="mt-1 text-sm text-gray-500">{content}</p>
          </div>
        </div>
      </div>
      <div className="flex border-l border-gray-200">
        <button
          onClick={() => toast.dismiss(toastRef.id)}
          className="flex w-full items-center justify-center rounded-none rounded-r-lg border border-transparent p-4 text-sm font-medium text-darkblue hover:text-darkblue focus:outline-none focus:ring-2 focus:ring-darkblue"
        >
          {t("main.shop.buttons.close")}
        </button>
      </div>
    </div>
  );
}
