import api from "@src/api/api";
import type { User } from "@src/utils/user";

export type MeResponse = {
  data: User;
};

export default async function me(): Promise<MeResponse> {
  const { data } = await api.get("/api/v1/me");
  return data;
}
