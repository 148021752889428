import reset from "@src/api/auth/reset";
import Panel from "@src/components/box/Panel";
import Alert from "@src/components/elements/Alert";
import Button from "@src/components/elements/input/Button";
import Input from "@src/components/elements/input/Input";
import AppLink from "@src/components/elements/shared/AppLink";
import usePopupModal from "@src/state/modal/usePopupModal";
import { useMutation } from "@tanstack/react-query";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const RequestPasswordResetContainer = () => {
  const [email, setEmail] = useState<string>("");
  const navigate = useNavigate();
  const popupModal = usePopupModal();
  const { t } = useTranslation();

  const resetMutation = useMutation({
    mutationKey: ["reset"],
    mutationFn: reset,
    onSuccess: () => {
      popupModal.open(
        <Alert
          buttons={Alert.ButtonVariants.OK}
          variant={Alert.MessageVariants.OK}
          onClose={popupModal.close}
          message={t("main.alerts.resetPassword")}
          title={t("main.profileViewTabs.editProfile.form.restore")}
        />,
      );
    },
    onError: (error: Error) => {
      popupModal.open(
        <Alert
          buttons={Alert.ButtonVariants.OK}
          variant={Alert.MessageVariants.Error}
          onClose={popupModal.close}
          message={t("main.alerts.resetPasswordError") + error.message}
          title={t("main.profileViewTabs.editProfile.form.restore")}
        />,
      );
    },
  });

  return (
    <Panel mobileBorder={false}>
      <form
        onSubmit={e => {
          e.preventDefault();
        }}
        className="grid grid-flow-row justify-center gap-1 text-center text-black"
      >
        <div className="mb-4 text-xl font-medium text-darkblue">
          {t("main.signIn.title1")}
          <br /> {t("main.signIn.title2")}
        </div>
        <Input
          name="email"
          label={t("main.signIn.email")}
          value={email}
          placeholder="max.mustermann@beispiel.de"
          type="email"
          onChange={e => setEmail(e)}
        />
        <Button
          onClick={() =>
            !resetMutation.isPending &&
            resetMutation.mutate({
              data: {
                email,
              },
            })
          }
        >
          {t("main.signIn.newPassword")}
        </Button>
        <div className="mt-2 text-sm text-gray-700">{t("main.signIn.description")}</div>
        <Button
          onClick={() => {
            navigate("/registrierung");
          }}
          variant={Button.Variants.Cyan}
        >
          {t("main.signIn.createAccount")}
        </Button>
        <AppLink />
      </form>
    </Panel>
  );
};

export default RequestPasswordResetContainer;
