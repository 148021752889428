import { CheckIcon } from "@heroicons/react/24/outline";
import {
  CheckCircleIcon,
  EnvelopeIcon,
  MinusCircleIcon,
  XCircleIcon,
} from "@heroicons/react/24/solid";
import create from "@src/api/chats/create";
import reject from "@src/api/contacts/requests/reject";
import connect from "@src/api/users/connect";
import follow from "@src/api/users/follow";
import unblock from "@src/api/users/unblock";
import unfollow from "@src/api/users/unfollow";
import Button from "@src/components/elements/input/Button";
import UserWidgetPicture from "@src/components/elements/UserWidgetPicture";
import ContactContextMenu from "@src/pages/contacts/context/ContactContextMenu";
import { showBlockUserModal } from "@src/state/blockUserModal/actions";
import { useAppSelector } from "@src/state/hooks";
import useLoginModal from "@src/state/modal/useLoginModal";
import { User } from "@src/utils/user";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";

type Props = {
  key?: string;
  mode?:
    | "birthdays"
    | "default"
    | "my-contacts"
    | "my-followers"
    | "requests-received"
    | "requests-sent";
  user: User;
};

const ContactItem = ({ mode, user }: Props) => {
  const { user: userData } = useAppSelector(state => state.user);
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const loginModal = useLoginModal();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const followMutation = useMutation({
    mutationKey: [`follow-${user.id}`],
    mutationFn: follow,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["contacts"] });
      queryClient.invalidateQueries({ queryKey: ["follows"] });
      queryClient.invalidateQueries({ queryKey: ["following"] });
      queryClient.invalidateQueries({ queryKey: ["likes"] });
    },
    onError: () => {
      alert("Failed");
    },
  });

  const unfollowMutation = useMutation({
    mutationKey: [`unfollow-${user.id}`],
    mutationFn: unfollow,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["contacts"] });
      queryClient.invalidateQueries({ queryKey: ["follows"] });
      queryClient.invalidateQueries({ queryKey: ["following"] });
      queryClient.invalidateQueries({ queryKey: ["likes"] });
    },
    onError: () => {
      alert("Failed");
    },
  });

  const createMutation = useMutation({
    mutationKey: ["chats", "create"],
    mutationFn: create,
    onSuccess: ({ data }) => {
      navigate("/messenger", { state: { chatId: data.id } });
    },
    onError: () => {
      alert("Failed");
    },
  });

  const unblockMutation = useMutation({
    mutationKey: [`unblock-${user.id}`],
    mutationFn: unblock,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["contacts"] });
      queryClient.invalidateQueries({ queryKey: ["blocked"] });
    },
    onError: () => {
      alert("Failed");
    },
  });

  const connectMutation = useMutation({
    mutationKey: ["contacts-requests", "connect", user.id],
    mutationFn: connect,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["contacts-requests", user.id],
      });
    },
    onError: () => {
      alert("Failed");
    },
  });

  const rejectMutation = useMutation({
    mutationKey: ["contacts-requests", "reject", user.id],
    mutationFn: reject,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["contacts-requests", user.id],
      });
    },
    onError: () => {
      alert("Failed");
    },
  });

  const checkAuth = () => {
    if (!userData) {
      loginModal.open();
      return false;
    }
    return true;
  };

  let actions: ReactElement;

  switch (mode) {
    case "birthdays":
      actions = (
        <Button
          onClick={() => checkAuth() && createMutation.mutate({ id: user.id })}
          size={Button.Sizes.Small}
          variant={Button.Variants.Transparent}
        >
          <div className="inline-flex items-center gap-1">
            {t("components.shared.congratulate")}
          </div>
        </Button>
      );
      break;
    case "my-contacts":
      actions = (
        <Button
          onClick={() => checkAuth() && createMutation.mutate({ id: user.id })}
          size={Button.Sizes.Small}
          variant={Button.Variants.Transparent}
        >
          <div className="inline-flex items-center gap-1">
            <EnvelopeIcon className="size-4" />
            {t("buttons.send")}
          </div>
        </Button>
      );
      break;
    case "my-followers":
      actions = (
        <Button
          onClick={() => {
            if (user.blocked) {
              checkAuth() && unblockMutation.mutate({ id: user.id });
            } else {
              dispatch(showBlockUserModal(user.id));
            }
          }}
          size={Button.Sizes.Small}
          variant={Button.Variants.Transparent}
        >
          <MinusCircleIcon className="size-4" />
          {user.blocked ? "Entblocken" : "Blockieren"}
        </Button>
      );
      break;
    case "requests-received":
      actions = (
        <div className="flex flex-row justify-evenly">
          <Button
            onClick={() => checkAuth() && rejectMutation.mutate({ id: user.id })}
            size={Button.Sizes.Small}
            variant={Button.Variants.Transparent}
          >
            <XCircleIcon className="size-7 text-red-700" />
          </Button>
          <Button
            onClick={() => checkAuth() && connectMutation.mutate({ id: user.id })}
            size={Button.Sizes.Small}
            variant={Button.Variants.Transparent}
          >
            <CheckCircleIcon className="size-7 text-green-700" />
          </Button>
        </div>
      );
      break;
    case "requests-sent":
      actions = (
        <Button
          onClick={() => checkAuth() && connectMutation.mutate({ id: user.id })}
          size={Button.Sizes.Small}
          variant={Button.Variants.Secondary}
        >
          <div className="inline-flex items-center gap-1">
            <CheckIcon className="size-3 border-2 border-green-700 stroke-[4px] text-green-400" />
            {t("main.header.links.groups.tabs.pending")}
          </div>
        </Button>
      );
      break;
    default:
      if (user.followed) {
        actions = (
          <Button
            onClick={() => checkAuth() && unfollowMutation.mutate({ id: user.id })}
            size={Button.Sizes.Small}
            variant={Button.Variants.Transparent}
          >
            <img src="/follow.svg" className="mx-auto h-5 w-4" />
          </Button>
        );
      } else {
        actions = (
          <Button
            onClick={() => checkAuth() && followMutation.mutate({ id: user.id })}
            size={Button.Sizes.Small}
            variant={Button.Variants.Red}
          >
            {t("main.contacts.tabs.buttonsConsequences")}
          </Button>
        );
      }
      break;
  }

  return (
    <div>
      <div>
        <div className="flex w-full flex-col gap-2 rounded-lg border border-gray-300 pb-2 text-sm">
          <div className="relative">
            <NavLink to={"/profile/" + user.id} className="flex w-full flex-col gap-1">
              <UserWidgetPicture user={user} showName={true} follow={false} verified={true} />
            </NavLink>
            <div className="absolute right-0 top-0 pt-2">
              <ContactContextMenu userId={user.id} type={user.type} />
            </div>
          </div>
          <div
            className="flex flex-row justify-between px-2 text-xs text-gray-600"
            style={{ marginTop: "-25px" }}
          >
            <NavLink to={"/profile/" + user.id + "/follows"}>
              <div className="flex flex-col items-center gap-x-1">
                <div>{t("main.followers.title")}</div>
                <div>{user.follower_count}</div>
              </div>
            </NavLink>
            <NavLink to={"/profile/" + user.id + "/following"}>
              <div className="flex flex-col items-center gap-x-1">
                <div>{t("main.following.title")}</div>
                <div>{user.following_count}</div>
              </div>
            </NavLink>
            <NavLink to={"/profile/" + user.id + "/likes"}>
              <div className="flex flex-col items-center gap-x-1">
                <div>{t("main.likes.title")}</div>
                <div>{user.like_count}</div>
              </div>
            </NavLink>
          </div>
          <div className=" px-2">{actions}</div>
        </div>
      </div>
    </div>
  );
};

export default ContactItem;
