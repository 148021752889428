import React, { useEffect, useState } from "react";

interface Props extends React.ImgHTMLAttributes<HTMLImageElement> {
  lowResSrc: string;
  highResSrc: string;
}

const ProgressiveImage = ({ lowResSrc, highResSrc, ...imgProps }: Props) => {
  const [imageSrc, setImageSrc] = useState(lowResSrc);

  useEffect(() => {
    const highResImage = new Image();
    highResImage.src = highResSrc;

    highResImage.onload = () => {
      setImageSrc(highResSrc);
    };
  }, [highResSrc]);

  return <img src={imageSrc} {...imgProps} />;
};

export default ProgressiveImage;
