import api, { requestParams, RequestWithId, RequestWithParams } from "@src/api/api";
import { User } from "@src/utils/user";

export type FollowsParams = {
  page?: number;
  q?: string;
};

export type FollowsRequest = RequestWithId & RequestWithParams<FollowsParams>;

type FollowsResponse = {
  data: User[];
  current_page: number;
  total_pages: number;
};

export default async function follows(request: FollowsRequest): Promise<FollowsResponse> {
  const { data } = await api.get(
    `/api/v1/users/${request.id}/follows${requestParams({ page: 0, ...request.params })}`,
  );
  return data;
}
