import api, { requestParams, RequestWithParams } from "@src/api/api";
import { Post } from "@src/utils/post";

export type PostsParams = {
  page?: number;
  user_id?: string;
  q?: string;
  watch?: boolean;
};

export type PostsRequest = RequestWithParams<PostsParams> & {
  hashtag: string;
};

export type PostsResponse = {
  data: Post[];
  current_page: number;
  total_pages: number;
};

export default async function posts(request: PostsRequest): Promise<PostsResponse> {
  const { data } = await api.get(
    `/api/v1/hashtags/${request.hashtag}/posts${requestParams({ page: 0, ...request.params })}`,
  );
  return data;
}
