import { PropsWithChildren } from "react";
import { NavLink } from "react-router-dom";
import tw from "twin.macro";

type Props = {
  key?: string;
  subTab?: boolean;
  to: string;
  counter?: number;
  active?: boolean;
  state?: any;
};

const TabLink = ({ subTab, to, children, counter, active, state }: PropsWithChildren<Props>) => (
  <NavLink
    to={to}
    state={state}
    onClick={e => {
      e.currentTarget.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "center",
      });
    }}
  >
    {({ isActive }) => (
      <span
        css={[
          tw`relative whitespace-nowrap flex gap-1 items-center border-b-2`,
          subTab ? tw`border-darkblue` : tw`border-darkblue`,
          active || isActive ? tw`text-gray-900` : tw`text-gray-500 border-transparent`,
        ]}
      >
        {children}
        {counter !== undefined ? (
          <span className="mx-1 inline-flex items-center rounded-full bg-lightgray px-2 text-xs font-light">
            {counter}
          </span>
        ) : null}
      </span>
    )}
  </NavLink>
);

export default TabLink;
