import api, { requestParams, RequestWithParams } from "@src/api/api";
import { VideoType } from "@src/utils/types/VideoType";

export type DirectParams = {
  videoNames?: string;
};

export type DirectRequest = RequestWithParams<DirectParams>;

export type DirectResponse = {
  data: VideoType;
};

export default async function direct(request: DirectRequest): Promise<DirectResponse> {
  const { data } = await api.get(`/api/v1/video/upload/direct${requestParams(request.params)}`);
  return data;
}
