import passwordReset from "@src/api/auth/passwordReset";
import Button from "@src/components/elements/input/Button";
import Input from "@src/components/elements/input/Input";
import BackButton from "@src/components/nav/BackButton";
import AuthWrapper from "@src/pages/auth/AuthWrapper";
import { useMutation } from "@tanstack/react-query";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

const ResetPasswordContainer = () => {
  const [password, setPassword] = useState<string>("");
  const [searchParams] = useSearchParams();
  const code = searchParams.get("code");
  const { t } = useTranslation();

  const passwordResetMutation = useMutation({
    mutationKey: ["password-reset"],
    mutationFn: passwordReset,
    onSuccess: () => {
      alert(t("main.passwordChange.success"));
    },
    onError: () => {
      alert("Failed");
    },
  });

  if (code == null) {
    alert("no code provided.");
    return <></>;
  }

  return (
    <AuthWrapper>
      <BackButton stepback={true} />
      <div className="mb-4 text-xl font-medium text-darkblue">
        {t("main.signIn.title1")}
        <br /> {t("main.signIn.title2")}
      </div>
      <form
        onSubmit={e => {
          e.preventDefault();
        }}
      >
        <Input
          name="new password"
          label={t("main.passwordChange.newPassword")}
          value={password}
          placeholder="**************"
          type="password"
          onChange={e => setPassword(e)}
        />
        <div className="mx-auto mt-2 flex gap-x-4">
          <Button
            onClick={() =>
              !passwordResetMutation.isPending &&
              passwordResetMutation.mutate({
                data: {
                  request_id: code ?? "",
                  password: password,
                },
              })
            }
          >
            {t("main.signIn.saveNewPassword")}
          </Button>
        </div>
      </form>
    </AuthWrapper>
  );
};

export default ResetPasswordContainer;
