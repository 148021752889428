import { NavLink, useLocation } from "react-router-dom";
import tw, { TwStyle } from "twin.macro";

type Props = {
  user: { id: string; name: string; avatar: string; type: string };
  nameStyle?: TwStyle;
};

const UserWidget = ({ user, nameStyle }: Props) => {
  const placeholder = `/images/placeholder/${user.type}.png`;
  const location = useLocation();

  return (
    <NavLink
      to={`/profile/${user.id}`}
      state={{ backlink: location.state?.backlink ?? location.pathname }}
    >
      <div css={[tw`cursor-pointer relative w-full`]}>
        <div
          css={[tw`rounded-full border border-gray-300 relative bg-white shrink-0`]}
          style={{
            width: "50px",
            height: "50px",
          }}
        >
          <div
            css={[tw`flex justify-center items-center rounded-full overflow-hidden h-full w-full`]}
          >
            <img className="w-full" src={user.avatar || placeholder} alt={user.name} />
          </div>
        </div>
        <div
          css={[
            tw`text-xs text-left whitespace-nowrap text-gray-600 flex justify-center items-center gap-1 w-full`,
            tw`mt-2 truncate`,
          ]}
        >
          <div css={[tw`truncate`, nameStyle]}>{user.name}</div>
        </div>
      </div>
    </NavLink>
  );
};

export default UserWidget;
