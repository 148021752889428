import api, { requestParams, RequestWithParams } from "@src/api/api";
import { Product } from "@src/utils/product";

export type ProductsParams = {
  page?: number;
  q?: string;
};

export type ProductsRequest = RequestWithParams<ProductsParams>;

export type ProductsResponse = {
  data: Product[];
  current_page: number;
  total_pages: number;
};

export default async function products(request: ProductsRequest): Promise<ProductsResponse> {
  const { data } = await api.get(
    `/api/v1/marketplace/products${requestParams({ page: 0, ...request.params })}`,
  );
  return data;
}
