import api, { RequestWithId } from "@src/api/api";
import { Post } from "@src/utils/post";

export type PostRequest = RequestWithId;

export type PostResponse = Post;

export default async function post(request: PostRequest): Promise<PostResponse> {
  const { data } = await api.get(`/api/v1/post/${request.id}`);
  return data;
}
