import { faStar as faStarRegular } from "@fortawesome/free-regular-svg-icons";
import { faStarHalfAlt, faStar as faStarSolid } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type Props = {
  rating?: number;
  onChange?: (i: number) => void;
  editable?: boolean;
};

const StarsRating = ({ rating = 0, onChange, editable }: Props) => {
  const stars = [];

  for (let i = 1; i <= 5; i++) {
    if (i <= Math.floor(rating)) {
      // Full star
      stars.push(
        <FontAwesomeIcon
          icon={faStarSolid}
          key={i}
          onClick={() => onChange && onChange(i)}
          className={`${editable && "hover:invert"}`}
        />,
      );
    } else if (i === Math.ceil(rating) && !Number.isInteger(rating)) {
      // Half star
      stars.push(
        <FontAwesomeIcon
          icon={faStarHalfAlt}
          key={i}
          onClick={() => onChange && onChange(i)}
          className={`${editable && "hover:invert"}`}
        />,
      );
    } else {
      // Empty star
      stars.push(
        <FontAwesomeIcon
          icon={faStarRegular}
          key={i}
          onClick={() => onChange && onChange(i)}
          className={`${editable && "hover:invert"}`}
        />,
      );
    }
  }

  return <div className={`${editable && "cursor-pointer"} select-none`}>{stars}</div>;
};

export default StarsRating;
