import api, { RequestWithId } from "@src/api/api";
import { UserStatisticsType } from "@src/utils/types/UserStatisticsType";

export type StatisticsRequest = RequestWithId;

export type StatisticsResponse = {
  data: UserStatisticsType;
};

export default async function statistics(request: StatisticsRequest): Promise<StatisticsResponse> {
  const { data } = await api.get(`/api/v1/users/${request.id}/statistics`);
  return data;
}
