import api, { RequestWithData } from "@src/api/api";

export type PasswordResetData = {
  request_id: string;
  password: string;
};

export type PasswordResetRequest = RequestWithData<PasswordResetData>;

export default async function passwordReset(request: PasswordResetRequest): Promise<void> {
  const { data } = await api.post("/api/v1/auth/password_reset", request.data);
  return data;
}
