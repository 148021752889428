import getNotificationToken from "@src/api/auth/notificationToken";
import BasicTextNotification from "@src/components/notification/BasicTextNotification";
import { useAppSelector } from "@src/state/hooks";
import { store } from "@src/state/store";
import { setNotificationToken } from "@src/state/user/userSlice";
import { ComponentProps, createContext, useCallback, useEffect, useState } from "react";
import toast, { Toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import useWebSocket, { ReadyState } from "react-use-websocket";

export const NotificationContextProvider = (props: ComponentProps<any>) => {
  const { user, notificationToken } = useAppSelector(state => state.user);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [reconnecting, _setReconnecting] = useState(false);

  const { sendMessage, lastMessage, readyState } = useWebSocket(import.meta.env.VITE_WS_URL || "", {
    shouldReconnect: () => true,
    reconnectAttempts: 20,
    reconnectInterval: 5000,
    onOpen: () => {
      if (!notificationToken) {
        return;
      }

      console.log("[Notifications] Connection opened");
      writeMessage({ token: notificationToken });
    },
    onClose: () => console.log("[Notifications] Connection closed"),
    onError: e => console.log("[Notifications] Connection failed", e),
  });

  const writeMessage = useCallback(
    (message: object) => {
      const encoded = JSON.stringify(message);

      sendMessage(encoded);
    },
    [sendMessage],
  );

  const requestNotificationToken = useCallback(() => {
    if (!user) {
      return;
    }

    getNotificationToken().then(({ data }) => {
      dispatch(setNotificationToken(data.token));
      writeMessage({ token: data.token });
    });
  }, [user, dispatch, writeMessage]);

  if (user && !notificationToken) {
    requestNotificationToken();
  }

  useEffect(() => {
    if (readyState == ReadyState.CLOSED) {
      console.log("[Notifications] Initiating reconnect");
    }
    if (readyState == ReadyState.OPEN) {
      console.log("[Notifications] Initiating open");
    }
  }, [readyState]);

  if (reconnecting) {
    //! todo show reconnection loader?
  }

  useEffect(() => {
    if (!lastMessage) return;
    const decoded = JSON.parse(lastMessage?.data);

    if (decoded.status === "ok") {
      console.log("[Notification] Authenticated successfully");
    } else if (decoded.status == "expired") {
      requestNotificationToken();
      // request new notification token
    } else if (NotificationConfigurationMappings[decoded.title]) {
      toast.custom(
        (toastRef: Toast) => NotificationConfigurationMappings[decoded.title](decoded, toastRef, t),
        {
          duration: 1000 * 10,
          position: "top-right",
        },
      );
    }
  }, [lastMessage, requestNotificationToken, t]);

  if (store.getState().user == null) return props.children;

  return props.children;
};

export const NotificationContext = createContext<NotificationState>({
  addHandler: () => null,
});

declare type Message = {
  body: any;
};

export const NotificationConfigurationMappings: {
  [key: string]: (data: Message, toast: Toast, t: any) => JSX.Element;
} = {
  new_follow: (data, toastRef, t: any) => (
    <BasicTextNotification
      toastRef={toastRef}
      title={data.body.followed_by.name}
      content={<>{t("main.notifications.new_follow.content")}</>}
      imageUrl={"/images/placeholder/person.png"}
    />
  ),
  post_liked: (data, toastRef, t: any) => (
    <BasicTextNotification
      toastRef={toastRef}
      title={data.body.liked_by.name}
      content={<>{t("main.notifications.post_liked.content")}</>}
      imageUrl={"/images/placeholder/person.png"}
    />
  ),
  post_reposted: (data, toastRef, t: any) => (
    <BasicTextNotification
      toastRef={toastRef}
      title={data.body.liked_by.name}
      content={<>{t("main.notifications.post_reposted.content")}</>}
      imageUrl={"/images/placeholder/person.png"}
    />
  ),
  new_connect: (data, toastRef, t: any) => (
    <BasicTextNotification
      toastRef={toastRef}
      title={data.body.connected_by.name}
      content={<>{t("main.notifications.new_connect.content")}</>}
      imageUrl={"/images/placeholder/person.png"}
    />
  ),
  new_post_comment: (data, toastRef, t: any) => (
    <BasicTextNotification
      toastRef={toastRef}
      title={data.body.commented_by.name}
      content={<>{t("main.notifications.new_post_comment.content")}</>}
      imageUrl={"/images/placeholder/person.png"}
    />
  ),
};

export type NotificationState = {
  addHandler: (payload: object, handler: (payload: object) => void) => void;
};
